var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"my-1",attrs:{"items":_vm.flightPlans,"headers":_vm.headers,"hide-default-footer":"","disable-filtering":"","disable-pagination":"","disable-sort":""},scopedSlots:_vm._u([{key:"item.meta.updatedAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$services.utils.dateFormatter.formatDateTimeUtc(item.meta.updatedAt)))])]}},{key:"item.endurance.time.trip",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$services.utils.dateFormatter.minutesToTimeString(item.endurance.time.trip)))])]}},_vm._l((_vm.unitHeaders),function(slotName,index){return {key:slotName,fn:function(ref){
var item = ref.item;
var header = ref.header;
return [_c('span',{key:index},[_vm._v(_vm._s(_vm.$_.get(item, slotName.slice(5)))+" "+_vm._s(header.unit))])]}}})],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }