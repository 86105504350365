import { Waypoint } from './Waypoint';

export class Route {
	static get name() {
		return 'Route';
	}

	constructor({
		_id = undefined,
		waypoints = [],
		notes = [],
		encodedPolyline = '',
		tags = [],
		cycle = {},
		cruiseAltitude = 0,
		routeString = '',
		totalDistance = 0,
	} = {}) {
		this._id = _id;
		this.waypoints = waypoints.map((item) => Waypoint.create(item));
		this.notes = notes;
		this.encodedPolyline = encodedPolyline;
		this.tags = tags;
		this.cycle = cycle;
		this.cruiseAltitude = cruiseAltitude;
		this.routeString = routeString;
		this.totalDistance = totalDistance;
	}

	static create(data = {}) {
		return Object.seal(new Route(data));
	}
}
