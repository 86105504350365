<template>
	<v-card>
		<v-card-title>Load</v-card-title>
		<v-card-text>
			<v-data-table
				:items="load"
				:headers="loadHeaders"
				dense
				disable-sort
				disable-filtering
				disable-pagination
				hide-default-footer
			>
				<template v-slot:[`item`]="{ item }">
					<tr :class="item.style">
						<td>{{ item.description }}</td>
						<td class="pr-1 text-right">{{ item.value }}</td>
						<td class="pl-0">{{ item.unit }}</td>
					</tr>
				</template>
			</v-data-table>
		</v-card-text>
	</v-card>
</template>

<script>
import { FlightPlan } from '@/models';
export default {
	name: 'AirFlightPlanLoad',
	props: {
		flightPlan: FlightPlan,
	},
	data: () => ({
		loadHeaders: [
			{ value: 'description', text: 'Load', align: 'start' },
			{ value: 'value', text: '', align: 'end' },
			{ value: 'unit', text: '', align: 'start' },
		],
	}),
	computed: {
		load() {
			return [
				{
					description: 'Empty Mass',
					value: this.flightPlan.aircraft.masses.emptyMass,
					unit: this.$services.utils.units.mass,
					style: 'font-weight-bold',
				},
				{
					description: 'PAX',
					value: this.flightPlan.load.pax * this.flightPlan.load.paxMass,
					unit: this.$services.utils.units.mass,
				},
				{ description: 'Baggage', value: this.flightPlan.load.baggage, unit: this.$services.utils.units.mass },
				{ description: 'Cargo', value: this.flightPlan.load.cargo, unit: this.$services.utils.units.mass },
				{
					description: 'Payload',
					value: this.flightPlan.load.payload,
					unit: this.$services.utils.units.mass,
					style: 'font-weight-bold',
				},
				{
					description: 'Actual Mass',
					value: this.flightPlan.load.actualMass,
					unit: this.$services.utils.units.mass,
					style: 'font-weight-bold',
				},
			];
		},
	},
};
</script>
