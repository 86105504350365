<template>
	<!-- ToDo check for TAB proceeding suspecting either v-input problematic or form needed-->
	<v-input class="inputWidth ma-2" :error-messages="errors" :error-count="errors.length">
		<v-combobox
			class="d-inline-block"
			dense
			v-model="year"
			:items="availYears"
			hide-details="true"
			:rules="[yearRule]"
			label="Year"
		/>
		<span class="mx-2">-</span>
		<v-combobox
			class="d-inline-block"
			dense
			v-model="month"
			:items="from1ToN(12)"
			hide-details="true"
			:rules="[monthRule]"
			label="Month"
		/>
		<span class="mx-2">-</span>
		<v-combobox
			class="d-inline-block"
			dense
			v-model="day"
			hide-details="true"
			:items="availDays"
			:rules="[dayRule]"
			:error="dayError"
			label="Day"
		/>
		<span class="mx-4"></span>
		<v-combobox
			class="d-inline-block"
			dense
			v-model="hours"
			hide-details="true"
			:items="from1ToN(23)"
			:rules="[hoursRule]"
			label="Hours"
		/>
		<span class="mx-2">:</span>
		<v-combobox
			class="d-inline-block"
			dense
			v-model="minutes"
			:items="from1ToN(59)"
			hide-details="true"
			:rules="[minutesRule]"
			label="Minutes"
		/>
	</v-input>
</template>
<script>
export default {
	name: 'AirDateTimePicker',
	props: {
		value: Date,
	},
	data: () => ({
		year: '',
		month: '',
		day: '',
		hours: '',
		minutes: '',
		errors: [],
		dayError: false,
	}),
	mounted() {
		this.year = '' + this.value.getUTCFullYear();
		this.month = '' + (this.value.getUTCMonth() + 1);
		this.day = '' + this.value.getUTCDate();
		this.hours = '' + this.value.getUTCHours();
		this.minutes = '' + this.value.getUTCMinutes();
	},
	computed: {
		correctDateTime: {
			get() {
				var out = new Date();
				out.setSeconds(0);
				out.setUTCMilliseconds(0);
				out.setUTCFullYear(Number.parseInt(this.year));
				out.setUTCMonth(Number.parseInt(this.month) - 1);
				out.setUTCDate(Number.parseInt(this.day));
				out.setUTCHours(Number.parseInt(this.hours));
				out.setUTCMinutes(Number.parseInt(this.minutes));
				return out;
			},
			set() {
				if (
					this.yearRule(this.year) === true &&
					this.monthRule(this.month) === true &&
					this.dayRule(this.day) === true
				) {
					var out = new Date();
					out.setSeconds(0);
					out.setUTCMilliseconds(0);
					out.setUTCFullYear(Number.parseInt(this.year));
					out.setUTCMonth(Number.parseInt(this.month) - 1);
					out.setUTCDate(Number.parseInt(this.day));
					out.setUTCHours(Number.parseInt(this.hours));
					out.setUTCMinutes(Number.parseInt(this.minutes));
					this.$emit('input', out);
				}
			},
		},
		availYears() {
			const now = new Date();

			var values = [];
			for (var i = now.getUTCFullYear() - 50; i <= now.getUTCFullYear() + 5; i++) values.push(i + '');

			return values;
		},
		availDays() {
			var values = [];
			for (var i = 1; i <= this.maxDays; i++) values.push(i + '');

			return values;
		},
		maxDays() {
			return new Date(this.year, this.month, 0).getDate();
		},
	},
	methods: {
		from1ToN(n) {
			return Array.from(Array(n).keys()).map((value) => (++value).toString());
		},
		addError(value) {
			const index = this.errors.indexOf(value);
			if (index < 0) {
				this.errors.push(value);
			}
			return value;
		},
		removeError(value) {
			const index = this.errors.indexOf(value);
			if (index >= 0) {
				this.errors.splice(index, 1);
			}
			return true;
		},
		yearRule(value) {
			if (/^[0-9]{4}$/.test(value)) {
				const parsed = Number.parseInt(value);
				if (parsed >= 1945 && parsed < 2022) {
					return this.removeError('Invalid year!');
				}
			}
			return this.addError('Invalid year!');
		},
		monthRule(value) {
			if (/^[0-9]{1,2}$/.test(value)) {
				const parsed = Number.parseInt(value);
				if (parsed >= 1 && parsed <= 12) {
					this.dayRule(this.day);
					return this.removeError('Invalid month!');
				}
			}
			return this.addError('Invalid month!');
		},
		dayRule(value) {
			if (/^[0-9]{1,2}$/.test(value)) {
				const parsed = Number.parseInt(value);
				if (Number.isInteger(this.maxDays) && parsed >= 1 && parsed <= this.maxDays) {
					this.dayError = false;
					return this.removeError('Invalid day!');
				}
			}
			this.dayError = true;
			return this.addError('Invalid day!');
		},
		hoursRule(value) {
			if (/^[0-9]{1,2}$/.test(value)) {
				const parsed = Number.parseInt(value);
				if (parsed >= 0 && parsed <= 23) {
					return this.removeError('Invalid hours!');
				}
			}
			return this.addError('Invalid hours!');
		},
		minutesRule(value) {
			if (/^[0-9]{1,2}$/.test(value)) {
				const parsed = Number.parseInt(value);
				if (parsed >= 0 && parsed <= 59) {
					return this.removeError('Invalid minutes!');
				}
			}
			return this.addError('Invalid minutes!');
		},
	},
};
</script>
<style lang="scss" scoped>
.inputWidth {
	width: 500px;
}
</style>
