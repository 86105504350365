<template>
	<v-app>
		<air-app-bar :mini.sync="navbarMini" :drawerExtended.sync="navbarExtended" :permanent="permanent"></air-app-bar>
		<air-navigation
			v-model="navbarExtended"
			:mini-variant="navbarMini"
			:permanent="permanent"
			:navItems="routes.filter((item) => !!item.icon)"
			app
			clipped
			left
			dark
		></air-navigation>

		<air-sideboard
			v-if="currentRouteHasView('rightSideboard')"
			:mini.sync="rightSideboardMini"
			permanent
			app
			clipped
			right
			dark
		>
			<router-view name="rightSideboard"></router-view>
		</air-sideboard>
		<v-main>
			<router-view />
		</v-main>
	</v-app>
</template>

<script>
import routes from './router/routes';

import { mapActions } from 'vuex';
import { LOAD } from '@/store/actionTypes';

export default {
	name: 'App',
	data: () => ({
		routes: routes,
		navbarExtended: false,
		navbarMini: false,
		rightSideboardMini: false,
	}),
	computed: {
		permanent() {
			return this.$vuetify.breakpoint.mdAndUp;
		},
		sideboardExpandOnHover() {
			return this.$vuetify.breakpoint.mdAndDown;
		},
	},
	methods: {
		...mapActions('crud', { populateCrudStore: LOAD }),
		currentRouteHasView(viewName) {
			const found = this.routes.filter(
				(route) => route.path === this.$route.path && route.components && route.components[viewName]
			);
			return found.length;
		},
		switchHotkeyRoutes(e) {
			const foundRoutes = this.routes.filter((item) => item.hotkey === e.key);
			if (foundRoutes.length === 1 && (e.ctrlKey || e.metaKey) && this.$route.path !== foundRoutes[0].path) {
				e.preventDefault();
				this.$router.push(foundRoutes[0].path);
			}
		},
	},
	created() {
		this.populateCrudStore();
		this.navbarExtended = this.$vuetify.breakpoint.mdAndUp;
		this.navbarMini = this.$vuetify.breakpoint.mdAndUp && this.$vuetify.breakpoint.mdAndDown;
		this.rightSideboardMini = this.$vuetify.breakpoint.mdAndUp && this.$vuetify.breakpoint.mdAndDown;

		window.addEventListener('keydown', this.switchHotkeyRoutes);
	},
	destroyed() {
		window.removeEventListener('keydown', this.switchHotkeyRoutes);
	},
};
</script>
