const data = [
	{
		rule: 'I',
		description: 'IFR',
	},
	{
		rule: 'V',
		description: 'VFR',
	},
	{
		rule: 'Y',
		description: 'IFR/VFR',
	},
	{
		rule: 'Z',
		description: 'VFR/IFR/VFR',
	},
];

function getRules() {
	return data.map((element) => element.rule);
}

function getDescriptions() {
	return data.map((element) => element.description);
}
function getRulesDescriptions() {
	return data.map((element) => element.rule + '-' + element.description);
}
export default {
	getRules,
	getDescriptions,
	getRulesDescriptions,
};
