const data = [
	{
		variant: 'Auto',
		description: 'Autmatic Routing',
	},
	{
		variant: 'Manual',
		description: 'Manual Routing',
	},
];

function getVariants() {
	return data.map((element) => element.variant);
}

function getDescriptions() {
	return data.map((element) => element.description);
}
function getVariantsDescriptions() {
	return data.map((element) => element.variant + ' ' + element.description);
}
export default {
	getVariants,
	getDescriptions,
	getVariantsDescriptions,
};
