const _ = require('lodash');

function items(items, searchFields, searchQuery, searchType = 'AND') {
	if (searchQuery) {
		var filtered = [];

		for (let item of items) {
			let matches;
			if (_.capitalize(searchType) === 'OR') matches = false;
			else if (_.capitalize(searchType) === 'AND') matches = true;
			else throw new Error('Illegal argument for parameter "searchType", choose either "AND" or "OR".');

			for (let keyword of searchQuery.toLowerCase().trim().split(' ')) {
				if (_.capitalize(searchType) === 'OR') {
					matches = matches || searchInObject(item, searchFields, keyword);
				}
				if (_.capitalize(searchType) === 'AND') {
					matches = matches && searchInObject(item, searchFields, keyword);
				}
			}

			if (matches) filtered.push(item);
		}
		return filtered;
	} else {
		return items;
	}
}

/**
 * This function provides a deep search for a keyword within certain properties of an object.
 * @param item An object which should be searched.
 * @param searchProperties An array(type string) of properties which are check if they contain the keyword.
 * @param keyword The string which is searched for.
 */
function searchInObject(item, searchProperties, keyword) {
	var aggregate = false;
	const keywordLowerCase = _.lowerCase(keyword);

	for (const field of searchProperties) {
		const currentProperty = _.get(item, field);
		aggregate = aggregate || this.$_.lowerCase(currentProperty).includes(keywordLowerCase);
	}
	return aggregate;
}

export default {
	items,
};
