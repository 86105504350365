<template>
	<svg width="21.449" height="21.449" version="1.1" viewBox="0 0 5.6751 5.6751" xmlns="http://www.w3.org/2000/svg">
		<!-- background -->
		<path
			d="m5.6587 2.5352c0.16722 1.5582-0.96061 2.9563-2.5188 3.1235-1.5579 0.16686-2.9563-0.96061-3.1235-2.5188-0.16686-1.5579 0.96061-2.9563 2.5188-3.1235 1.5582-0.16686 2.9566 0.96061 3.1235 2.5188"
			:fill="backgroundColor"
			:fill-opacity="opacity"
		/>
		<!-- triangle -->
		<path
			d="m1.8843 3.5401 0.94239-1.6311 0.94194 1.6313zm2.4981 0.029886-1.2917-2.2372c-0.1084-0.19009-0.41956-0.19012-0.52792-2.128e-4l-1.2923 2.2375c-0.054418 0.093862-0.054444 0.20988 2.66e-5 0.30407 0.054396 0.09489 0.15529 0.15325 0.26421 0.15323l2.5837 3.03e-5c0.10889 3.26e-4 0.20988-0.058028 0.26406-0.15298 0.054807-0.094175 0.054482-0.21023 1.1e-5 -0.30442"
			:fill="color"
		/>
		<path v-if="reporting" d="m2.7975 1.6677 1.2121 1.9904-2.4247-2.876e-4z" fill="#222555" />
		<ellipse
			v-if="overfly"
			cx="2.8369"
			cy="2.837"
			rx="2.6726"
			ry="2.6726"
			fill="none"
			:stroke="color"
			stroke-width=".3287"
		/>
	</svg>
</template>
<script>
export default {
	name: 'fwSvgFix',
	props: {
		color: String,
		backgroundColor: String,
		reporting: Boolean,
		overfly: Boolean,
		opacity: Number,
	},
};
</script>
