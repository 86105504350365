<template>
	<v-card>
		<v-card-title class="golden--text">Flight Plan Preview</v-card-title>
		<v-card-text v-if="!flightPlan.aircraft">No aircraft selected.</v-card-text>
		<v-card-text v-else class="pa-4">
			<v-card class="mb-4">
				<v-card-title>Load</v-card-title>
				<v-card-text>
					<v-data-table
						:items="load"
						:headers="loadHeaders"
						dense
						disable-sort
						disable-filtering
						disable-pagination
						hide-default-footer
					>
						<template v-slot:[`item`]="{ item }">
							<tr :class="item.style">
								<td>{{ item.description }}</td>
								<td class="pr-1 text-right">{{ item.value }}</td>
								<td class="pl-0">{{ item.unit }}</td>
							</tr>
						</template>
					</v-data-table>
				</v-card-text>
			</v-card>
			<v-card>
				<v-card-title>Energy/Time</v-card-title>
				<v-card-text class="d-inline-block">
					<v-data-table
						:items="energyTime"
						:headers="energyTimeHeaders"
						dense
						disable-sort
						disable-filtering
						disable-pagination
						hide-default-footer
					>
						<template v-slot:[`item.phase`]="{ item }">
							<span :class="item.style ? item.style : ''">{{ item.phase[0].toUpperCase() + item.phase.slice(1) }}</span>
						</template>
						<template v-slot:[`item.time`]="{ item }">
							<span :class="item.style ? item.style : ''">{{
								$services.utils.dateFormatter.minutesToTimeString(item.time)
							}}</span>
						</template>
						<template v-slot:[`item.energy`]="{ item }">
							<span :class="item.style ? item.style : ''">{{ item.energy }} kWh</span>
						</template>
					</v-data-table>
				</v-card-text>
			</v-card>
		</v-card-text>
	</v-card>
</template>
<script>
import { FlightPlan } from '@/models';
export default {
	name: 'fpComputedPreview',
	props: {
		flightPlan: FlightPlan,
		aircraftTypes: Object,
	},
	data: () => ({
		panelExpanded: [1, 0],
		energyTimeHeaders: [
			{ value: 'phase', text: 'Phase', align: 'start' },
			{ value: 'time', text: 'Time', align: 'end' },
			{ value: 'energy', text: 'Energy', align: 'end' },
		],
		loadHeaders: [
			{ value: 'description', text: 'Load', align: 'start' },
			{ value: 'value', text: 'Amount', align: 'end' },
			{ value: 'unit', text: '', align: 'start' },
		],
	}),
	computed: {
		energyTime() {
			const eet = (new Date(this.flightPlan.general.sta) - new Date(this.flightPlan.general.std)) / (60 * 1000);

			const energy = this.aircraftTypes[this.flightPlan.aircraft.references.aircraftType].performance.energyPerMinute;
			const items = [
				{
					phase: 'Taxi',
					time: Number.parseInt(this.flightPlan.endurance.time.taxi),
					energy: Number.parseInt(this.flightPlan.endurance.time.taxi) * energy.taxi,
				},
				{
					phase: 'Trip',
					time: eet,
					energy: eet * energy.cruise,
				},
				{
					phase: 'Contingency',
					time: Number.parseInt(this.flightPlan.endurance.time.contingency),
					energy: Number.parseInt(this.flightPlan.endurance.time.contingency) * energy.cruise,
				},
				{
					phase: 'Reserve',
					time: Number.parseInt(this.flightPlan.endurance.time.reserve),
					energy: Number.parseInt(this.flightPlan.endurance.time.reserve) * energy.cruise,
				},
				{
					phase: 'Additional',
					time: Number.parseInt(this.flightPlan.endurance.time.additional),
					energy: Number.parseInt(this.flightPlan.endurance.time.additional) * energy.cruise,
				},
				{
					phase: 'Extra',
					time: Number.parseInt(this.flightPlan.endurance.time.extra),
					energy: Number.parseInt(this.flightPlan.endurance.time.extra) * energy.cruise,
				},
			];
			items.push({
				phase: 'Total',
				time: items.reduce((accumulator, item) => accumulator + item.time, 0),
				energy: items.reduce((accumulator, item) => accumulator + item.energy, 0),
				style: 'font-weight-bold',
			});
			return items;
		},
		load() {
			const payload =
				Number.parseInt(this.flightPlan.load.pax) * Number.parseInt(this.flightPlan.load.paxMass) +
				Number.parseInt(this.flightPlan.load.baggage) +
				Number.parseInt(this.flightPlan.load.cargo);
			const emptyMass = this.flightPlan.aircraft.masses.emptyMass;

			return [
				{
					description: 'Empty Mass',
					value: emptyMass,
					unit: this.$services.utils.units.mass,
					style: 'font-weight-bold',
				},
				{ description: 'PAX', value: this.flightPlan.load.pax, unit: '' },
				{ description: 'Baggage', value: this.flightPlan.load.baggage, unit: this.$services.utils.units.mass },
				{ description: 'Cargo', value: this.flightPlan.load.cargo, unit: this.$services.utils.units.mass },
				{
					description: 'Payload',
					value: payload,
					unit: this.$services.utils.units.mass,
					style: 'font-weight-bold',
				},
				{
					description: 'Actual Mass',
					value: emptyMass + payload,
					unit: this.$services.utils.units.mass,
					style: 'font-weight-bold',
				},
			];
		},
	},
};
</script>
