import { make } from 'vuex-pathify';
import { state as openAipState } from './openAip';
import { state as openWeatherMapState } from './openWeatherMap';

const state = {
	// https://leaflet-extras.github.io/leaflet-providers/preview/
	availableTiles: {
		esriWorldStreet: {
			name: 'Street',
			dark: false,
			url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}',
			minZoom: 3,
			maxZoom: 18,
			attribution:
				'Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012',
		},
		openTopo: {
			//new Map
			name: 'Topography',
			dark: false,
			url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
			minZoom: 3,
			maxZoom: 16,
			attribution:
				'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
		},
		openGeo: {
			name: 'Geo-Info',
			dark: false,
			url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
			minZoom: 3,
			maxZoom: 16,
			attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
		},
		esriWorldTopo: {
			name: 'Topo',
			dark: false,
			url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
			minZoom: 3,
			maxZoom: 18,
			attribution:
				'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC, USGS, FAO, NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), and the GIS User Community',
		},
		esriWorldTerrain: {
			name: 'Terrain',
			dark: false,
			url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Terrain_Base/MapServer/tile/{z}/{y}/{x}',
			minZoom: 3,
			maxZoom: 9,
			attribution: 'Tiles &copy; Esri &mdash; Source: USGS, Esri, TANA, DeLorme, and NPS',
		},
		esriWorldImagery: {
			name: 'Imagery',
			dark: true,
			url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
			minZoom: 3,
			maxZoom: 18,
			attribution:
				'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
		},
		esriLight: {
			name: 'Light',
			dark: false,
			url:
				'https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}',
			minZoom: 3,
			maxZoom: 16,
			attribution: 'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ',
		},
	},
	availableOverlays: {
		...openAipState,
		...openWeatherMapState,
	},
};

export const store = {
	namespaced: true,
	modules: {},
	state: () => state,
	getters: make.getters(state),
	mutations: make.mutations(state),
};
