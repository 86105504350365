<template>
	<svg width="16" height="16" version="1.1" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
		<g fill="none" :stroke="color">
			<rect x="1.494" y="1.5061" width="13.007" height="12.987" stroke-width="1.1073" />
			<g v-if="subtype === 'ToC'">
				<path d="m3.2582 11.07h4.011l2.9961-5.1894" stroke-width="1px" />
				<path
					transform="rotate(60 9.6835 6.4501)"
					d="m9.4816 5.924v-0.13118-0.13118l0.22721 0.13118-0.1136 0.065589z"
				/>
				<path d="m12.758 4.4866h-4.4684" stroke-width="1.0555px" />
			</g>
			<g v-else-if="subtype === 'ToD'">
				<path d="m3.2582 4.4589h4.011l2.9961 5.1894" stroke-width="1px" />
				<path
					transform="matrix(.5 -.86603 -.86603 -.5 10.428 20.69)"
					d="m9.4816 5.924v-0.13118-0.13118l0.22721 0.13118-0.1136 0.065589z"
				/>
				<path d="m12.758 11.043h-4.4684" stroke-width="1.0555px" />
			</g>
		</g>
	</svg>
</template>
<script>
export default {
	name: 'fwSvgTopOf',
	props: {
		color: String,
		subtype: String,
	},
};
</script>
