import { LOAD } from '@/store/actionTypes';

import { store as AircraftType } from './aircraftType';
import { store as Aircraft } from './aircraft';
import { store as Flight } from './flight';
import { store as FlightPlan } from './flightPlan';

const modules = {
	AircraftType,
	Aircraft,
	Flight,
	FlightPlan,
};

export const store = {
	namespaced: true,
	modules,
	actions: {
		[LOAD]: async function ({ dispatch }, payload) {
			var selectedModules;

			if (Array.isArray(payload)) {
				selectedModules = payload;
			} else if (typeof payload === 'string') {
				selectedModules = [modules[payload]];
			} else {
				selectedModules = Object.keys(modules);
			}

			return Promise.allSettled(
				selectedModules.map((module) => dispatch(`${module.name ? module.name : module}/LOAD`)) // if the module is a class, use the class name. Otherwise, trust its already a moduleName
			);
		},
	},
};
