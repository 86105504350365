<template>
	<g class="windrose">
		<title>windrose</title>
		<g :stroke="lineColor" stroke-miterlimit="10">
			<line :x1="xOffset + 52.4" :x2="xOffset + 52.4" :y1="yOffset + 4.5" :y2="yOffset + 13.9" />
			<line :x1="xOffset + 52.4" :x2="xOffset + 52.4" :y1="yOffset + 76" :y2="yOffset + 85.4" />
			<circle :cx="xOffset + 52.4" :cy="yOffset + 44.9" r="31" fill="none" />
			<line :x1="xOffset + 92.7" :x2="xOffset + 83.4" :y1="yOffset + 44.9" :y2="yOffset + 44.9" />
			<line :x1="xOffset + 21.4" :x2="xOffset + 12" :y1="yOffset + 44.9" :y2="yOffset + 44.9" />
		</g>
		<g :fill="textColor" font-family="Roboto-Regular, Roboto" font-size="9.58px">
			<text :x="xOffset + 49.9" :y="yOffset + 0">N</text>
			<text :x="xOffset + 98.2" :y="yOffset + 48.2">E</text>
			<text :x="xOffset + 48.5" :y="yOffset + 98.5">S</text>
			<text :x="xOffset" :y="yOffset + 48">W</text>
		</g>
		<path
			:transform="`rotate(${windDirection} ${xOffset + 52.6} ${yOffset + 43.95})`"
			:d="`m${xOffset + 68.2} ${
				yOffset + 58.8
			}-15.3-35a0.5 0.5 0 0 0-0.5-0.3c-0.3 0-0.5 0.2-0.5 0.3l-15.2 35.1c-0.1 0.3-0.1 0.5 0.2 0.7s0.3 0.2 0.6 0.1l15-9.6 14.6 9.4c0.4 0.2 0.7 0.3 1 0.1a0.8 0.8 0 0 0 0.1-0.8z`"
			:fill="arrowColor"
		/>
	</g>
</template>
<script>
export default {
	name: 'svgWindrose',
	props: {
		arrowColor: String,
		lineColor: String,
		textColor: String,
		xOffset: {
			type: Number,
			default: 0,
		},
		yOffset: {
			type: Number,
			default: 0,
		},
		windDirection: Number,
	},
};
</script>
