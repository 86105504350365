<template>
	<div>
		<template v-for="(airport, index) in ['departure', 'arrival']">
			<v-card :key="index">
				<div class="d-inline-block">
					<v-card-title>
						<span>{{ value[airport].ICAO }}</span>
						<span class="mx-4">-</span>
						<span>{{ value[airport].IATA }}</span>
					</v-card-title>
					<v-card-subtitle class="pb-1">
						<span>{{ value[airport].name }}, {{ value[airport].regionName }}</span>
					</v-card-subtitle>
					<v-divider class="my-1" />
				</div>
				<v-card-text>
					<v-row>
						<v-col cols="4" md="2">
							<div :class="value[airport].runwayCount === 1 ? 'orange--text' : ''">
								Pads{{ value[airport].runwayCount > 1 ? '' : '' }}
							</div>
							<div>Elevation</div>
							<div>Postition</div>
							<div>mag. Variation</div>
							<div>Operating Hours</div>
							<div>Timezone</div>
						</v-col>
						<v-col cols="8" md="4">
							<div :class="value[airport].runwayCount === 1 ? 'orange--text' : ''">
								{{ value[airport].runwayCount * 4 }}
							</div>
							<div class="font-weight-bold">{{ value[airport].elevation }}ft</div>
							<div>
								<span> {{ value[airport].lat >= 0 ? 'N' : 'S' }}{{ value[airport].lat.toFixed(4) }} </span>
								<span> {{ value[airport].lon >= 0 ? 'E' : 'W' }}{{ value[airport].lon.toFixed(4) }} </span>
							</div>
							<div>
								{{
									value[airport].magneticVariation.toFixed(2) + ' ' + (value[airport].magneticVariation > 0 ? 'E' : 'W')
								}}
							</div>
							<div>24h</div>
							<div>
								{{ value[airport].timezone.name }} (UTC
								{{
									value[airport].timezone.offset > 0
										? '+' + value[airport].timezone.offset / 3600
										: value[airport].timezone.offset / 3600
								}}h)
							</div>
						</v-col>
					</v-row>
					<v-divider class="my-3" />
					<air-metar-taf :weather="value[airport].weather" />
					<v-divider class="my-3" />
					<!-- <air-runways :runways="value[airport].runways" /> -->
					<v-divider class="my-3" />
				</v-card-text>
			</v-card>
		</template>
	</div>
</template>
<script>
export default {
	name: 'AirFlightPlanAirports',
	props: {
		value: Object,
	},
	watch: {
		value() {
			this.$emit('input', this.value);
		},
	},
};
</script>
