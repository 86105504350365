<template>
	<v-container fluid>
		<v-row>
			<v-col cols="12" md="6" lg="4" xl="3">
				<air-item-search-list
					class="my-3"
					v-model="selectedIndex"
					title="Aircraft Management"
					:items="Object.values(items)"
					:disabled="operationStatus.busy || isListDisabled"
					:loading="operationStatus.loading"
					:maxHeight="500"
					:searchFields="['general.registration', 'general.homebase', 'aircraftType.general.typeDesignator']"
					:uploadModelName="modelName"
					@delete="openArchiveDialog"
					@select="setActiveItem"
					@new="newItem"
					@uploaded="loadItems"
				>
					<template v-slot:[`item-title`]="{ item }">
						<span>{{ item.general.registration }}</span>
					</template>
					<template v-slot:[`item-subtitle`]="{ item }">
						<v-row>
							<v-col cols="4">
								{{ item.general.homebase }}
							</v-col>
							<v-col cols="4">
								{{ aircraftTypeItems[item.references.aircraftType].general.typeDesignator }}
							</v-col>
							<v-col cols="2">
								{{ item.passengers }}
							</v-col>
						</v-row>
					</template>
				</air-item-search-list>
			</v-col>

			<v-col cols="12" md="6" lg="8" xl="9">
				<air-aircraft-editor
					ref="editor"
					v-model="temporaryItem"
					:aircraftTypes="Object.values(aircraftTypeItems)"
					:disabled="operationStatus.busy || isEditorDisabled"
					@cancel="cancelEdit"
					@save="saveActiveItem"
				/>
			</v-col>
		</v-row>
		<yes-cancel-dialog
			v-model="dialogOpen"
			title="Archive Aircraft"
			message="Are your sure you want to archive the Aircraft?"
			@yes="archiveItem()"
		/>
	</v-container>
</template>

<script>
import { Aircraft, AircraftType } from '@/models';
import { generateMixin as generateCrudManagementMixin } from '@/mixins/crud/crudManagement';
import { generateMixin as generateAggregatedOperationStatusMixin } from '@/mixins/aggregatedOperationStatus';

export default {
	name: `AircraftManagement`,
	mixins: [
		generateAggregatedOperationStatusMixin(Aircraft, AircraftType),
		generateCrudManagementMixin(Aircraft, AircraftType),
	],
};
</script>
