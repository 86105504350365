<template>
	<v-data-table
		:headers="headers"
		:items="value"
		:items-per-page="100"
		:class="'elevation-10' + (fluid ? '' : ' d-inline-block')"
		disable-sort
		disable-pagination
		disable-filtering
		hide-default-footer
	>
		<template v-for="(value, index) in ['distance', 'Time', 'Energy']" v-slot:[`header.${value}`]="{ header }">
			<div class="my-2" :key="index">
				<div class="">
					{{ header.text.split(' ')[0] }}
				</div>
				<div class="caption">[{{ header.text.split(' ')[1].split('/')[0] }}]</div>
				<div class="caption">[{{ header.text.split(' ')[1].split('/')[1] }}]</div>
			</div>
		</template>
		<template v-slot:[`item.ident`]="{ item }">
			<div v-if="item.ident === 'ToC' || item.ident === 'ToD'" class="purple--text text--lighten-1">
				{{ item.ident }}
			</div>
			<div v-else>
				{{ item.ident }}
			</div>
		</template>
		<template v-slot:[`item.via`]="{ item }">
			<span>{{ item.via ? item.via.ident : '' }}</span>
		</template>
		<template v-slot:[`item.position`]="{ item }">
			<div>{{ item.position.latitude >= 0 ? 'N' : 'S' }}{{ item.position.latitude.toFixed(4) }}</div>
			<div>{{ item.position.longitude >= 0 ? 'E' : 'W' }}{{ item.position.longitude.toFixed(4) }}</div>
		</template>
		<template v-slot:[`item.magneticTrack`]="{ item }">
			<div>
				{{ item.magneticTrack !== null ? Math.round(item.magneticTrack) + '&deg;' : '' }}
			</div>
		</template>
		<template v-slot:[`item.distance`]="{ item }">
			<div class="d-inline-block">
				<div class="text-right">
					{{ Math.round(item.distance) }}
				</div>
				<div class="text-right">
					{{ Math.round(item.accumulatedDistance) }}
				</div>
			</div>
		</template>
		<template v-slot:[`item.time`]="{ item }">
			<div class="d-inline-block">
				<div class="text-right">
					{{ $services.utils.dateFormatter.minutesToTimeString(Math.round(item.estimatedSegmentTime)) }}
				</div>
				<div class="text-right">
					{{ $services.utils.dateFormatter.minutesToTimeString(Math.round(item.estimatedAccumulatedTime)) }}
				</div>
			</div>
		</template>
		<template v-slot:[`item.energy`]="{ item }">
			<div class="d-inline-block">
				<div class="text-right">
					{{ Math.round(item.estimatedSegmentEnergy) }}
				</div>
				<div class="text-right">
					{{ Math.round(item.estimatedAccumulatedEnergy) }}
				</div>
			</div>
		</template>
		<template v-slot:[`body.prepend`]="">
			<tr>
				<td colspan="100">
					<air-flight-plan-frequencies v-model="airports.departure.frequencies" />
				</td>
			</tr>
		</template>
		<template v-slot:[`body.append`]="">
			<tr>
				<td colspan="100">
					<air-flight-plan-frequencies v-model="airports.arrival.frequencies" />
				</td>
			</tr>
		</template>
	</v-data-table>
</template>
<script>
export default {
	props: {
		value: Array,
		airports: Object,
		fluid: Boolean,
	},
	data: () => ({
		headers: [
			{ text: 'Waypoint', value: 'ident', align: 'start' },
			{ text: 'Airway', value: 'via', align: 'start' },
			{ text: 'Position', value: 'position', align: 'start' },
			{ text: 'Altitude', value: 'altitude', align: 'center' },
			{ text: 'TAS', value: 'tas', align: 'center' },
			{ text: 'mag. Track', value: 'magneticTrack', align: 'center' },
			{ text: 'Distance leg/acc.', value: 'distance', align: 'center' },
			{ text: 'Time leg/acc.', value: 'time', align: 'center' },
			{ text: 'Energy leg/acc.', value: 'energy', align: 'center' },
		],
	}),
	watch: {
		value() {
			this.$emit('input', this.value);
		},
	},
};
</script>
