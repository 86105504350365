<template>
	<div
		v-if="
			(waypointsVisible &&
				(waypoint.type === 'FIX' ||
					waypoint.type === 'LATLON' ||
					waypoint.type === 'VOR' ||
					waypoint.type === 'DME')) ||
			(vertiportsVisible && waypoint.type === 'APT') ||
			(pseudoWaypointsVisible && waypoint.type === 'PSY')
		"
	>
		<fw-area-of-operation v-if="waypoint.type === 'APT'" :position="waypoint.position"></fw-area-of-operation>
		<l-marker @click="selectMapObject(waypoint)" :lat-lng="waypoint.position">
			<!-- https://vue2-leaflet.netlify.app/components/LIcon.html#demo -->
			<l-icon :iconAnchor="iconAnchor">
				<fw-svg-fix
					v-if="
						waypoint.type === 'FIX' || waypoint.type === 'LATLON' || waypoint.type === 'VOR' || waypoint.type === 'DME'
					"
					:backgroundColor="waypointBackgroundColor"
					:color="isThisMapObjectSelected ? selectedColor : waypointColor"
					:reporting="waypoint.reporting"
					:overfly="waypoint.overfly"
					:opacity="waypointOpacity"
				/>
				<fw-svg-top-of
					v-else-if="waypoint.type === 'PSY'"
					:color="isThisMapObjectSelected ? selectedColor : waypointColor"
					:subtype="waypoint.ident"
				/>
				<!-- <fw-svg-vor-dme
				v-else-if="waypoint.type === 'VOR' || waypoint.type === 'DME'"
				:vor="waypoint.type === 'VOR'"
				:dme="waypoint.type === 'DME'"
				:color="isThisMapObjectSelected ? selectedColor : color"
			/> -->
				<fw-svg-vertiport
					v-else-if="waypoint.type === 'APT'"
					:backgroundColor="vertiportBackgroundColor"
					:color="isThisMapObjectSelected ? selectedColor : vertiportColor"
				/>
			</l-icon>
			<l-popup class="text-center" ref="popup">
				<div class="font-weight-bold">{{ waypoint.ident }}</div>
				<div>{{ waypoint.name }}</div>
			</l-popup>
		</l-marker>
	</div>
</template>
<script>
import { get } from 'vuex-pathify';
import { Waypoint } from '@/models/Waypoint';
import { mixin as selectableMapObject } from '@/mixins/selectableMapObject';
export default {
	name: `FlightWatchWaypoint`,
	mixins: [selectableMapObject],
	props: {
		waypoint: Waypoint,
	},
	data: () => ({}),
	computed: {
		...get(`flightWatch/settings/layers`, {
			selectedColor: 'general@selectedColor',
			waypointsVisible: 'flightPlans@route.waypoints.visible',
			waypointColor: 'flightPlans@route.waypoints.color',
			waypointBackgroundColor: 'flightPlans@route.waypoints.backgroundColor',
			vertiportsVisible: 'flightPlans@route.vertiports.visible',
			vertiportColor: 'flightPlans@route.vertiports.color',
			vertiportBackgroundColor: 'flightPlans@route.vertiports.backgroundColor',
			pseudoWaypointsVisible: 'flightPlans@route.pseudoWaypoints.visible',
			waypointOpacity: 'flightPlans@route.waypoints.opacity',
		}),
		iconAnchor() {
			var type = 'fix';
			if (
				this.waypoint.type === 'FIX' ||
				this.waypoint.type === 'LATLON' ||
				this.waypoint.type === 'VOR' ||
				this.waypoint.type === 'DME'
			)
				type = 'fix';
			if (this.waypoint.type === 'APT') type = 'vertiport';

			const anchors = {
				fix: [11, 10],
				vertiport: [13, 13],
			};

			return anchors[type];
		},
	},
	methods: {
		registerEventHandlers() {
			console.log(this.$refs.popup.mapObject);
			// this.$refs.fwWaypoint.on('popupopen', this.output);
		},
		output() {
			console.log('popup event');
		},
	},
};
</script>
