// ToDo: Flatten options
export const state = {
	aip: {
		name: 'AIP',
		url:
			'https://{s}.tile.maps.openaip.net/geowebcache/service/tms/1.0.0/openaip_basemap@EPSG%3A900913@png/{z}/{x}/{y}.png',
		attribution:
			'<a href="https://www.openaip.net/">openAIP Data</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-NC-SA</a>)',
		minZoom: 4,
		maxZoom: 14,
		tms: true,
		detectRetina: true,
		subdomains: '12',
	},
};
