<template>
	<v-row class="ma-0" justify="center">
		<v-col cols="12" sm="12" md="12" lg="12" xl="12">
			<v-card>
				<v-card-title class="display-2 mb-5 d-none d-md-flex">
					<span>Welcome to</span>
					<img class="ml-6 mt-4" alt="aeronymos" :src="logoUrl" width="250px" />
				</v-card-title>
				<v-card-title class="display-1 mb-5 d-sm-flex d-md-none">
					<span>Welcome to</span>
					<img class="ml-6 mt-4" alt="aeronymos" :src="logoUrl" width="160px" />
				</v-card-title>
				<v-card-subtitle class="headline font-weight-bold">The project</v-card-subtitle>
				<v-card-text>
					<span>
						Presently there are no flight plaining systems on the market that compute flight plans routes and electric
						energy consumption based on the specific flight profiles of e-VTOL aircraft.
					</span>
					<span>The same applies for system supported operations control, crew scheduling, and flight watch.</span>
					<br />
					<span>
						aeronymos is a project driven by flight operation professionals and programmers who believe in the future of
						electric propelled and/or electric vertical takeoff and landing (e-VTOL) aircraft. This new technology and
						its future modus operandi require re-thinking and re-designing of current methods and systems
					</span>
				</v-card-text>
				<v-card-subtitle class="headline font-weight-bold">The mission</v-card-subtitle>
				<v-card-text>
					The project mission is to develop a state-of-the-art flight management solution for e-VTOLs by 2022. The
					system should be easy to use but at the same time provide the ultimate level of planning and management
					capabilities to handle future operations of e-VTOL aircraft. aeronymos will allow to operate a single aircraft
					or complex networks with numerous aircraft. As the only 21st century e-VTOL flight management system it will
					contribute to this promising future technology.
				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: 'WelcomeToAeronymos',

	data: () => ({}),
	computed: {
		logoUrl() {
			// return require('@/assets/logo_light.svg');
			if (this.$vuetify.theme.dark) {
				return require('@/assets/logo_dark.svg');
			} else {
				return require('@/assets/logo_light.svg');
			}
		},
	},
};
</script>
