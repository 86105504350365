<template>
	<svg width="25" height="25" version="1.1" viewBox="0 0 15.875 15.875" xmlns="http://www.w3.org/2000/svg">
		<ellipse
			id="path1689-6"
			transform="translate(-43.648 -79.056)"
			cx="51.586"
			cy="86.994"
			rx="7.1438"
			ry="7.1438"
			:fill="backgroundColor"
		/>
		<g :stroke="color">
			<circle
				:fill="backgroundColor"
				transform="translate(-43.648 -79.056)"
				cx="51.586"
				cy="86.994"
				r="4.7625"
				stroke-width="1.0583"
			/>
			<g transform="translate(-27.009 -79.862)" stroke-width=".52917">
				<path id="path1423" d="m34.946 89.384-1.0962-3.0304" />
				<path id="path1423-3" d="m34.946 89.384 1.0962-3.0304" />
			</g>
		</g>
	</svg>
</template>
<script>
export default {
	name: 'fwSvgVertiport',
	props: {
		color: String,
		backgroundColor: String,
	},
};
</script>
