import airports_json from './airports_1.json';

const rawAirports = Object.values(airports_json);
const airports = {};

for (const rawAirport of rawAirports) {
	if (!rawAirport.iata) continue;
	airports[rawAirport.icao] = {
		icao: rawAirport.icao,
		iata: rawAirport.iata,
		name: rawAirport.name,
		city: rawAirport.city,
		state: rawAirport.state,
		country: rawAirport.country,
		elevation: rawAirport.elevation,
		latitude: rawAirport.lat,
		longitude: rawAirport.lon,
		timezone: rawAirport.tz,
	};
}

export default {
	icaoCodes: Object.keys(airports),
	asArray: Object.values(airports),
	getAirportByIcaoCode: (icaoCode) => airports[icaoCode],
	items: airports,
};
