var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"dense":"","items":_vm.runways,"headers":_vm.headers,"items-per-page":20,"disable-sort":"","disable-filtering":"","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([_vm._l((['width', 'length', 'thresholdOffset', 'overrunLength', 'markings', 'lighting']),function(value,index){return {key:("item." + value),fn:function(ref){
var item = ref.item;
return [(value === 'markings' || value === 'lighting')?_c('span',{key:index},[_vm._v(_vm._s(item[value].join(', ')))]):(value === 'length')?_c('span',{key:index,class:item[value] < 1800
					? 'red--text'
					: item[value] < 2400
					? 'orange--text'
					: item[value] > 3000
					? 'green--text'
					: ''},[_vm._v(_vm._s(item[value].toFixed(0))+"m")]):(value === 'width')?_c('span',{key:index,class:item[value] < 43 ? 'red--text' : item[value] > 47 ? 'green--text' : ''},[_vm._v(_vm._s(item[value].toFixed(0))+"m")]):_c('span',{key:index},[_vm._v(_vm._s(item[value].toFixed(0))+"m")])]}}}),{key:"item.bearing",fn:function(ref){
					var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.bearing.toFixed(0))+"°")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }