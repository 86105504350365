var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"cardSearchList",attrs:{"disabled":_vm.disabled || _vm.loading}},[_c('v-card-title',[_c('span',[_vm._v(_vm._s(_vm.title))]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('air-file-upload',{attrs:{"modelName":_vm.uploadModelName,"accept":".csv"},on:{"saved":function($event){return _vm.$emit('uploaded')}}})],1)]}}])},[_c('span',[_vm._t("newTooltip",function(){return [_vm._v("Upload CSV")]})],2)]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{attrs:{"fab":""},on:{"click":function($event){return _vm.$emit('new')}}},[_c('v-icon',[_vm._v(" mdi-plus ")])],1)],1)]}}])},[_c('span',[_vm._t("newTooltip",function(){return [_vm._v("New")]})],2)])],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"8","sm":"6","md":"8"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Search","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('v-col',{attrs:{"cols":"12","xs":"4","sm":"6","md":"4"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticStyle:{"width":"min-content"}},'div',attrs,false),on),[_c('v-switch',{staticClass:"my-1",attrs:{"true-value":"AND","false-value":"OR","inset":"","label":_vm.searchType,"dense":""},model:{value:(_vm.searchType),callback:function ($$v) {_vm.searchType=$$v},expression:"searchType"}})],1)]}}])},[_c('span',[_vm._v("AND: Single Search"),_c('br'),_vm._v("OR: Multiple Search")])])],1)],1),(_vm.loading)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-skeleton-loader',{attrs:{"type":"list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"}})],1)],1):_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-list',{staticClass:"overflow-y-auto",attrs:{"dense":"","rounded":"","max-height":_vm.maxHeight}},[_c('v-list-item-group',{model:{value:(_vm.selectedIndex),callback:function ($$v) {_vm.selectedIndex=$$v},expression:"selectedIndex"}},[_vm._l((_vm.filteredItems),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-content',{on:{"click":function($event){return _vm.$emit('select', item)}}},[_c('v-list-item-title',[_vm._t("item-title",null,{"item":item})],2),_c('v-list-item-subtitle',[_vm._t("item-subtitle",null,{"item":item})],2)],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('delete', item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)],1)}),_c('v-divider')],2)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }