import { make } from 'vuex-pathify';
import { store as layers } from './layers';

const state = {};

export const store = {
	namespaced: true,
	modules: { layers },
	state: () => state,
	getters: {
		...make.getters(state),
	},
	mutations: {
		...make.mutations(state),
	},
};
