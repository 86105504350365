<template>
	<svg width="16" height="16" version="1.1" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
		<g fill="none" :stroke="color">
			<rect x="1.494" y="1.5061" width="13.007" height="12.987" stroke-width="1.1073" />
			<g v-if="vor" stroke-width="1px">
				<path d="m1.494 7.9844 3.7402-6.4782" />
				<path d="m1.3881 7.8313 3.7402 6.4782" />
				<path d="m14.501 7.9844-3.7402-6.4782" />
				<path d="m14.607 7.8313-3.7402 6.4782" />
			</g>
			<circle v-if="dme" cx="8" cy="7.9844" r=".5" />
		</g>
	</svg>
</template>
<script>
export default {
	name: 'fwSvgFix',
	props: {
		color: String,
		vor: Boolean,
		dme: Boolean,
	},
};
</script>
