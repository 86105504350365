import lodash from 'lodash';
import options from './options';

const defaultAircraftType = {
	_id: null,
	general: {
		manufacturerCode: '',
		modelName: '',
		modelVersion: '',
		typeDesignator: '',
		specialTypeDesignator: '',
		category: '',
	},
	engine: {
		manufacturerCode: '',
		modelNumber: '',
		engineType: '',
		engineCount: null,
		description: '', // (L)andPlane, (2) Engines, (J)et concatenation
	},
	performance: {
		serviceCeiling: null,
		verticalRates: {
			climb: null,
			descent: null,
		},
		energyPerMinute: {
			taxi: null,
			takeoff: null,
			climb: null,
			cruise: null,
			descent: null,
			landing: null,
		},
		speedProfiles: [],
	},
};

function createEmptyAircraftType() {
	return lodash.cloneDeep(defaultAircraftType);
}

export default {
	options,
	createEmptyAircraftType,
};
