import { LOAD } from '@/store/actionTypes';

export const generateMixin = (...models) => {
	if (Array.isArray(models[0])) models = models[0];
	for (var i = 0; i < models.length; i++) {
		if (typeof models[i] === 'function') {
			if (models[i].name) {
				models[i] = models[i].name;
			} else {
				throw new Error(`Unsupported model: ${models[i]}`);
			}
		}
	}

	return {
		beforeCreate() {
			this.$store.dispatch(`crud/${LOAD}`, models);
		},
	};
};
