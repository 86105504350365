<template>
	<v-container>
		<v-row>
			<v-col cols="12" lg="3">
				<v-subheader class="subtitle-1 font-weight-bold">Select Vertiports here:</v-subheader>
			</v-col>
			<v-col cols="12" lg="3">
				<v-combobox
					:items="$services.airports.icaoCodes"
					v-model="selectedVertiports"
					chips
					deletable-chips
					dense
					multiple
				/>
			</v-col>
		</v-row>
		<v-row>
			<v-col v-for="(vertiport, index) of selectedVertiports" :key="index" cols="12" sm="12" md="12" lg="6" xl="6">
				<v-card v-if="vertiportStates && vertiportStates[vertiport]" color="#434343">
					<v-card-text>
						<div>
							<vc-vertiport-control height="580px" :vertiportState="vertiportStates[vertiport]" />
						</div>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
import vcVertiportControl from '../components/vcVertiportControl.vue';
import { LOAD } from '@/store/actionTypes';
import { get, call } from 'vuex-pathify';

export default {
	name: 'vcMultiControl',
	components: {
		vcVertiportControl,
	},
	data() {
		return {
			updateIntervalId: null,
			selectedVertiports: ['EFHK', 'EETN'],
		};
	},
	computed: {
		vertiportStates: get(`flightWatch/vertiportStates/items`),
	},
	watch: {
		selectedVertiports() {
			this.LOAD(this.selectedVertiports);
		},
	},
	methods: {
		[LOAD]: call(`flightWatch/vertiportStates/${LOAD}`),
	},
	created() {
		this.LOAD(this.selectedVertiports);
		this.updateIntervalId = setInterval(() => {
			this.LOAD(this.selectedVertiports);
		}, 20 * 1000);
	},
	beforeDestroy() {
		if (this.updateIntervalId) clearInterval(this.updateIntervalId);
	},
};
</script>
