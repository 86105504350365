const data = [
	{
		sdesig: 'SHIP',
		model: 'Airship',
	},
	{
		sdesig: 'BALL',
		model: 'Balloon',
	},
	{
		sdesig: 'GLID',
		model: 'Glider, Sailplane',
	},
	{
		sdesig: 'ULAC',
		model: 'Microlight Aircraft, Ultralight Aircraft',
	},
	{
		sdesig: 'GYRO',
		model: 'Microlight Autogyro, Ultralight Autogyro',
	},
	{
		sdesig: 'UHEL',
		model: 'Microlight helicopter, Ultralight Helicopter',
	},
	{
		sdesig: 'ZZZZ',
		model: 'Aircraft type not (yet) assigned a designator',
	},
];

function getSdesigs() {
	return data.map((element) => element.sdesig);
}

function getModels() {
	return data.map((element) => element.model);
}
function getSdesigsModels() {
	return data.map((element) => element.sdesig + ' ' + element.model);
}
export default {
	getSdesigs,
	getModels,
	getSdesigsModels,
};
