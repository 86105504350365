const data = [
	{
		code: 'A',
		description: 'Transponder - Mode A (4 digits - 4096 codes) ) ',
	},
	{
		code: 'C',
		description: '(Transponder - Mode A (4 digits - 4096 codes) and Mode C)',
	},
	{
		code: 'S',
		description: 'SSR Mode S',
	},
];

function getSurveillance() {
	// return Object.keys(data).map(
	// 	(key) => data[key].code + '=' + data[key].description
	// );
	return data.map((element) => element.code);
}

export default {
	getSurveillance,
};
