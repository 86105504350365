<template>
	<v-container>
		<welcome-to-aeronymos />
		<service-overview />
	</v-container>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue';

export default {
	name: 'Home',
	components: {
		//HelloWorld,
	},
};
</script>
