<template>
	<l-feature-group v-if="visible">
		<fw-flight
			v-for="(flightPlan, index) of uniqueCitypairs(activeFlightPlans)"
			:key="index"
			:flightPlan="flightPlan"
		></fw-flight>
	</l-feature-group>
</template>
<script>
import { get } from 'vuex-pathify';
import { Flight, FlightPlan } from '@/models';

export default {
	name: 'fwFlightsLayer',
	computed: {
		...get(`flightWatch/settings/layers`, ['flightPlans@visible']),
		flights: get(`crud/${Flight.name}/items`),
		flightPlans: get(`crud/${FlightPlan.name}/items`),
		activeFlightPlans() {
			const activeFlightPlans = [];
			for (const flight of Object.values(this.flights)) {
				const id = flight.references.activeFlightPlan;
				if (id && this.flightPlans[id]) activeFlightPlans.push(this.flightPlans[id]);
			}
			return activeFlightPlans;
		},
	},
	methods: {
		uniqueCitypairs(flightPlans) {
			const citypairs = [];
			const out = [];
			for (const flightPlan of flightPlans) {
				const citypair = flightPlan.general.departure + '-' + flightPlan.general.arrival;
				if (!citypairs.includes(citypair)) {
					citypairs.push(citypair);
					out.push(flightPlan);
				}
			}
			return out;
		},
	},
};
</script>
