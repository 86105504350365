import { Meta } from './Meta';
import { Route } from './Route';

export class FlightPlan {
	static get name() {
		return 'FlightPlan';
	}

	constructor({
		_id = undefined,
		meta = {},
		mock = {},
		references = {},
		general = {},
		aircraft = null,
		other = {},
		routingOptions = {},
		route = {},
		endurance = {},
		speeds = {},
		airports = null,
		load = {},
	} = {}) {
		this._id = _id;
		this.meta = Meta.create(meta);
		this.mock = mock;
		this.references = References.create(references);
		this.general = General.create(general);
		this.aircraft = aircraft;
		this.other = Other.create(other);
		this.routingOptions = RoutingOptions.create(routingOptions);
		this.route = Route.create(route);
		this.endurance = Endurance.create(endurance);
		this.speeds = Speeds.create(speeds);
		this.airports = airports;
		this.load = Load.create(load);
	}

	static create(data = {}) {
		return Object.seal(new FlightPlan(data));
	}
}

class References {
	constructor({ flight = null } = {}) {
		this.flight = flight;
	}

	static create(data) {
		return Object.freeze(new References(data));
	}
}

class General {
	constructor({
		flightDesignator = '',
		callsign = '',
		departure = '',
		arrival = '',
		std = '',
		sta = '',
		etd = '',
		eta = '',
		flightRule = '',
		typeOfFlight = '',
		sts = [],
		dispatchNotes = null,
		atcRemarks = null,
	} = {}) {
		this.flightDesignator = flightDesignator;
		this.callsign = callsign;
		this.departure = departure;
		this.arrival = arrival;
		this.std = std;
		this.sta = sta;
		this.etd = etd;
		this.eta = eta;
		this.flightRule = flightRule;
		this.typeOfFlight = typeOfFlight;
		this.sts = sts;
		this.dispatchNotes = dispatchNotes;
		this.atcRemarks = atcRemarks;
	}
	static create(data = {}) {
		return Object.seal(new General(data));
	}
}

class Other {
	constructor({ equipment = [], ssr = [], pbn = [] } = {}) {
		this.equipment = equipment;
		this.ssr = ssr;
		this.pbn = pbn;
	}
	static create(data = {}) {
		return Object.seal(new Other(data));
	}
}

class RoutingOptions {
	constructor({
		autoRoute = true,
		maxAltitude = '',
		via = '',
		avoid = '',
		sharpTurnAvoidance = false,
		lowerAirways = true,
		upperAirways = false,
		manualRoute = '',
	} = {}) {
		this.autoRoute = autoRoute;
		this.maxAltitude = maxAltitude;
		this.via = via;
		this.avoid = avoid;
		this.sharpTurnAvoidance = sharpTurnAvoidance;
		this.lowerAirways = lowerAirways;
		this.upperAirways = upperAirways;
		this.manualRoute = manualRoute;
	}
	static create(data = {}) {
		return Object.seal(new RoutingOptions(data));
	}
}

class Endurance {
	constructor({ time = {}, energy = {} } = {}) {
		this.time = EnduranceTime.create(time);
		this.energy = EnduranceEnergy.create(energy);
	}

	static create(data = {}) {
		return Object.seal(new Endurance(data));
	}
}

class EnduranceTime {
	constructor({ taxi = 10, trip = 0, contingency = 15, reserve = 30, additional = 15, extra = 0, total = 0 } = {}) {
		this.taxi = taxi;
		this.trip = trip;
		this.contingency = contingency;
		this.reserve = reserve;
		this.additional = additional;
		this.extra = extra;
		this.total = total;
	}

	static create(data = {}) {
		return Object.seal(new EnduranceTime(data));
	}
}

class EnduranceEnergy {
	constructor({ taxi = 0, trip = 0, contingency = 0, reserve = 0, additional = 0, extra = 0, total = 0 } = {}) {
		this.taxi = taxi;
		this.trip = trip;
		this.contingency = contingency;
		this.reserve = reserve;
		this.additional = additional;
		this.extra = extra;
		this.total = total;
	}

	static create(data = {}) {
		return Object.seal(new EnduranceEnergy(data));
	}
}

class Speeds {
	constructor({ climb = {}, cruise = {}, descent = {} } = {}) {
		this.climb = SpeedItem.create(climb);
		this.cruise = SpeedItem.create(cruise);
		this.descent = SpeedItem.create(descent);
	}

	static create(data = {}) {
		return Object.seal(new Speeds(data));
	}
}

class SpeedItem {
	constructor({ ias = null, averageTas = null } = {}) {
		this.ias = ias;
		this.averageTas = averageTas;
	}

	static create(data = {}) {
		return Object.seal(new SpeedItem(data));
	}
}

class Load {
	constructor({ pax = 0, paxMass = 0, baggage = 0, cargo = 0, payload = 0, actualMass = 0 } = {}) {
		this.pax = pax;
		this.paxMass = paxMass;
		this.baggage = baggage;
		this.cargo = cargo;
		this.payload = payload;
		this.actualMass = actualMass;
	}

	static create(data = {}) {
		return Object.seal(new Load(data));
	}
}
