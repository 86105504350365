<template>
	<v-container fluid>
		<v-row>
			<v-col cols="12" md="6" lg="4" xl="3">
				<air-item-search-list
					class="my-3"
					v-model="selectedIndex"
					title="Aircraft Type Management"
					:items="Object.values(items)"
					:disabled="operationStatus.busy || isListDisabled"
					:loading="operationStatus.loading"
					:maxHeight="500"
					:searchFields="[
						'general.typeDesignator',
						'general.manufacturerCode',
						'general.modelName',
						'engine.description',
					]"
					:uploadModelName="modelName"
					@delete="openArchiveDialog"
					@select="setActiveItem"
					@new="newItem"
					@uploaded="loadItems"
				>
					<template v-slot:[`item-title`]="{ item }">
						<span>{{ item.general.typeDesignator }}</span>
					</template>
					<template v-slot:[`item-subtitle`]="{ item }">
						<v-row>
							<v-col cols="4">
								{{ item.general.manufacturerCode }}
							</v-col>
							<v-col cols="4">
								{{ item.general.modelName }}
							</v-col>
							<v-col cols="2">
								{{ item.general.category.charAt(0) + item.engine.engineCount + item.engine.engineType.charAt(0) }}
							</v-col>
						</v-row>
					</template>
				</air-item-search-list>
			</v-col>

			<v-col cols="12" md="6" lg="8" xl="9">
				<air-aircraft-type-editor
					@cancel="cancelEdit"
					@save="saveActiveItem"
					:disabled="operationStatus.busy || isEditorDisabled"
					v-model="temporaryItem"
					ref="editor"
				/>
			</v-col>
		</v-row>
		<yes-cancel-dialog
			v-model="dialogOpen"
			title="Archive Aircraft Type"
			message="Are your sure you want to archive the Aircraft Type?"
			@yes="archiveItem"
		/>
	</v-container>
</template>

<script>
import { AircraftType } from '@/models';
import { generateMixin as generateCrudManagementMixin } from '@/mixins/crud/crudManagement';
import { generateMixin as generateAggregatedOperationStatusMixin } from '@/mixins/aggregatedOperationStatus';

export default {
	name: `AircraftTypeManagement`,
	mixins: [generateCrudManagementMixin(AircraftType), generateAggregatedOperationStatusMixin(AircraftType)],
};
</script>
