const data = [
	{
		type: 'S',
		description: 'scheduled air service',
	},
	{
		type: 'N',
		description: 'non-scheduled air service',
	},
	{
		type: 'G',
		description: 'general aviation',
	},
	{
		type: 'M',
		description: 'military',
	},
	{
		type: 'X',
		description: 'Other',
	},
];

function getTypes() {
	//return Object.keys(data).map((key) => data[key].type); bei JSON
	return data.map((element) => element.type);
}

function getDescriptions() {
	return data.map((element) => element.description);
}
function getTypesDescriptions() {
	return data.map((element) => element.type + ' ' + element.description);
}
export default {
	getTypes,
	getDescriptions,
	getTypesDescriptions,
};
