<template>
	<v-container>
		<v-card max-width="800">
			<v-card-title>Flight Plan</v-card-title>
			<v-card-text>
				<air-flight-plan-general class="mb-4" :flightPlan="flightPlan"></air-flight-plan-general>
				<air-flight-plan-load class="mb-4" :flightPlan="flightPlan" />
				<air-flight-plan-energy-time class="mb-4" :flightPlan="flightPlan" />
				<air-flight-plan-route class="mb-4" :flightPlan="flightPlan" />
				<air-flight-plan-airports class="mb-4" v-model="flightPlan.airports" />
				<air-flight-plan-waypoints class="mb-4" v-model="flightPlan.route.waypoints" :airports="flightPlan.airports" />
			</v-card-text>
		</v-card>
	</v-container>
</template>

<script>
export default {
	name: 'AirFlightPlan',
	props: {
		flightPlan: Object,
	},
	data: () => ({}),
	created() {},
	methods: {},
};
</script>
