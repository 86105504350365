<template>
	<v-dialog v-model="show" v-bind="$attrs">
		<v-card>
			<v-card-title class="headline">
				<span>{{ title }}</span>
				<v-spacer />
				<v-icon color="red" @click="emitCancel()">mdi-close-thick</v-icon>
			</v-card-title>
			<v-card-text>
				<slot></slot>
			</v-card-text>
			<v-card-actions>
				<v-btn v-if="yes" @click="emitYes()">Yes</v-btn>
				<v-btn v-if="no" @click="emitNo()">No</v-btn>
				<v-btn v-if="cancel" @click="emitCancel()">Cancel</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: 'AirDialog',
	props: {
		value: Boolean,
		title: {
			type: String,
			default: 'Sorry, something went wrong!',
		},
		yes: Boolean,
		no: Boolean,
		cancel: Boolean,
	},
	data: () => ({}),
	computed: {
		show: {
			get() {
				return this.value;
			},
			set(value) {
				// this.value = value; - funktioniert nicht, da value ein prop ist.
				// stattdessen wird der neue wert für das prop "value" durch das "input"
				// event and den parent geschickt, welcher wiederum das prop updated - dies nennt man 2-way binding
				this.$emit('input', value);
			},
		},
	},
	methods: {
		emitYes() {
			this.$emit('yes');
			this.show = false;
		},
		emitNo() {
			this.$emit('no');
			this.show = false;
		},
		emitCancel() {
			this.$emit('cancel');
			this.show = false;
		},
	},
};
</script>
