const data = [
	{
		type: 'J',
		description: 'Jet',
	},
	{
		type: 'P',
		description: 'Piston',
	},
	{
		type: 'T',
		description: 'Turbprop/Turboshaft',
	},
	{
		type: 'E',
		description: 'Electric',
	},
	{
		type: 'R',
		description: 'Rocket',
	},
];

function getTypes() {
	return data.map((element) => element.type);
}

function getDescriptions() {
	return data.map((element) => element.description);
}
function getTypesDescriptions() {
	return data.map((element) => element.type + ' - ' + element.description);
}
export default {
	getTypes,
	getDescriptions,
	getTypesDescriptions,
};
