import { sync } from 'vuex-pathify';
import { debounce } from 'lodash';

const customDebounce = debounce(
	function (context, value) {
		// if there is a unselectMapObjectHandle available in the store, execute it.
		if (context.unselectMapObjectHandle) context.unselectMapObjectHandle();
		context.isThisMapObjectSelected = context;
		context.selectedMapObject = value;
		context.unselectMapObjectHandle = context.unselect;
	},
	100,
	{
		leading: true,
		trailing: false,
	}
);

export const mixin = {
	data: () => ({
		isThisMapObjectSelected: false,
	}),
	computed: {
		...sync(`flightWatch/interactions`, ['selectedMapObject', 'unselectMapObjectHandle']),
	},
	methods: {
		unselect() {
			this.isThisMapObjectSelected = false;
			this.selectedMapObject = null;
		},
		selectMapObject(value) {
			customDebounce(this, value);
		},
	},
};
