import { FlightPlan } from '@/models';
import api from '@/services/backend';
import { generateModule } from '@/store/helpers/crud';
import { SAVE } from '@/store/actionTypes';
import { SET_SAVING, SAVE_ITEMS } from '@/store/mutationTypes';

const actions = {
	[SAVE]: async ({ commit }, payload) => {
		return new Promise((resolve, reject) => {
			commit(`operationStatus/${SET_SAVING}`, true);
			api.flightPlanning
				.save(payload)
				.then((res) => {
					console.log('flight planning engine returned after calculation:', res.data);
					commit(SAVE_ITEMS, res.data);
					resolve(res);
				})
				.catch((error) => {
					// commit(`operationStatus/${SET_ERROR}`, error);
					console.log(error);
					reject(error);
				})
				.finally(() => {
					commit(`operationStatus/${SET_SAVING}`, false);
				});
		});
	},
};
export const store = generateModule(FlightPlan, {}, {}, {}, {}, actions);
