import options from './options';
import lodash from 'lodash';

const emptyFlightPlan = {
	_id: null,
	general: {
		flightDesignator: null,
		callsign: null,
		dep: null,
		arr: null,
		std: null,
		sta: null,
		etd: null,
		eta: null,
		flightRule: 'Z',
		typeOfFlight: 'G',
		sts: [],
		dispatchNotes: null,
		atcRemarks: null,
	},
	flight: null,
	aircraft: null,
	other: {
		equipment: [],
		ssr: [],
		pbn: [],
	},
	routingOptions: {
		autoRoute: true,
		maxAltitude: null,
		via: null,
		avoid: null,
		sharpTurnAvoidance: false,
		lowerAirways: true,
		upperAirways: false,
	},
	route: {
		routeString: null,
	},
	time: {
		taxi: 8,
		trip: null,
		contingency: 15,
		reserve: 45,
		additional: 0,
		extra: 0,
		total: null,
	},
	speeds: {
		climb: {
			ias: 160,
			averageTas: null,
		},
		cruise: {
			ias: 230,
			averageTas: null,
		},
		descent: {
			ias: 160,
			averageTas: null,
		},
	},
	energy: {
		taxi: null,
		trip: null,
		contingency: null,
		reserve: null,
		additional: null,
		extra: null,
		total: null,
	},
	airports: null,
	load: {
		pax: 2,
		paxMass: 75,
		baggage: 30,
		cargo: 10,
		payload: null,
		actualMass: null,
	},
};

function createEmptyFlightPlan() {
	return lodash.cloneDeep(emptyFlightPlan);
}

function createFlightPlan(flight) {
	var flightPlan = lodash.cloneDeep(emptyFlightPlan);
	flightPlan.flight = flight._id;
	flightPlan.general.flightDesignator = flight.flightDesignator;
	flightPlan.general.callsign = flight.callsign;
	flightPlan.general.dep = flight.dep;
	flightPlan.general.std = flight.std;
	flightPlan.general.arr = flight.arr;
	flightPlan.general.sta = flight.sta;
	return flightPlan;
}

export default {
	options,
	createEmptyFlightPlan,
	createFlightPlan,
};
