<template>
	<v-card class="mx-auto">
		<v-card-title class="subheading font-weight-bold">
			<span>Aircraft</span>
			<v-spacer></v-spacer>
			<span class="golden--text">{{ value.key }}</span>
		</v-card-title>
		<v-card-subtitle>
			<v-divider></v-divider>
		</v-card-subtitle>
		<v-card-text>
			<v-list>
				<v-list-item
					v-for="entries of Object.entries(items[value.key]).filter(
						(item) => item[1] !== null && item[0] !== 'references'
					)"
					:key="entries[0]"
				>
					<template v-if="entries[0] !== 'references'">
						<template v-if="entries[0] === 'position'">
							<v-list-item-content>
								<v-list-item-title class="font-weight-bold">{{ $_.startCase(entries[0]) }}:</v-list-item-title>
								<div v-for="(subVal, subKey) of entries[1]" :key="subKey">
									<ul>
										<li>
											<div class="d-flex">
												<div>{{ subKey }}</div>
												<div class="ml-auto">
													{{ formatPosition(subKey, subVal) }}
												</div>
											</div>
										</li>
									</ul>
								</div>
							</v-list-item-content>
						</template>
						<template v-else-if="entries[0] === 'battery'">
							<v-list-item-content>
								<v-list-item-title class="font-weight-bold">{{ $_.startCase(entries[0]) }}:</v-list-item-title>
								<div v-for="(subVal, subKey) of entries[1]" :key="subKey">
									<ul>
										<li>
											<div class="d-flex">
												<div>{{ subKey }}</div>
												<div class="ml-auto">
													{{ formatBattery(subKey, subVal) }}
												</div>
											</div>
										</li>
									</ul>
								</div>
							</v-list-item-content>
						</template>
						<template v-else>
							<v-list-item-content>
								<v-list-item-title class="font-weight-bold text-wrap"
									>{{ $_.startCase(entries[0]) }}:</v-list-item-title
								>
							</v-list-item-content>
							<v-list-item-content v-if="entries[0] === 'velocity'" class="justify-end">
								{{ formatVelocity(entries[1]) }}
							</v-list-item-content>
							<v-list-item-content v-if="entries[0] === 'track'" class="justify-end">
								{{ formatTrack(entries[1]) }}
							</v-list-item-content>
							<v-list-item-content v-else class="justify-end"> {{ entries[1] }} </v-list-item-content>
						</template>
					</template>
				</v-list-item>
			</v-list>
		</v-card-text>
	</v-card>
</template>
<script>
import { get } from 'vuex-pathify';
export default {
	name: 'fwAircraftStateDetails',
	data: () => ({
		tempAircraftState: {
			references: {
				aircraft: '61a9069873b32d4072bb4a3f',
				flightPlan: '61ae3d6dc48e03f2dcdb0058',
				flight: '61ae3d6cc48e03f2dcdb0045',
			},
			lastPositionUpdate: '2021-12-06T21:45:26.780Z',
			lastUpdate: '2021-12-06T21:45:26.780Z',
			registration: 'OE-AFFE',
			icao24: '31345456',
			flightDesignator: 'FY503',
			callsign: 'FLY503',
			position: {
				latitude: 50.21455710725278,
				longitude: 10.903360720048186,
			},
			isOnGround: false,
			velocity: 242,
			track: 303.9991575449084,
			battery: {
				temperature: 50.46368255363869,
				remainingCapacity: 0.450416787037037,
			},
			squawk: 7000,
		},
	}),

	props: {
		value: Object,
	},
	computed: {
		...get(`flightWatch/aircraftStates`, ['items', 'lastUpdated']),
	},
	methods: {
		formatPosition(type, value) {
			let out;
			if (type === 'latitude') {
				out = value >= 0 ? 'N' : 'S';
			} else if (type === 'longitude') {
				out = value >= 0 ? 'E' : 'W';
			}
			out += Math.abs(value).toFixed(4) + '°';
			return out;
		},
		formatBattery(type, value) {
			let out;
			if (type === 'temperature') {
				out = value.toFixed(1) + ' °C';
			} else if (type === 'remainingCapacity') {
				out = (value * 100).toFixed(2) + ' %';
			}
			return out;
		},
		formatVelocity(value) {
			return value.toFixed(0) + ' kt';
		},
		formatTrack(value) {
			return value.toFixed(0) + '°';
		},
	},
};
</script>
