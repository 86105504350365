export class Meta {
	static get name() {
		return 'Meta';
	}

	constructor({
		archived = false,
		documentVersion = null,
		historicDocuments = [],
		createdAt = new Date(),
		updatedAt = new Date(),
	} = {}) {
		this.archived = archived;
		this.documentVersion = documentVersion;
		this.historicDocuments = historicDocuments;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
	}

	static create(data = {}) {
		return Object.seal(new Meta(data));
	}
}
