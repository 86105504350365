<template>
	<air-sideboard width="30%" :expandOnHover.sync="sideboardExpand" permanent app clipped right dark>
		<keep-alive>
			<component :is="selectedMapObjectComponent" v-model="selectedMapObject"></component>
		</keep-alive>
	</air-sideboard>
</template>
<script>
import AirSideboard from '@/componentsViews/layout/views/AirSideboard.vue';
import { Waypoint, FlightPlan } from '@/models';
import { get } from 'vuex-pathify';
export default {
	components: { AirSideboard },
	name: 'FlightWatchSideboard',
	data() {
		return {
			sideboardExpand: false,
		};
	},
	computed: {
		...get('flightWatch/interactions', ['selectedMapObject']),
		selectedMapObjectComponent() {
			if (this.selectedMapObject) {
				if (typeof this.selectedMapObject === 'object') {
					if (this.selectedMapObject instanceof Waypoint) {
						if (this.selectedMapObject.type === 'APT') return 'fw-vertiport-details';
						else return 'fw-waypoint-details';
					} else if (this.selectedMapObject instanceof FlightPlan) return 'fw-flight-details';
					else if (this.selectedMapObject.type && this.selectedMapObject.type === 'AircraftState')
						return 'fw-aircraft-state-details';
				}
				/*else if (typeof this.selectedMapObject === 'string') {
				if (this.selectedMapObject === 'admin') return 'fw-map-admin';
			}*/
			}
			return 'fw-map-admin';
		},
	},
};
</script>
<style lang="scss" scoped>
.localContainer {
	width: 100%;
	background-color: red;
}
</style>
