<template>
	<div>
		<v-skeleton-loader :loading="loading" transition="scale-transition" type="table">
			<air-flight-plan v-if="flightPlan" :flightPlan="flightPlan" />
			<div v-else>{{ error }}</div>
		</v-skeleton-loader>
	</div>
</template>
<script>
import { FlightPlan } from '@/models';
export default {
	props: {},
	data: () => ({
		flightPlan: null,
		loading: true,
		error: false,
	}),
	created() {
		this.flightPlan = this.$services.backend
			.loadById(FlightPlan.name, this.$route.query.id)
			.then((result) => {
				this.flightPlan = FlightPlan.create(result.data);
			})
			.catch((err) => {
				console.log(err);
				this.error = err.response && err.response.data ? err.response.data : err;
			})
			.finally(() => {
				this.loading = false;
			});
	},
};
</script>
