<template>
	<v-menu bottom :offset-y="true" :close-on-content-click="false">
		<template v-slot:activator="{ on, attrs }">
			<v-btn v-bind="attrs" v-on="on">
				<v-icon>mdi-account</v-icon>
			</v-btn>
		</template>

		<air-login :width="width" />
	</v-menu>
</template>
<script>
export default {
	props: {
		width: Number,
	},
	data: () => ({}),
};
</script>
