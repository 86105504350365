const data = [
	{
		category: 'L',
		description: 'LandPlane',
	},
	{
		category: 'A',
		description: 'Amphibian',
	},
	{
		category: 'H',
		description: 'Helicopter',
	},
	{
		category: 'G',
		description: 'Gyrocopter',
	},
	{
		category: 'T',
		description: 'Tiltwing',
	},
	{
		category: 'S',
		description: 'SeaPlane',
	},
	{
		category: 'Other',
		description: 'Special Designator',
	},
];

function getCategories() {
	return data.map((element) => element.category);
}

function getDescriptions() {
	return data.map((element) => element.description);
}
function getCategoriesDescriptions() {
	return data.map((element) => element.category + ' - ' + element.description);
}
export default {
	getCategories,
	getDescriptions,
	getCategoriesDescriptions,
};
