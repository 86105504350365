var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"golden--text"},[_vm._v("Flight Plan Preview")]),(!_vm.flightPlan.aircraft)?_c('v-card-text',[_vm._v("No aircraft selected.")]):_c('v-card-text',{staticClass:"pa-4"},[_c('v-card',{staticClass:"mb-4"},[_c('v-card-title',[_vm._v("Load")]),_c('v-card-text',[_c('v-data-table',{attrs:{"items":_vm.load,"headers":_vm.loadHeaders,"dense":"","disable-sort":"","disable-filtering":"","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{class:item.style},[_c('td',[_vm._v(_vm._s(item.description))]),_c('td',{staticClass:"pr-1 text-right"},[_vm._v(_vm._s(item.value))]),_c('td',{staticClass:"pl-0"},[_vm._v(_vm._s(item.unit))])])]}}],null,true)})],1)],1),_c('v-card',[_c('v-card-title',[_vm._v("Energy/Time")]),_c('v-card-text',{staticClass:"d-inline-block"},[_c('v-data-table',{attrs:{"items":_vm.energyTime,"headers":_vm.energyTimeHeaders,"dense":"","disable-sort":"","disable-filtering":"","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.phase",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.style ? item.style : ''},[_vm._v(_vm._s(item.phase[0].toUpperCase() + item.phase.slice(1)))])]}},{key:"item.time",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.style ? item.style : ''},[_vm._v(_vm._s(_vm.$services.utils.dateFormatter.minutesToTimeString(item.time)))])]}},{key:"item.energy",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.style ? item.style : ''},[_vm._v(_vm._s(item.energy)+" kWh")])]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }