export class Position2D {
	static get name() {
		return 'Position2D';
	}

	constructor({ latitude = null, longitude = null, lat = null, lon = null } = {}) {
		this.latitude = latitude ? latitude : lat;
		this.longitude = longitude ? longitude : lon;
	}

	get lat() {
		return this.latitude;
	}
	set lat(value) {
		this.latitude = value;
	}

	get lon() {
		return this.longitude;
	}
	set lon(value) {
		this.longitude = value;
	}

	static create(data) {
		return Object.seal(new Position2D(data));
	}
}
