var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
		(_vm.waypointsVisible &&
			(_vm.waypoint.type === 'FIX' ||
				_vm.waypoint.type === 'LATLON' ||
				_vm.waypoint.type === 'VOR' ||
				_vm.waypoint.type === 'DME')) ||
		(_vm.vertiportsVisible && _vm.waypoint.type === 'APT') ||
		(_vm.pseudoWaypointsVisible && _vm.waypoint.type === 'PSY')
	)?_c('div',[(_vm.waypoint.type === 'APT')?_c('fw-area-of-operation',{attrs:{"position":_vm.waypoint.position}}):_vm._e(),_c('l-marker',{attrs:{"lat-lng":_vm.waypoint.position},on:{"click":function($event){return _vm.selectMapObject(_vm.waypoint)}}},[_c('l-icon',{attrs:{"iconAnchor":_vm.iconAnchor}},[(
					_vm.waypoint.type === 'FIX' || _vm.waypoint.type === 'LATLON' || _vm.waypoint.type === 'VOR' || _vm.waypoint.type === 'DME'
				)?_c('fw-svg-fix',{attrs:{"backgroundColor":_vm.waypointBackgroundColor,"color":_vm.isThisMapObjectSelected ? _vm.selectedColor : _vm.waypointColor,"reporting":_vm.waypoint.reporting,"overfly":_vm.waypoint.overfly,"opacity":_vm.waypointOpacity}}):(_vm.waypoint.type === 'PSY')?_c('fw-svg-top-of',{attrs:{"color":_vm.isThisMapObjectSelected ? _vm.selectedColor : _vm.waypointColor,"subtype":_vm.waypoint.ident}}):(_vm.waypoint.type === 'APT')?_c('fw-svg-vertiport',{attrs:{"backgroundColor":_vm.vertiportBackgroundColor,"color":_vm.isThisMapObjectSelected ? _vm.selectedColor : _vm.vertiportColor}}):_vm._e()],1),_c('l-popup',{ref:"popup",staticClass:"text-center"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.waypoint.ident))]),_c('div',[_vm._v(_vm._s(_vm.waypoint.name))])])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }