<template>
	<g class="parking">
		<title>parking</title>
		<line
			x1="67.6"
			x2="408.2"
			y1="537.05"
			y2="537.05"
			fill="none"
			stroke="#646567"
			stroke-miterlimit="10"
			stroke-width="1.5"
		/>
		<g v-for="(parking, index) of parkings" :key="index" class="p1">
			<title v-if="parking">{{ parking.registration }}</title>
			<text
				:x="57.300472 + index * 70"
				y="576.95099"
				fill="#737373"
				font-family="Roboto-Medium, Roboto"
				font-size="12px"
				font-weight="500"
				style="isolation: isolate"
			>
				P{{ index + 1 }}
			</text>
			<circle
				:cx="64.6 + index * 70"
				cy="537.05"
				r="22.1"
				fill="#434345"
				stroke="#646567"
				stroke-miterlimit="10"
				stroke-width="1.42"
			/>
			<circle
				v-if="parking"
				:cx="64.6 + index * 70"
				cy="537.05"
				r="22.1"
				fill="#434345"
				stroke="#bd8bb9"
				stroke-miterlimit="10"
				stroke-width="2.2"
			/>
			<text
				:x="55 + index * 70"
				y="541.151"
				fill="#bd8bb9"
				font-family="Roboto-Medium, Roboto"
				font-size="12px"
				font-weight="500"
				style="isolation: isolate"
			>
				{{ parking ? parking.registration.split('-')[1] : '' }}
			</text>
			<text
				v-if="parking"
				:x="57.300472 + index * 70"
				y="576.95099"
				fill="#b0b0b0"
				font-family="Roboto-Medium, Roboto"
				font-size="12px"
				font-weight="500"
				style="isolation: isolate"
			>
				P{{ index + 1 }}
			</text>
			<g v-if="parking && parking.alert" class="p1alert">
				<title>alert1</title>
				<path
					:d="`m${
						92.8 + index * 70
					} 524.55-9.6-16.7a1.3 1.3 0 0 0-1.1-0.6 1.1 1.1 0 0 0-1.1 0.6l-9.6 16.7a1.1 1.1 0 0 0 0 1.3 1.1 1.1 0 0 0 1.1 0.6h19.2a1.1 1.1 0 0 0 1.1-0.6 1.5 1.5 0 0 0 0-1.3z`"
					fill="#e53e16"
				/>
				<path
					:d="`m${
						81.8 + index * 70
					} 512.25h0.6a0.9 0.9 0 0 1 0.8 0.8v6.5a0.9 0.9 0 0 1-0.8 0.8h-0.6a0.9 0.9 0 0 1-0.8-0.8v-6.5a0.9 0.9 0 0 1 0.8-0.8z`"
					fill="#434345"
				/>
				<path
					:d="`m${82.1 + index * 70} 521.65a1.2 1.2 0 0 1 0 2.4 1.3 1.3 0 0 1-1.2-1.2 1.2 1.2 0 0 1 1.2-1.2z`"
					fill="#434345"
				/>
			</g>
		</g>
	</g>
</template>
<script>
export default {
	name: 'parkings',
	props: {
		parkings: Array,
	},
};
</script>
