<template>
	<v-dialog v-model="show">
		<v-card color="primary">
			<v-card-title class="error">{{ title }}</v-card-title>
			<v-card-text class="pt-6">{{ message }}</v-card-text>
			<v-divider></v-divider>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="light" text @click="show = false"> OK </v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props: {
		value: Boolean,
		title: {
			type: String,
			default: 'Sorry, something went wrong!',
		},
		message: {
			type: String,
			default: 'There is no error, you should not see this message.',
		},
	},
	data: () => ({}),
	computed: {
		show: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
};
</script>
<style scoped lang="scss">
::v-deep .dialog-top {
	align-self: flex-start;
}
</style>
