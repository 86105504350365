<template>
	<div>
		<l-map
			class="flightWatchMap"
			:center.sync="center"
			:minZoom="availableTiles[selectedTileKey].minZoom"
			:maxZoom="availableTiles[selectedTileKey].maxZoom"
			:zoom.sync="zoom"
			:bounds.sync="bounds"
			:maxBounds="maxBounds"
			ref="map"
			@ready="onMapReady"
			@click="selectMapObject()"
			:options="{ zoomControl: false }"
		>
			<fw-aircraft-layer :visible="true" />
			<fw-layer-control position="topright"></fw-layer-control>
			<fw-zoom-control v-model="zoom" position="topleft"></fw-zoom-control>
			<fw-region-control
				:centerControl="center"
				:zoomControl="zoom"
				@region="setMapCenterAndZoom"
				@vertiport="setVertiportCenterAndZoom"
			></fw-region-control>
			<!-- vertiport-control bottomright -->
			<!-- flight-list bottomleft -->
			<fw-flights-layer></fw-flights-layer>
		</l-map>
	</div>
</template>
<script>
import fwRegionControl from './controls/fwRegionControl.vue';
import FwAircraftLayer from './aircraftState/fwAircraftLayer.vue';

import { Flight, FlightPlan } from '@/models';
import { mixin as selectableMapObject } from '@/mixins/selectableMapObject';
import { generateMixin as loadCrudModels } from '@/mixins/crud/loadModels';
import { get, sync, call } from 'vuex-pathify';
import { LOAD } from '@/store/actionTypes';
import { debounce } from 'lodash';

export default {
	name: `fwWatchMap`,
	components: { fwRegionControl, FwAircraftLayer },
	mixins: [loadCrudModels(Flight, FlightPlan), selectableMapObject],
	props: {},
	data: () => ({
		maxBounds: [
			[-90, -230],
			[90, 270],
		],
		zoom: 4,
		center: { lat: 50, lng: 15 }, //ToDo: Get this from user settings or last state of user
		isMapReady: false,
		bounds: null,
		aircraftStatesUpdateIntervalId: null,
		vertiportStatesUpdateIntervalId: null,
	}),
	computed: {
		...get(`flightWatch/layers`, ['availableTiles']),
		...get(`flightWatch/settings/layers`, ['selectedTileKey']),
		...get(`flightWatch/regions`, ['selectedRegion']),
		...get(`flightWatch/regions`, ['availableRegions']),
		...sync(`flightWatch/regions`, ['selectedRegion']),
	},
	watch: {
		zoom() {
			if (this.selectedRegion !== '' || this.availableRegions[this.selectedRegion] !== undefined) {
				try {
					setTimeout(() => {
						if (this.availableRegions[this.selectedRegion] !== undefined) {
							if (this.zoom !== this.availableRegions[this.selectedRegion].zoom) {
								this.selectedRegion = '';
							}
						}
					}, 500);
				} catch (err) {
					console.log('Zoom-Error', err);
				}
			}
		},
		center() {
			setTimeout(() => {
				if (this.selectedRegion !== '' || this.availableRegions[this.selectedRegion] !== undefined) {
					let tempLatitude = this.center.lat.toFixed();
					let tempLongitude = this.center.lng.toFixed();
					console.log('position fixed', tempLatitude, tempLongitude);
					if (
						!(
							this.inRange(
								tempLatitude,
								this.availableRegions[this.selectedRegion].position.latitude,
								this.availableRegions[this.selectedRegion].position.latitude
							) &&
							this.inRange(
								tempLongitude,
								this.availableRegions[this.selectedRegion].position.longitude,
								this.availableRegions[this.selectedRegion].position.longitude
							)
						)
					) {
						console.log('center');
						this.selectedRegion = '';
					}
				}
			}, 500);
		},
		bounds() {
			this.updateAircraftStates();
		},
	},

	methods: {
		setVertiportCenterAndZoom(region) {
			const { center, zoom } = region;

			setTimeout(() => {
				this.zoom = zoom;
			}, 500); //300 min on different zoom
			this.center = {
				lat: center.latitude,
				lng: center.longitude,
			};
		},
		setMapCenterAndZoom(region) {
			const { center, zoom } = region;
			this.center = {
				lat: center.latitude,
				lng: center.longitude,
			};

			setTimeout(() => {
				if (this.availableRegions[this.selectedRegion] !== undefined) {
					if (this.zoom !== this.availableRegions[this.selectedRegion].zoom) {
						this.zoom = zoom;
					}
				}
			}, 500); //300 min on different zoom
		},
		...call(`flightWatch/aircraftStates`, { loadAircraftStates: LOAD }),
		loadVertiportStates: call(`flightWatch/vertiportStates/${LOAD}`),
		onMapReady() {
			var map = this.$refs.map.mapObject;
			map.on('popupclose', () => this.unselectMapObjectHandle());
			this.isMapReady = true;
			this.updateAircraftStates();
			const self = this;
			this.aircraftStatesUpdateIntervalId = setInterval(function () {
				self.updateAircraftStates();
			}, 2 * 1000);

			this.loadVertiportStates();
			this.vertiportStatesUpdateIntervalId = setInterval(() => {
				this.loadVertiportStates();
			}, 10 * 1000);
			console.log('Map component ready.');
		},
		inRange(x, min, max) {
			return (x - min) * (x - max) <= 0;
		},
		updateAircraftStates: debounce(
			function () {
				if (this.isMapReady) {
					const bounds = this.$refs.map.mapObject.getBounds();
					this.loadAircraftStates(bounds);
				}
			},
			200,
			{
				leading: true,
				trailing: false,
			}
		),
	},
	beforeDestroy() {
		if (this.aircraftStatesUpdateIntervalId) clearInterval(this.aircraftStatesUpdateIntervalId);
		if (this.vertiportStatesUpdateIntervalId) clearInterval(this.updateIntervalId);
	},
};
</script>

<style scoped>
.flightWatchMap {
	z-index: 1;
}
</style>
