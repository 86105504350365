var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-10",attrs:{"loading":_vm.operationStatus.loading,"headers":_vm.headers,"items":Object.values(_vm.flightStore),"items-per-page":_vm.itemsPerPage,"sort-desc":true,"multi-sort":"","item-key":"_id","show-expand":"","expanded":_vm.expandedFlights,"sort-by":"std"},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:expanded":function($event){_vm.expandedFlights=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td'),_c('td',{attrs:{"colspan":headers.length - 1}},[(item.references.flightPlans.length)?_c('fp-dispatch-quickview',{attrs:{"flightPlans":item.references.flightPlans.map(function (id) { return _vm.flightPlanStore[id]; })}}):_vm._e()],1)]}},_vm._l((['std', 'sta', 'etd', 'eta']),function(slotName,index){return {key:'item.' + slotName,fn:function(ref){
var item = ref.item;
return [(item[slotName])?_c('span',{key:index},[_vm._v(_vm._s(_vm.$services.utils.dateFormatter.formatDateTimeUtc(item[slotName])))]):_vm._e()]}}}),{key:"item.aircraft",fn:function(ref){
var item = ref.item;
return [(
					item.references.activeFlightPlan && _vm.flightPlanStore && _vm.flightPlanStore[item.references.activeFlightPlan]
				)?_c('span',{staticClass:"green--text"},[_vm._v(_vm._s(_vm.flightPlanStore[item.references.activeFlightPlan].aircraft.general.registration))]):(item.plannedAircraftRegistration)?_c('span',[_vm._v(_vm._s(item.plannedAircraftRegistration))]):_vm._e()]}},_vm._l((['item.departure', 'item.arrival']),function(slotName,index){return {key:slotName,fn:function(ref){
				var item = ref.item;
return [_c('v-btn',{key:index,attrs:{"outlined":"","small":""},on:{"click":function($event){_vm.showAirportInfo(item[slotName.split('.')[1]])}}},[_vm._v(_vm._s(item[slotName.split('.')[1]]))])]}}}),{key:"item.calculated",fn:function(ref){
				var item = ref.item;
return [(item.references.activeFlightPlan)?_c('v-btn',{staticClass:"noBorder",attrs:{"outlined":"","to":{ path: '/ofp/view', query: { id: item.references.activeFlightPlan } },"small":""}},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-bold")])],1):_vm._e()]}},{key:"item.ofp",fn:function(ref){
				var item = ref.item;
return [_c('v-btn',{staticClass:"noBorder",attrs:{"outlined":"","to":{
					path: '/ofp/file',
					query: {
						flightId: item._id,
					},
				}}},[_c('v-icon',{attrs:{"color":"listbutton"}},[_vm._v("mdi-clipboard-edit-outline")])],1)]}}],null,true)}),_c('air-dialog',{attrs:{"width":"unset","title":'Airport Information: ' +
			(_vm.currentAirport ? _vm.currentAirport.ICAO : '') +
			' / ' +
			(_vm.currentAirport ? _vm.currentAirport.IATA : '')},model:{value:(_vm.showAirportInfoDialog),callback:function ($$v) {_vm.showAirportInfoDialog=$$v},expression:"showAirportInfoDialog"}},[(_vm.currentAirport)?_c('air-metar-taf',{attrs:{"weather":_vm.currentAirport.weather}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }