<template>
	<v-app-bar app clipped-left clipped-right>
		<v-app-bar-nav-icon dark v-if="permanent" @click.stop="miniProp = !miniProp">
			<v-icon>{{ miniProp ? 'mdi-chevron-right' : 'mdi-chevron-left' }}</v-icon>
		</v-app-bar-nav-icon>
		<v-app-bar-nav-icon dark v-if="!permanent" @click.stop="drawerExtendedProp = !drawerExtendedProp">
			<v-icon>mdi-menu</v-icon>
		</v-app-bar-nav-icon>
		<v-app-bar-title>
			<router-link to="/"
				><v-img alt="aeronymos logo" class="mt-2" contain :src="logoUrl" transition="scale-transition" width="200" />
			</router-link>
		</v-app-bar-title>
		<v-spacer></v-spacer>
		<v-btn @click="toggleTheme" class="mr-2">
			<span>{{ themeButtonText }}</span>
		</v-btn>
		<air-login-menu :width="250" />
	</v-app-bar>
</template>
<script>
export default {
	name: 'AirAppBar',
	props: {
		permanent: Boolean,
		mini: Boolean,
		drawerExtended: Boolean,
	},
	computed: {
		miniProp: {
			get() {
				return this.mini;
			},
			set(value) {
				this.$emit('update:mini', value);
			},
		},
		drawerExtendedProp: {
			get() {
				return this.drawerExtended;
			},
			set(value) {
				this.$emit('update:drawerExtended', value);
			},
		},
		themeButtonText() {
			return !this.$vuetify.theme.dark ? 'Dark Mode' : 'Light Mode';
		},
		logoUrl() {
			return require('@/assets/logo_dark.svg');
			// if (this.$vuetify.theme.dark) {
			// 	return require('../assets/logo_dark.svg');
			// } else {
			// 	return require('../assets/logo_light.svg');
			// }
		},
	},
	methods: {
		toggleTheme() {
			this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
		},
	},
};
</script>
