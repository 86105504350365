<template>
	<v-card>
		<v-card-title>Energy/Time</v-card-title>
		<v-card-text class="d-inline-block">
			<v-data-table
				:items="energyTime"
				:headers="energyTimeHeaders"
				dense
				disable-sort
				disable-filtering
				disable-pagination
				hide-default-footer
			>
				<template v-slot:[`item.phase`]="{ item }">
					<span :class="item.style ? item.style : ''">{{ item.phase[0].toUpperCase() + item.phase.slice(1) }}</span>
				</template>
				<template v-slot:[`item.time`]="{ item }">
					<span :class="item.style ? item.style : ''">{{
						$services.utils.dateFormatter.minutesToTimeString(item.time)
					}}</span>
				</template>
				<template v-slot:[`item.energy`]="{ item }">
					<span :class="item.style ? item.style : ''">{{ item.energy }} kWh</span>
				</template>
			</v-data-table>
		</v-card-text>
	</v-card>
</template>
<script>
import { FlightPlan } from '@/models';
export default {
	name: 'AirFlightPlanEnergyTime',
	props: {
		flightPlan: FlightPlan,
	},
	data: () => ({
		energyTimeHeaders: [
			{ value: 'phase', text: 'Phase', align: 'start' },
			{ value: 'time', text: 'Time', align: 'end' },
			{ value: 'energy', text: 'Energy', align: 'end' },
		],
	}),
	computed: {
		energyTime() {
			var items = [];
			const keys = Object.keys(this.flightPlan.endurance.energy);
			if (this.$_.isEqual(keys, Object.keys(this.flightPlan.endurance.time))) {
				for (const key of keys) {
					var newItem = {
						phase: key,
						time: this.flightPlan.endurance.time[key],
						energy: this.flightPlan.endurance.energy[key],
					};
					if (['trip', 'total'].includes(key)) {
						newItem.style = 'font-weight-bold';
					}
					items.push(newItem);
				}
			}
			return items;
		},
	},
};
</script>
