<template>
	<g class="pads">
		<title>Pads</title>
		<g class="pad1">
			<g class="v1Active" v-if="pad1active">
				<title>v1Active</title>
				<circle
					cx="166.1"
					cy="170.25"
					r="51"
					fill="#434345"
					stroke="#ffaa00"
					stroke-miterlimit="10"
					stroke-width="2.2"
				/>
				<text
					x="159.00047"
					y="143.451"
					fill="#b0b0b0"
					font-family="Roboto-Medium, Roboto"
					font-size="12px"
					font-weight="500"
					style="isolation: isolate"
				>
					V1
				</text>
				<g transform="translate(4.7337e-4,-15.549)" fill="#ffaa00" font-family="Roboto-Regular, Roboto">
					<g font-size="19px">
						<text transform="translate(139.6,214.8)" style="isolation: isolate">18:50</text>
					</g>
					<g font-size="13px">
						<text transform="translate(157.2,193.4)" style="isolation: isolate">IBC</text>
						<text transform="translate(139.6,176.1)" style="isolation: isolate">ARR/EAST</text>
					</g>
				</g>
			</g>
			<g class="v1Inactive" v-else>
				<title>v1Inactive</title>
				<circle
					cx="166.1"
					cy="170.25"
					r="51"
					fill="#434345"
					stroke="#646567"
					stroke-miterlimit="10"
					stroke-width="2.2"
				/>
				<text
					x="157.60046"
					y="174.851"
					fill="#b0b0b0"
					font-family="Roboto-Medium, Roboto"
					font-size="12px"
					font-weight="500"
					style="isolation: isolate"
				>
					V1
				</text>
			</g>
		</g>
		<g class="pad2">
			<g class="v2Active" v-if="pad2active">
				<title>v2Active</title>
				<circle
					cx="166.1"
					cy="303.95"
					r="51"
					fill="#434345"
					stroke="#83b6de"
					stroke-miterlimit="10"
					stroke-width="2.2"
				/>
				<text
					x="159.00047"
					y="276.75101"
					fill="#b0b0b0"
					font-family="Roboto-Medium, Roboto"
					font-size="12px"
					font-weight="500"
					style="isolation: isolate"
				>
					V2
				</text>
				<g transform="translate(4.7337e-4,-15.549)" fill="#83b6de" font-family="Roboto-Regular, Roboto">
					<g font-size="19px">
						<text transform="translate(139.6,348.5)" style="isolation: isolate">19:07</text>
					</g>
					<g font-size="13px">
						<text transform="translate(157.3,327.2)" style="isolation: isolate">IBB</text>
						<text transform="translate(136.4,309.9)" style="isolation: isolate">DEP/WEST</text>
					</g>
				</g>
			</g>
			<g class="v2Inactive" v-else>
				<title>v2Inactive</title>
				<circle
					cx="166.1"
					cy="303.95"
					r="51"
					fill="#434345"
					stroke="#646567"
					stroke-miterlimit="10"
					stroke-width="2.2"
				/>
				<text
					x="158.00047"
					y="308.05099"
					fill="#b0b0b0"
					font-family="Roboto-Medium, Roboto"
					font-size="12px"
					font-weight="500"
					style="isolation: isolate"
				>
					V2
				</text>
			</g>
		</g>
	</g>
</template>
<script>
export default {
	name: 'pads',
	props: {
		pad1active: Boolean,
		pad2active: Boolean,
	},
};
</script>
