import { make } from 'vuex-pathify';
import { store as aircraftStates } from './aircraftStates';
import { store as layers } from './layers';
import { store as settings } from './settings';
import { store as interactions } from './interactions';
import { store as regions } from './regions';
import { store as vertiportStates } from './vertiportStates';

const state = {};

export const store = {
	namespaced: true,
	state: () => state,
	modules: {
		vertiportStates,
		aircraftStates,
		layers,
		settings,
		interactions,
		regions,
	},
	getters: make.getters(state),
	mutations: make.mutations(state),
	actions: {},
};
