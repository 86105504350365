const data = {
	L: {
		type: 'L',
		name: 'Light',
		description: 'Light aircraft types of 7 000 kg (15 500 lb) or less.',
	},
	M: {
		type: 'M',
		name: 'Medium',
		description: 'MEDIUM aircraft types less than 136 000 kg (300 000 lb) and more than 7 000 kg (15 500 lb).',
	},
	H: {
		type: 'H',
		name: 'Heavy',
		description: 'HEAVY aircraft types of 136 000 kg (300 000 lb) or more (except those specified as J).',
	},
	J: {
		type: 'J',
		name: 'Super',
		description: 'SUPER aircraft types with a maximum take-off mass in the order of 560 000 kg.',
	},
};

function getTypes() {
	//return Object.keys(data).map((key) => data[key].type); bei JSON
	return Object.values(data).map((element) => element.type);
}

function getNames() {
	return Object.values(data).map((element) => element.name);
}

function getDescriptions() {
	return Object.values(data).map((element) => element.description);
}

function getTypesNames() {
	return Object.values(data).map((element) => element.type + ' - ' + element.name);
}

function getWtcByMtow(mass) {
	if (mass >= 560000) return data['J'];
	if (mass >= 136000) return data['H'];
	if (mass >= 7000) return data['M'];
	if (mass >= 0) return data['L'];
	return null;
}

export default {
	getTypes,
	getNames,
	getDescriptions,
	getTypesNames,
	getWtcByMtow,
};
