<template>
	<v-navigation-drawer v-model="drawerExtended" v-bind="$attrs">
		<v-list>
			<template v-for="(item, index) in navItems">
				<v-list-group v-if="item.sub" :key="item.name">
					<template v-slot:activator>
						<v-list-item-icon>
							<!-- uncomment next element if you prefer colored sub-menu root element -->
							<!-- <v-icon :class="{ activeGolden: item.active && mini }">{{ item.icon }}</v-icon> -->
							<!-- comment next element if you prefer colored sub-menu root element -->
							<v-icon>{{ item.icon }}</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title v-text="item.name" />
						</v-list-item-content>
					</template>
					<!-- uncomment next element if you prefer v-divider below sub-menu root element -->
					<v-divider key="{{ 'sub' + index }}" v-if="item.active && mini" class="mx-3 customGoldDivider dottedBorder" />
					<v-list-item v-for="subItem in item.sub" :key="subItem.name" :to="subItem.path">
						<v-list-item-icon>
							<v-icon>{{ subItem.icon }}</v-icon>
						</v-list-item-icon>
						<v-list-item-title v-text="subItem.name" />
					</v-list-item>
				</v-list-group>

				<v-list-item v-else :key="item.name" :to="item.path">
					<v-list-item-icon>
						<v-icon>{{ item.icon }}</v-icon>
					</v-list-item-icon>
					<v-list-item-title v-text="item.name" />
				</v-list-item>

				<v-divider :key="index" v-if="index + 1 < navItems.length" class="mx-3 customGoldDivider" />
			</template>
		</v-list>
	</v-navigation-drawer>
</template>

<script>
export default {
	name: 'AirNavigation',
	props: {
		value: Boolean,
		navItems: Array,
	},
	computed: {
		drawerExtended: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
	data: () => ({}),
};
</script>

<style scoped lang="scss">
.customGoldDivider {
	border-color: var(--v-golden-base) !important;
}

.dottedBorder {
	border-style: dashed !important;
}

.activeGolden {
	color: var(--v-golden-base) !important;
}
</style>
