const data = [
	{
		code: 'ALTRV',
		description: 'for a flight operated in accordance with an altitude reservation',
	},
	{
		code: 'ATFMX',
		description: 'for a flight approved for exemption from ATFM measures by the appropriate ATS authority',
	},
	{
		code: 'FFR',
		description: 'fire-fighting',
	},
	{
		code: 'FLTCK',
		descritption: 'flight check for calibration of NAVAIDs',
	},
];

function getSTS() {
	// return Object.keys(data).map(
	// 	(key) => data[key].code + '=' + data[key].description
	// );
	return data.map((element) => element.code);
}

export default {
	getSTS,
};
