<template>
	<v-data-table
		:headers="headers"
		:items="items"
		:rows-per-page-items="pageSizeOptions"
		:pagination.sync="pagination"
		:total-items="totalNumberOfItems"
		@update:pagination="paginate"
	/>
</template>

<script>
export default {
	name: 'AirPaginationTable',
	props: {
		route: String,
	},
	data: () => ({
		totalNumberOfItems: 0,
		items: [],
		pageSizeOptions: [10, 25, 50, 100],
		pagination: {
			descending: !!this.$route.query.desc,
			sortBy: this.$route.query.orderby || 'name',
			rowsPerPage: +this.$route.query.limit || 10,
			page: +this.$route.query.page || 1,
			totalItems: 0,
		},
	}),
	beforeRouteUpdate(to, from, next) {
		this.fetchData(to);
		next();
	},
	methods: {
		buildQuery() {
			if (!this.route.startsWith('/')) this.route += '/';
			if (!this.route.endsWith('/')) this.route += '/';

			let params = '?limit=' + this.pagination.rowsPerPage + '&orderby=' + this.pagination.sortBy;
			if (this.pagination.page > 1) params += '&start=' + (this.pagination.page - 1) * this.pagination.rowsPerPage;
			if (this.pagination.descending) params += '&direction=desc';

			return this.route + params;
		},
		fetchData() {
			this.$services.backend.api
				.get(this.buildQuery())
				.then((result) => {
					this.totalNumberOfItems = result.data.resulttotals;
					this.items = result.data.items;
				})
				.error((err) => {
					console.log('unhandled error in AirPaginationTable', err);
				});
		},
		paginate(val) {
			// emitted by the data-table when changing page, rows per page, or the sorted column/direction - will be also immediately emitted after the component was created
			const query = this.$route.query;
			const obj = Object.assign({}, query);
			if (val.rowsPerPage !== this.pageSizeOptions[0]) obj.limit = val.rowsPerPage;
			if (val.descending) obj.desc = 'true';
			else delete obj.desc;
			obj.orderby = val.sortBy;
			obj.page = val.page;
			// check if old and new query are the same - VueRouter will not change the route if they are, so probably this is the first page loading
			let same = true;
			for (let key in query) {
				if (query[key] != obj[key]) {
					same = false;
					break;
				}
			}
			// to handle the case when a KEY exists in OBJ but not in query
			for (let key in obj) {
				if (query[key] != obj[key]) {
					same = false;
					break;
				}
			}
			if (same) this.fetchData();
			// page has been manually reloaded in the browser
			else {
				this.$router.replace({
					...this.$router.currentRoute,
					query: obj,
				});
			}
		},
	},
};
</script>
