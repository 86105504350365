import { make } from 'vuex-pathify';

const state = {
	selectedRegion: 'centralEurope',
	availableRegions: {
		northernEurope: {
			name: 'Northern Europe',
			position: { latitude: 65, longitude: 12 },
			zoom: 5,
		},
		centralEurope: { name: 'Central Europe', position: { latitude: 50, longitude: 15 }, zoom: 5 },
		southernEurope: { name: 'Southern Europe', position: { latitude: 40, longitude: 14 }, zoom: 5 },
		northAmerica: { name: 'North America', position: { latitude: 48, longitude: -110 }, zoom: 4 },
		centralAmerica: { name: 'Central America', position: { latitude: 11, longitude: -90 }, zoom: 5 },
		carribean: { name: 'Carribean', position: { latitude: 14, longitude: -75 }, zoom: 5 },
		southAmerica: { name: 'South America', position: { latitude: -26, longitude: -60 }, zoom: 4 },
		africa: { name: 'Africa', position: { latitude: 2, longitude: 40 }, zoom: 4 },
		asia: { name: 'Asia', position: { latitude: 50, longitude: 110 }, zoom: 4 },
		southeastAsia: { name: 'South-East Asia', position: { latitude: -5, longitude: 115 }, zoom: 4 },
		oceania: { name: 'Oceania', position: { latitude: -25, longitude: 120 }, zoom: 5 },
	},
};

export const store = {
	namespaced: true,
	modules: {},
	state: () => state,
	getters: make.getters(state),
	mutations: make.mutations(state),
};
