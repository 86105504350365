<template>
	<div>
		<v-form ref="form" :disabled="disabled">
			<v-card class="my-3">
				<v-card-subtitle>General Data</v-card-subtitle>
				<v-card-text>
					<v-row>
						<v-col cols="12" sm="6" md="6" lg="3" xl="2">
							<v-text-field
								v-model="editorItem.general.registration"
								outlined
								dense
								label="Registration"
								:rules="[$validationRules.required]"
							></v-text-field>
						</v-col>
						<v-col cols="12" sm="6" md="6" lg="3" xl="2">
							<v-tooltip top>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										v-model="editorItem.general.msn"
										outlined
										dense
										label="MSN"
										v-bind="attrs"
										v-on="on"
										:rules="[$validationRules.required]"
									></v-text-field>
								</template>
								<span>Manufacturer Serial Number</span>
							</v-tooltip>
						</v-col>
						<v-col cols="12" sm="6" md="6" lg="3" xl="2">
							<v-autocomplete
								:items="aircraftTypes"
								v-model="editorItem.references.aircraftType"
								outlined
								dense
								label="Aircraft Type"
								:rules="[$validationRules.required]"
								item-text="general.typeDesignator"
								item-value="_id"
							>
							</v-autocomplete>
						</v-col>
						<v-col cols="12" sm="6" md="6" lg="3" xl="2">
							<v-autocomplete
								:items="$services.airports.icaoCodes"
								v-model="editorItem.general.homebase"
								outlined
								dense
								label="Homebase"
							></v-autocomplete>
						</v-col>
						<v-col cols="12" sm="6" md="6" lg="3" xl="2">
							<v-autocomplete
								:items="this.$services.utils.colors.getColors()"
								v-model="editorItem.general.primaryColor"
								outlined
								dense
								label="Primary Color"
							></v-autocomplete>
						</v-col>
						<v-col cols="12" sm="6" md="6" lg="3" xl="2">
							<v-autocomplete
								:items="this.$services.utils.colors.getColors()"
								v-model="editorItem.general.secondaryColor"
								outlined
								dense
								label="Secondary Color"
							></v-autocomplete>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>

			<v-card class="my-3">
				<v-card-subtitle>Battery</v-card-subtitle>
				<v-card-text>
					<v-row>
						<v-col cols="12" sm="6" md="6" lg="3" xl="2">
							<v-text-field
								outlined
								dense
								label="Manufacturer"
								v-model="editorItem.battery.manufacturer"
								:rules="[$validationRules.required]"
							>
							</v-text-field>
						</v-col>
						<v-col cols="12" sm="6" md="6" lg="3" xl="2">
							<v-text-field outlined dense label="Model" v-model="editorItem.battery.modelName" />
						</v-col>
						<v-col cols="12" sm="6" md="6" lg="3" xl="2">
							<v-text-field
								outlined
								dense
								label="Capacity"
								v-model="editorItem.battery.capacity"
								:rules="[$validationRules.required, $validationRules.decimalString]"
								:suffix="$services.utils.units.energy"
							/>
						</v-col>
						<v-col cols="12" sm="6" md="6" lg="3" xl="2">
							<v-text-field
								outlined
								dense
								label="Bias"
								v-model="currentBias"
								:rules="[$validationRules.required, $validationRules.decimalString]"
								:suffix="$services.utils.units.percentage"
							/>
						</v-col>
						<v-col cols="12" sm="6" md="6" lg="3" xl="2">
							<v-text-field
								outlined
								dense
								label="Min. Temp"
								v-model="editorItem.battery.minTemperature"
								:suffix="$services.utils.units.temperature"
								:rules="[$validationRules.required, $validationRules.negativeDecimalString]"
							/>
						</v-col>
						<v-col cols="12" sm="6" md="6" lg="3" xl="2">
							<v-text-field
								outlined
								dense
								label="Max. Temp"
								v-model="editorItem.battery.maxTemperature"
								:suffix="$services.utils.units.temperature"
								:rules="[$validationRules.required, $validationRules.decimalString]"
							/>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>

			<v-card class="my-3">
				<v-card-subtitle>Passengers</v-card-subtitle>
				<v-card-text>
					<v-row>
						<v-col cols="12" sm="6" md="6" lg="4" xl="4">
							<v-text-field
								outlined
								dense
								label="PAX"
								v-model="editorItem.passengers"
								:rules="[$validationRules.required, $validationRules.integerString]"
							>
							</v-text-field>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>

			<v-card class="my-3">
				<v-card-subtitle>Mass</v-card-subtitle>
				<v-card-text>
					<v-row>
						<v-col cols="12" sm="6" md="6" lg="3" xl="2">
							<v-text-field
								outlined
								dense
								label="Empty Mass"
								v-model="editorItem.masses.emptyMass"
								:rules="[$validationRules.required, $validationRules.decimalString]"
								:suffix="$services.utils.units.mass"
							></v-text-field>
						</v-col>
						<v-col cols="12" sm="6" md="6" lg="3" xl="2">
							<v-text-field
								outlined
								dense
								label="Max. Mass"
								v-model="editorItem.masses.maxMass"
								:rules="[$validationRules.required, $validationRules.decimalString]"
								:suffix="$services.utils.units.mass"
							></v-text-field>
						</v-col>
						<v-col cols="12" sm="6" md="6" lg="3" xl="2">
							<v-tooltip top>
								<template v-slot:activator="{ on, attrs }">
									<!-- bind to a computed property -->
									<v-text-field
										readonly
										outlined
										dense
										label="WTC"
										v-bind="attrs"
										v-on="on"
										v-model="wtc"
									></v-text-field>
								</template>
								<span>ICAO-wake turbulence category</span>
							</v-tooltip>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>

			<v-card class="my-3">
				<v-card-subtitle>Mass and Balance Profile </v-card-subtitle>
				<v-card-text>
					<v-row>
						<v-col cols="12" sm="6" md="6" lg="6" xl="6">
							Fixed Mass Stations
							<v-text-field outlined dense label="Fixed Mass Stations"> </v-text-field>
						</v-col>
						<v-col cols="12" sm="6" md="6" lg="6" xl="6">
							Variable Stations
							<v-text-field outlined dense label="Variable Stations"> </v-text-field>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>

			<v-card class="my-3">
				<v-card-subtitle>Equipment</v-card-subtitle>
				<v-card-text>
					<v-row>
						<v-col cols="12" sm="6" md="6" lg="3" xl="3">
							<v-combobox
								v-model="editorItem.equipment.equipment"
								:items="this.$services.aircrafts.options.equipment.getEquipment()"
								label="Equipment"
								multiple
								outlined
								dense
								small-chips
							></v-combobox>
						</v-col>
						<v-col cols="12" sm="6" md="6" lg="3" xl="3">
							<v-combobox
								v-model="editorItem.equipment.surveillance"
								:items="this.$services.aircrafts.options.surveillance.getSurveillance()"
								label="SSR"
								multiple
								outlined
								dense
								small-chips
							></v-combobox>
						</v-col>
						<v-col cols="12" sm="6" md="4" lg="3" xl="3">
							<v-combobox
								v-model="editorItem.equipment.pbn"
								:items="this.$services.aircrafts.options.pbn.getPBN()"
								label="PBN"
								multiple
								outlined
								dense
								small-chips
							></v-combobox>
						</v-col>
					</v-row>
				</v-card-text>

				<v-card-actions class="justify-center">
					<v-btn medium @click="showDialog()">Save Aircraft</v-btn>
					<v-btn medium @click="onCancel">Cancel</v-btn>
					<!-- <v-btn block rounded @click="clean()"> Clean </v-btn> -->
				</v-card-actions>
			</v-card>
			<yes-cancel-dialog
				v-model="dialogOpen"
				title="Save Aircraft"
				message="Are your sure to save Aircraft to the Database?"
				@yes="$emit('save')"
			/>
		</v-form>
	</div>
</template>
<script>
import { Aircraft } from '@/models';
import { generateMixin } from '@/mixins/crud/crudEditor';
export default {
	name: 'AirAircraftEditor',
	mixins: [generateMixin(Aircraft)],
	props: {
		aircraftTypes: Array, // ToDo: Implement validation/type-check
	},
	data: () => ({
		passengerTableHeaders: [
			{ text: 'Seating Section', value: 'seatingSection' },
			{ text: 'Capacity', value: 'capacity' },
		],
	}),
	computed: {
		wtc: {
			get() {
				if (this.editorItem.masses.maxMass > 0)
					return this.$services.aircrafts.wtc.getWtcByMtow(this.editorItem.masses.maxMass).type;
				return null;
			},
			set() {},
		},

		currentBias: {
			get: function () {
				return this.editorItem.battery.bias ? (this.editorItem.battery.bias * 100.0 * 10) / 10 : null;
			},
			set: function (value) {
				this.editorItem.battery.bias = value / 100.0;
			},
		},
	},
};
</script>
