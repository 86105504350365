import { make } from 'vuex-pathify';
import { SET_ITEMS, SAVE_ITEMS, REMOVE_KEYS, SET_LOADING, SET_SAVING, SET_DELETING } from '@/store/mutationTypes';
import { LOAD, SAVE, ARCHIVE } from '@/store/actionTypes';
import api from '@/services/backend';
import { generateModule as generateModuleOperationStatus } from '@/store/helpers/operationStatus';

const state = {
	items: {},
};

function generateLocalMutations(model) {
	return {
		[SET_ITEMS]: function (state, payload) {
			state.items = {};
			for (const item of Object.values(payload)) {
				state.items[item._id] = model.create(item);
			}
		},
		[SAVE_ITEMS]: function (state, payload) {
			if (!Array.isArray(payload)) payload = [payload];
			for (const item of Object.values(payload)) {
				state.items[item._id] = model.create(item);
			}
		},
		[REMOVE_KEYS]: function (state, payload) {
			if (!Array.isArray(payload)) payload = [payload];
			for (const key of payload) {
				if (state.items[key]) delete state.items[key];
			}
		},
	};
}

function generateLocalActions(model) {
	if (model && model.name) {
		return {
			[LOAD]: async ({ commit }, payload) => {
				return new Promise((resolve, reject) => {
					commit(`operationStatus/${SET_LOADING}`, true);
					const filter = {
						'meta.archived': payload && payload.archived ? payload.archived : false,
						...(payload ? payload : {}),
					};
					api
						.loadByFilter(model.name, filter)
						.then((res) => {
							commit(SET_ITEMS, res.data);
							resolve(res);
						})
						.catch((error) => {
							// commit(`operationStatus/${SET_ERROR}`, error);
							console.log(error);
							reject(error);
						})
						.finally(() => {
							commit(`operationStatus/${SET_LOADING}`, false);
						});
				});
			},
			[SAVE]: async ({ commit }, payload) => {
				return new Promise((resolve, reject) => {
					commit(`operationStatus/${SET_SAVING}`, true);
					if (!Array.isArray(payload)) payload = [payload];
					api
						.save(model.name, payload)
						.then((res) => {
							console.log('database returned after saving:', res.data);
							commit(REMOVE_KEYS, Object.keys(res.data));
							commit(SAVE_ITEMS, Object.values(res.data));
							resolve(res);
						})
						.catch((error) => {
							// commit(`operationStatus/${SET_ERROR}`, error);
							console.log(error);
							reject(error);
						})
						.finally(() => {
							commit(`operationStatus/${SET_SAVING}`, false);
						});
				});
			},
			[ARCHIVE]: async ({ commit }, payload) => {
				return new Promise((resolve, reject) => {
					commit(`operationStatus/${SET_DELETING}`, true);
					if (!Array.isArray(payload)) payload = [payload];
					api
						.archive(model.name, payload)
						.then((res) => {
							console.log('database returned after archiving:', res.data);
							commit(REMOVE_KEYS, Object.keys(res.data));
							resolve(res);
						})
						.catch((error) => {
							// commit(`operationStatus/${SET_ERROR}`, error);
							console.log(error);
							reject(error);
						})
						.finally(() => {
							commit(`operationStatus/${SET_DELETING}`, false);
						});
				});
			},
		};
	} else {
		return {};
	}
}

/**
 * Generates a CRUD module with state 'items:{}, selectedKey:null'.
 * @param {*} model The model for the CRUD operation. Passing NULL will not add the default CRUD backend actions.
 * @param {*} populateFields A string array of keys which should be populated by the backend.
 * @param {*} additionalModules
 * @param {*} additionalState
 * @param {*} additionalGetters
 * @param {*} additionalMutations
 * @param {*} additionalActions
 * @returns
 */
export function generateModule(
	model,
	additionalModules = {},
	additionalState = {},
	additionalGetters = {},
	additionalMutations = {},
	additionalActions = {}
) {
	return {
		namespaced: true,
		modules: {
			operationStatus: generateModuleOperationStatus(),
			...additionalModules,
		},
		state: () => ({ ...state, ...additionalState }),
		getters: {
			...make.getters({ ...state, ...additionalState }),
			...additionalGetters,
		},
		mutations: {
			...make.mutations({ ...state, ...additionalState }),
			...generateLocalMutations(model),
			...additionalMutations,
		},
		actions: {
			...generateLocalActions(model),
			...additionalActions,
		},
	};
}
