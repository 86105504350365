<template>
	<div class="d-flex flex-wrap">
		<div class="mx-2" v-for="(item, index) in value" :key="index">
			<div class="caption text-center">
				{{ item.name }}
			</div>
			<div class="text-center">{{ item.frequency }}</div>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		value: Array,
	},
	watch: {
		value() {
			this.$emit('input', this.value);
		},
	},
};
</script>
