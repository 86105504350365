import lodash from 'lodash';
import options from './options';
import wtc from './wtc';

const defaultAircraft = {
	_id: null,
	general: {
		registration: '',
		msn: '',
		homebase: '',
		primaryColor: '',
		secondaryColor: '',
	},
	aircraftType: null,
	battery: {
		manufacturer: null,
		modelName: null,
		capacity: null,
		bias: null,
		minTemperature: null,
		maxTemperature: null,
	},
	passengers: null,
	masses: {
		emptyMass: null,
		maxMass: null,
	},
	massAndBalance: {},
	equipment: {
		equipment: [],
		surveillance: [],
		pbn: [],
	},
};

function createEmptyAircraft() {
	return lodash.cloneDeep(defaultAircraft);
}

export default {
	options,
	wtc,
	createEmptyAircraft,
};
