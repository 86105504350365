<template>
	<v-container fluid>
		<v-row>
			<v-col cols="12">
				<air-flight-plan-editor
					v-model="flightPlan"
					ref="editor"
					:aircrafts="Object.values(aircrafts)"
					:aircraftTypes="Object.values(aircraftTypes)"
				/>
			</v-col>
		</v-row>
		<yes-cancel-dialog
			v-model="dialogOpen"
			title="File OFP"
			message="Are your sure to file OFP?"
			@yes="calculateFlightPlan"
		/>

		<air-sideboard alwaysExpanded disableSearch permanent app clipped right dark>
			<v-card class="py-3 mb-4">
				<v-card-title class="py-1">Actions</v-card-title>
				<v-card-actions class="justify-left">
					<v-btn :disabled="!!flightPlan && !!flightPlan._id" medium @click="showDialog()"> Save OFP </v-btn>
					<!-- <v-btn medium @click="cancel()"> Cancel </v-btn> -->
					<!-- <v-btn medium rounded @click="clean()"> Clean </v-btn> -->
				</v-card-actions>
			</v-card>
			<fp-result-overview v-if="flightPlan._id" :flightPlan="flightPlan" />
			<fp-computed-preview v-else :flightPlan="flightPlan" :aircraftTypes="aircraftTypes" />
		</air-sideboard>
	</v-container>
</template>
<script>
import { Flight, FlightPlan, Aircraft, AircraftType } from '@/models';
import { get, call } from 'vuex-pathify';
import { LOAD, SAVE } from '@/store/actionTypes';
export default {
	name: 'FlightPlanManagement',
	data: () => ({
		dialogOpen: false,
		flightPlan: FlightPlan.create(),
	}),
	computed: {
		aircraftTypes: get(`crud/${AircraftType.name}/items`),
		aircrafts: get(`crud/${Aircraft.name}/items`),
		flights: get(`crud/${Flight.name}/items`),
	},
	created() {
		this.loadModels([Flight, Aircraft, AircraftType]).then(() => {
			const { flightId } = this.$route.query;
			if (this.flights[flightId]) {
				// IMPORTANT: cloneDeep was needed to keep vue reactivity!
				// If you just assign, Vue looses track of the reference and the object is no longer reactive.
				this.flightPlan = this.$_.cloneDeep(this.flights[flightId].getFlightPlanDraft(Object.values(this.aircrafts)));
			}
		});
	},
	methods: {
		loadModels: call(`crud/${LOAD}`),
		apiCalculate: call(`crud/${FlightPlan.name}/${SAVE}`),
		calculateFlightPlan() {
			this.apiCalculate(this.flightPlan).then((res) => {
				this.flightPlan = FlightPlan.create(res.data);
			});
		},
		showDialog() {
			const isEditorFormValid = this.$refs.editor.$refs.form.validate();
			if (isEditorFormValid) {
				this.dialogOpen = true;
			}
		},
		cancel() {
			this.flightPlan = this.$services.flightPlan.createEmptyFlightPlan();
		},
	},
};
</script>
