<template>
	<l-feature-group v-if="true">
		<l-polyline
			@click="selectMapObject(flightPlan)"
			:lat-lngs="flightPlan.route.waypoints.map((waypoint) => waypoint.position)"
			:color="isThisMapObjectSelected ? selectedColor : polylineColor"
		/>
		<fw-waypoint v-for="(waypointItem, index) of flightPlan.route.waypoints" :key="index" :waypoint="waypointItem" />
	</l-feature-group>
</template>
<script>
import { get } from 'vuex-pathify';
import { FlightPlan } from '@/models';
import { mixin as selectableMapObject } from '@/mixins/selectableMapObject';

export default {
	name: `fwFlight`,
	mixins: [selectableMapObject],
	props: {
		flightPlan: FlightPlan,
	},
	computed: {
		...get(`flightWatch/settings/layers`, {
			polylineColor: 'flightPlans@route.polyline.color',
			selectedColor: 'general@selectedColor',
		}),
	},
	methods: {},
};
</script>
