<template>
	<div @mouseover="isMouseover = true" @mouseout="isMouseover = false">
		<v-navigation-drawer
			:key="renderKey"
			:mini-variant="miniProp"
			:expand-on-hover="expandOnHoverProp"
			disable-route-watcher
			v-bind="$attrs"
			:width="width"
		>
			<v-toolbar dense>
				<v-icon v-if="!isMouseover && expandOnHoverProp">mdi-lock-open</v-icon>
				<v-toolbar-title class="ml-4">{{ titleProp }}</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-btn v-if="!disableSearch" @click="$emit('search')" icon>
					<v-icon>mdi-magnify</v-icon>
				</v-btn>
				<v-btn v-if="!disableSettings" @click="$emit('settings')" icon>
					<v-icon>mdi-dots-vertical</v-icon>
				</v-btn>
				<v-btn v-if="!alwaysExpanded" icon @click.stop="toggleexpandOnHoverProp()">
					<v-icon>{{ expandOnHoverProp ? 'mdi-lock-open' : 'mdi-lock' }}</v-icon>
				</v-btn>
			</v-toolbar>

			<div class="d-flex align-center fill-height">
				<div class="ma-3">
					<v-btn
						class="buttonSideboard ma-2"
						:style="expandOnHoverProp ? 'visibility:hidden;' : ''"
						small
						@click.stop="miniProp = !miniProp"
						icon
					>
						<v-icon v-if="miniProp" class="buttonSideboard"> mdi-chevron-double-left</v-icon>

						<v-icon v-else class="buttonSideboard"> mdi-chevron-double-right </v-icon>
					</v-btn>
				</div>

				<div class="ma-4 align-self-start flex-grow-1">
					<slot>Override the default slot to render your content.</slot>
				</div>
			</div>
		</v-navigation-drawer>
	</div>
</template>
<script>
export default {
	name: 'AirSideboard',
	props: {
		width: {
			type: String,
			default: '20%',
		},
		title: {
			type: String,
			default: 'Sideboard',
		},
		disableSearch: {
			type: Boolean,
			default: false,
		},
		disableSettings: {
			type: Boolean,
			default: false,
		},
		alwaysExpanded: {
			type: Boolean,
			default: false,
		},
		// use this prop only with .sync
		mini: {
			type: Boolean,
			default: undefined,
		},
		// use this prop only with .sync
		expandOnHover: {
			type: Boolean,
			default: undefined,
		},
	},
	data: () => ({
		renderKey: 0,
		isMouseover: false,
		expandOnHoverLocal: true,
		miniLocal: false,
	}),
	computed: {
		titleProp: {
			get() {
				return this.title;
			},
			set(value) {
				this.$emit('update:title', value);
			},
		},
		miniProp: {
			get() {
				if (typeof this.mini !== 'undefined') return this.mini;
				else return this.miniLocal;
			},
			set(value) {
				if (typeof this.mini !== 'undefined') this.$emit('update:mini', value);
				else this.miniLocal = value;
			},
		},
		expandOnHoverProp: {
			get() {
				if (this.alwaysExpanded) return false;
				else if (typeof this.expandOnHover !== 'undefined') return this.expandOnHover;
				else return this.expandOnHoverLocal;
			},
			set(value) {
				if (typeof this.expandOnHover !== 'undefined') this.$emit('update:expandOnHover', value);
				else this.expandOnHoverLocal = value;
			},
		},
	},
	methods: {
		toggleexpandOnHoverProp() {
			if (!this.expandOnHoverProp) {
				this.renderKey++;
			}
			this.expandOnHoverProp = !this.expandOnHoverProp;
		},
	},
};
</script>

<style scoped>
.buttonSideboard {
	width: 0;
	height: 0;
	border-top: 42vh solid transparent;
	border-bottom: 42vh solid transparent;
	position: fixed;
	top: 7%;
}
</style>
