<template>
	<div class="d-block" style="width: 100%; height: 100%">
		<vc-vertiport-control :vertiportState="vertiportStates[value.ident]"></vc-vertiport-control>
	</div>
</template>
<script>
import vcVertiportControl from '@/componentsViews/vertiportControl/components/vcVertiportControl.vue';
import { get } from 'vuex-pathify';

export default {
	components: { vcVertiportControl },
	name: 'fwVertiportDetails',
	props: {
		value: Object,
	},
	data() {
		return {
			updateIntervalId: null,
		};
	},
	computed: {
		vertiportStates: get(`flightWatch/vertiportStates/items`),
	},
};
</script>
