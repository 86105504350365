<template>
	<v-form :disabled="disabled" ref="form">
		<v-card class="my-3">
			<v-card-subtitle>General Data</v-card-subtitle>

			<v-card-text>
				<v-row>
					<v-col cols="12" sm="6" md="6" lg="3" xl="2">
						<v-text-field
							v-model="editorItem.general.manufacturerCode"
							outlined
							dense
							label="Manufacturer Code"
							:rules="[$validationRules.required]"
						></v-text-field>
					</v-col>

					<v-col cols="12" sm="6" md="6" lg="3" xl="2">
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									v-model="editorItem.general.modelName"
									outlined
									dense
									label="Model Name"
									v-bind="attrs"
									v-on="on"
								></v-text-field>
							</template>
							<span>if applicable, empty otherwise</span>
						</v-tooltip>
					</v-col>
					<v-col cols="12" sm="6" md="6" lg="3" xl="2">
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									v-model="editorItem.general.modelVersion"
									outlined
									dense
									label="Model Version"
									v-bind="attrs"
									v-on="on"
								></v-text-field>
							</template>
							<span>if applicable, empty otherwise</span>
						</v-tooltip>
					</v-col>
					<v-col cols="12" sm="6" md="6" lg="3" xl="2">
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									v-model="editorItem.general.typeDesignator"
									outlined
									dense
									label="Type Designator Code"
									v-bind="attrs"
									v-on="on"
									:rules="[$validationRules.fourChar, $validationRules.required]"
								></v-text-field>
							</template>
							<span>not more than four characters</span>
						</v-tooltip>
					</v-col>
					<v-col cols="12" sm="6" md="6" lg="3" xl="2">
						<v-autocomplete
							:items="this.$services.aircraftTypes.options.aircraftCategory.getDescriptions()"
							v-model="editorItem.general.category"
							outlined
							dense
							label="Category"
							:rules="[$validationRules.required]"
						></v-autocomplete>
					</v-col>
					<v-col cols="12" sm="6" md="6" lg="3" xl="2">
						<v-autocomplete
							:items="this.$services.aircraftTypes.options.specialDesignators.getSdesigs()"
							v-model="editorItem.general.specialTypeDesignator"
							outlined
							dense
							label="Special Type Designator"
						></v-autocomplete>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

		<v-card class="my-3">
			<v-card-subtitle>Engine Data</v-card-subtitle>
			<v-card-text>
				<v-row>
					<v-col cols="12" sm="6" md="6" lg="3" xl="2">
						<v-text-field
							v-model="editorItem.engine.manufacturerCode"
							outlined
							dense
							label="Manufacturer Code"
							:rules="[$validationRules.required]"
						></v-text-field>
					</v-col>
					<v-col cols="12" sm="6" md="6" lg="4" xl="2">
						<v-text-field v-model="editorItem.engine.modelNumber" outlined dense label="Model Number"></v-text-field>
					</v-col>

					<v-col cols="12" sm="6" md="6" lg="4" xl="2">
						<v-autocomplete
							v-model="editorItem.engine.engineType"
							:items="this.$services.aircraftTypes.options.engineTypes.getDescriptions()"
							outlined
							dense
							label="Engine Type"
							:rules="[$validationRules.required]"
						></v-autocomplete>
					</v-col>
					<v-col cols="12" sm="6" md="6" lg="3" xl="2">
						<v-text-field
							v-model="editorItem.engine.engineCount"
							outlined
							dense
							label="Number of Engines"
							:rules="[$validationRules.required, $validationRules.integerString]"
						></v-text-field>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

		<v-card class="my-3">
			<v-card-subtitle>General Performance</v-card-subtitle>
			<v-card-text>
				<v-row>
					<v-col cols="12" sm="4" md="6" lg="4" xl="3">
						<v-text-field
							v-model="editorItem.performance.serviceCeiling"
							outlined
							dense
							label="Service Ceiling"
							:rules="[$validationRules.required, $validationRules.integerString]"
							:suffix="this.$services.utils.units.altitude"
						></v-text-field>
					</v-col>
					<v-col cols="12" sm="4" md="6" lg="4" xl="3">
						<v-text-field
							v-model="editorItem.performance.verticalRates.climb"
							outlined
							dense
							label="Rate of Climb"
							:rules="[$validationRules.required, $validationRules.integerString]"
							:suffix="this.$services.utils.units.verticalSpeed"
						></v-text-field>
					</v-col>
					<v-col cols="12" sm="4" md="6" lg="4" xl="3">
						<v-text-field
							v-model="editorItem.performance.verticalRates.descent"
							outlined
							dense
							label="Rate of Descent"
							:rules="[$validationRules.required, $validationRules.integerString]"
							:suffix="this.$services.utils.units.verticalSpeed"
						></v-text-field>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

		<v-card class="my-3">
			<v-card-subtitle>Energy Performance (consumption per minute)</v-card-subtitle>
			<v-card-text>
				<v-row>
					<v-col cols="12" sm="3" md="4" lg="3" xl="2">
						<v-text-field
							label="Air Taxi"
							v-model="editorItem.performance.energyPerMinute.taxi"
							placeholder=""
							outlined
							dense
							:rules="[$validationRules.required, $validationRules.decimalString]"
							:suffix="$services.utils.units.energy"
						></v-text-field>
					</v-col>
					<v-col cols="12" sm="3" md="4" lg="3" xl="2">
						<v-text-field
							label="Takeoff"
							v-model="editorItem.performance.energyPerMinute.takeoff"
							placeholder=""
							outlined
							dense
							:rules="[$validationRules.required, $validationRules.decimalString]"
							:suffix="$services.utils.units.energy"
						></v-text-field>
					</v-col>
					<v-col cols="12" sm="3" md="4" lg="3" xl="2">
						<v-text-field
							label="Climb"
							v-model="editorItem.performance.energyPerMinute.climb"
							placeholder=""
							outlined
							dense
							:rules="[$validationRules.required, $validationRules.decimalString]"
							:suffix="$services.utils.units.energy"
						></v-text-field>
					</v-col>
					<v-col cols="12" sm="3" md="4" lg="3" xl="2">
						<v-text-field
							label="Cruise"
							v-model="editorItem.performance.energyPerMinute.cruise"
							placeholder=""
							outlined
							dense
							:rules="[$validationRules.required, $validationRules.decimalString]"
							:suffix="$services.utils.units.energy"
						></v-text-field>
					</v-col>
					<v-col cols="12" sm="3" md="4" lg="3" xl="2">
						<v-text-field
							label="Descent"
							v-model="editorItem.performance.energyPerMinute.descent"
							placeholder=""
							outlined
							dense
							:rules="[$validationRules.required, $validationRules.decimalString]"
							:suffix="$services.utils.units.energy"
						></v-text-field>
					</v-col>
					<v-col cols="12" sm="3" md="4" lg="3" xl="2">
						<v-text-field
							label="Landing"
							v-model="editorItem.performance.energyPerMinute.landing"
							placeholder=""
							outlined
							dense
							:rules="[$validationRules.required, $validationRules.decimalString]"
							:suffix="$services.utils.units.energy"
						></v-text-field>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

		<v-card class="my-3">
			<v-card-subtitle>Speed Profiles </v-card-subtitle>
			<v-card-text>
				<v-row>
					<v-col cols="12" sm="12" md="12">
						<v-data-table :headers="speedProfilesHeaders" :items-per-page="5"> </v-data-table>
					</v-col>
				</v-row>
			</v-card-text>

			<v-card-actions class="justify-center">
				<v-btn medium @click="showDialog"> Save Aircraft Type </v-btn>
				<v-btn meduim @click="onCancel"> Cancel </v-btn>
			</v-card-actions>
		</v-card>
		<yes-cancel-dialog
			v-model="dialogOpen"
			title="Save Aircraft Type"
			message="Are your sure to save Aircraft-Type to the Database?"
			@yes="$emit('save')"
		/>
	</v-form>
</template>
<script>
import { AircraftType } from '@/models';
import { generateMixin } from '@/mixins/crud/crudEditor';
export default {
	name: 'AirAircraftTypeEditor',
	mixins: [generateMixin(AircraftType)],
	data: () => ({
		speedProfilesHeaders: [
			{ text: 'Name', value: 'name' },
			{ text: 'Take Off [kt]', value: 'takeoff' },
			{ text: 'Climb [kt]', value: 'climb' },
			{ text: 'Cruise [kt]', value: 'cruise' },
			{ text: 'Descent [kt]', value: 'descent' },
			{ text: 'Landing [kt]', value: 'landing' },
		],
	}),
};
</script>
