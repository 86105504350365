import axios from 'axios';

const options = {
	baseURL: process.env.VUE_APP_BACKEND_URL,
	headers: {},
};

const api = axios.create(options);

// const token = localStorage.getItem('token');
// if (token) {
// 	options.headers['Authorization'] = token;
// }

function loadById(modelName, _id, populatePaths = []) {
	return api.post('/crud/getById', { model: modelName, _id, populatePaths }); // { model....} is the request.body
}

function loadByFilter(modelName, filter = { meta: { archived: false } }, populatePaths = []) {
	return api.post('/crud/getByFilter', { model: modelName, filter, populatePaths });
}

function save(modelName, items, populatePaths = []) {
	return api.post('/crud/createOrUpdate', { model: modelName, data: items, populatePaths });
}

function upload(modelName, file) {
	if (file) {
		let formData = new FormData();
		formData.append('model', modelName);
		formData.append('file', file, file.name);

		return api.post('/crud/upload', formData);
	} else {
		console.log('there are no files.');
	}
}

/**
 * Archives one or more items. This means store it in the "archive" collection and remove it from its old collection.
 * @param {*} model The Model you want to search in.
 * @param {*} ids Can be a single id, an array of ids, an item containing an id or an Array containing items with ids.
 * @returns
 */
function archive(modelName, items) {
	return api.post('/crud/archive', { model: modelName, data: items });
}

function saveFlightPlan(flightPlan) {
	return api.post('/flightPlanning/createFlightPlan', flightPlan);
}

function getAirportInfos(icaoCodes) {
	return api.post('flightPlanning/getAirportInfos', icaoCodes);
}

// flightWatch
function getAircraftStates(filter) {
	return api.post('flightWatch/getAircraftStates', filter);
}
function getVertiportStates(icaoCodes = undefined) {
	return api.post('flightWatch/getVertiportStates', icaoCodes);
}

export default {
	api,
	loadById,
	loadByFilter,
	save,
	archive,
	upload,
	flightPlanning: {
		save: saveFlightPlan,
		getAirportInfos,
	},
	flightWatch: {
		getAircraftStates,
		getVertiportStates,
	},
};
