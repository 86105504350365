import Vue from 'vue';

import 'leaflet/dist/leaflet.css';
import './firefoxWillChangeWorkaround.css';

import {
	LMap,
	LControl,
	LTileLayer,
	LImageOverlay,
	LFeatureGroup,
	LTooltip,
	LPopup,
	LMarker,
	LIcon,
	LCircle,
	LPolyline,
	LControlZoom,
} from 'vue2-leaflet';

Vue.component('l-map', LMap);
Vue.component('l-control', LControl);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-image-overlay', LImageOverlay);
Vue.component('l-feature-group', LFeatureGroup);
Vue.component('l-tooltip', LTooltip);
Vue.component('l-popup', LPopup);
Vue.component('l-marker', LMarker);
Vue.component('l-icon', LIcon);
Vue.component('l-circle', LCircle);
Vue.component('l-polyline', LPolyline);
Vue.component('l-control-zoom', LControlZoom);

import { Icon } from 'leaflet';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
	iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
	iconUrl: require('leaflet/dist/images/marker-icon.png'),
	shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
