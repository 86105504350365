<template>
	<l-circle
		v-if="visible"
		:lat-lng="position"
		:radius="radius * 1850"
		:color="strokeColor"
		:weight="strokeWeight"
		:fillColor="fillColor"
		:fillOpacity="fillOpacity"
		:interactive="false"
	></l-circle>
</template>
<script>
import { get } from 'vuex-pathify';
import { Position2D } from '@/models';
export default {
	name: 'fwAreaOfOperation',
	props: {
		position: Position2D,
	},
	computed: {
		...get('flightWatch/settings/layers', [
			'flightPlans@route.areaOfOperations.visible',
			'flightPlans@route.areaOfOperations.fillColor',
			'flightPlans@route.areaOfOperations.fillOpacity',
			'flightPlans@route.areaOfOperations.strokeWeight',
			'flightPlans@route.areaOfOperations.strokeColor',

			'flightPlans@route.areaOfOperations.radius',
		]),
	},
};
</script>
