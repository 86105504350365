<template>
	<v-card class="mx-auto">
		<v-card-title class="subheading font-weight-bold">
			<span>Waypoint</span>
			<v-spacer></v-spacer>
			<span class="golden--text">{{ value.ident }}</span>
		</v-card-title>
		<v-card-subtitle>
			<v-divider></v-divider>
		</v-card-subtitle>
		<v-card-text>
			<v-list>
				<v-list-item v-for="entries of Object.entries(value).filter((item) => item[1] !== null)" :key="entries[0]">
					<template v-if="entries[0] === 'position'">
						<v-list-item-content>
							<v-list-item-title class="font-weight-bold">{{ $_.startCase(entries[0]) }}:</v-list-item-title>
							<div v-for="(subVal, subKey) of entries[1]" :key="subKey">
								<ul>
									<li>
										<div class="d-flex">
											<div>{{ subKey }}</div>
											<div class="ml-auto">
												{{ formatPosition(subKey, subVal) }}
											</div>
										</div>
									</li>
								</ul>
							</div>
						</v-list-item-content>
					</template>
					<template v-else>
						<v-list-item-content>
							<v-list-item-title class="font-weight-bold text-wrap">{{ $_.startCase(entries[0]) }}:</v-list-item-title>
						</v-list-item-content>
						<v-list-item-content v-if="entries[0] === 'via'" class="justify-end">
							{{ formatVia(entries[1]) }}
						</v-list-item-content>
						<v-list-item-content v-else-if="entries[0] === 'distance'" class="justify-end">
							{{ formatDistance(entries[1]) }}</v-list-item-content
						>
						<v-list-item-content v-else-if="entries[0] === 'magneticTrack'" class="justify-end">
							{{ formatMagneticTrack(entries[1]) }}</v-list-item-content
						>
						<v-list-item-content v-else class="justify-end"> {{ entries[1] }} </v-list-item-content>
					</template>
				</v-list-item>
			</v-list>
		</v-card-text>
	</v-card>
</template>
<script>
import { Waypoint } from '@/models';
export default {
	name: 'FlightWatchMapObjectDetails',
	props: {
		value: Waypoint,
	},
	methods: {
		formatPosition(type, value) {
			var out;
			if (type === 'latitude') {
				out = value >= 0 ? 'N' : 'S';
			} else if (type === 'longitude') {
				out = value >= 0 ? 'E' : 'W';
			}
			out += Math.abs(value).toFixed(4) + '°';
			return out;
		},
		formatVia(value) {
			return value.ident + ' (' + value.type + ')';
		},

		formatDistance(value) {
			return value.toFixed(2);
		},
		formatMagneticTrack(value) {
			return value.toFixed();
		},
	},
};
</script>
<style lang="scss" scoped>
.noListStyle {
	list-style-type: none;
}
</style>
