const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

function formatDateTimeUtc(date) {
	// return date.replace('T', ' ').replace(/:[0-9]{2}\.[0-9]{3}/, '');
	if (typeof date === 'string') date = new Date(date);
	return (
		date.getUTCFullYear() +
		'-' +
		('0' + (date.getUTCMonth() + 1)).slice(-2) +
		'-' +
		('0' + date.getUTCDate()).slice(-2) +
		' ' +
		('0' + date.getUTCHours()).slice(-2) +
		':' +
		('0' + date.getUTCMinutes()).slice(-2)
	);
}

function formatDateUtc(date) {
	// return date.replace('T', ' ').replace(/:[0-9]{2}\.[0-9]{3}/, '');
	if (typeof date === 'string') date = new Date(date);
	return date.getUTCFullYear() + '-' + months[date.getUTCMonth()] + '-' + date.getUTCDate();
}

function formatTimeUtc(date) {
	// return date.replace('T', ' ').replace(/:[0-9]{2}\.[0-9]{3}/, '');
	if (typeof date === 'string') date = new Date(date);
	return ('0' + date.getUTCHours()).slice(-2) + ':' + ('0' + date.getUTCMinutes()).slice(-2);
}

function dayDifference(date1, date2) {
	if (typeof date1 === 'string') date1 = new Date(date1);
	if (typeof date2 === 'string') date2 = new Date(date2);

	const d2 = new Date(date2.getUTCFullYear(), date2.getUTCMonth(), date2.getUTCDate());
	const d1 = new Date(date1.getUTCFullYear(), date1.getUTCMonth(), date1.getUTCDate());

	return Math.floor((d2 - d1) / (1000 * 60 * 60 * 24));
}

function dateTimeDifference(date1, date2) {
	if (typeof date1 === 'string') date1 = new Date(date1);
	if (typeof date2 === 'string') date2 = new Date(date2);
	return new Date(date2 - date1);
}

function minutesToTimeString(value) {
	const hours = Math.floor(value / 60);
	const minutes = Math.round(value % 60);

	return hours + ':' + ('0' + minutes).slice(-2);
}

function hoursToTimeString(value) {
	return minutesToTimeString(value * 60.0);
}

module.exports = {
	formatDateTimeUtc,
	formatDateUtc,
	formatTimeUtc,
	dayDifference,
	dateTimeDifference,
	minutesToTimeString,
	hoursToTimeString,
};
