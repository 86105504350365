var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{class:'elevation-10' + (_vm.fluid ? '' : ' d-inline-block'),attrs:{"headers":_vm.headers,"items":_vm.value,"items-per-page":100,"disable-sort":"","disable-pagination":"","disable-filtering":"","hide-default-footer":""},scopedSlots:_vm._u([_vm._l((['distance', 'Time', 'Energy']),function(value,index){return {key:("header." + value),fn:function(ref){
var header = ref.header;
return [_c('div',{key:index,staticClass:"my-2"},[_c('div',{},[_vm._v(" "+_vm._s(header.text.split(' ')[0])+" ")]),_c('div',{staticClass:"caption"},[_vm._v("["+_vm._s(header.text.split(' ')[1].split('/')[0])+"]")]),_c('div',{staticClass:"caption"},[_vm._v("["+_vm._s(header.text.split(' ')[1].split('/')[1])+"]")])])]}}}),{key:"item.ident",fn:function(ref){
var item = ref.item;
return [(item.ident === 'ToC' || item.ident === 'ToD')?_c('div',{staticClass:"purple--text text--lighten-1"},[_vm._v(" "+_vm._s(item.ident)+" ")]):_c('div',[_vm._v(" "+_vm._s(item.ident)+" ")])]}},{key:"item.via",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.via ? item.via.ident : ''))])]}},{key:"item.position",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.position.latitude >= 0 ? 'N' : 'S')+_vm._s(item.position.latitude.toFixed(4)))]),_c('div',[_vm._v(_vm._s(item.position.longitude >= 0 ? 'E' : 'W')+_vm._s(item.position.longitude.toFixed(4)))])]}},{key:"item.magneticTrack",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.magneticTrack !== null ? Math.round(item.magneticTrack) + '°' : '')+" ")])]}},{key:"item.distance",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-inline-block"},[_c('div',{staticClass:"text-right"},[_vm._v(" "+_vm._s(Math.round(item.distance))+" ")]),_c('div',{staticClass:"text-right"},[_vm._v(" "+_vm._s(Math.round(item.accumulatedDistance))+" ")])])]}},{key:"item.time",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-inline-block"},[_c('div',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$services.utils.dateFormatter.minutesToTimeString(Math.round(item.estimatedSegmentTime)))+" ")]),_c('div',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$services.utils.dateFormatter.minutesToTimeString(Math.round(item.estimatedAccumulatedTime)))+" ")])])]}},{key:"item.energy",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-inline-block"},[_c('div',{staticClass:"text-right"},[_vm._v(" "+_vm._s(Math.round(item.estimatedSegmentEnergy))+" ")]),_c('div',{staticClass:"text-right"},[_vm._v(" "+_vm._s(Math.round(item.estimatedAccumulatedEnergy))+" ")])])]}},{key:"body.prepend",fn:function(){return [_c('tr',[_c('td',{attrs:{"colspan":"100"}},[_c('air-flight-plan-frequencies',{model:{value:(_vm.airports.departure.frequencies),callback:function ($$v) {_vm.$set(_vm.airports.departure, "frequencies", $$v)},expression:"airports.departure.frequencies"}})],1)])]},proxy:true},{key:"body.append",fn:function(){return [_c('tr',[_c('td',{attrs:{"colspan":"100"}},[_c('air-flight-plan-frequencies',{model:{value:(_vm.airports.arrival.frequencies),callback:function ($$v) {_vm.$set(_vm.airports.arrival, "frequencies", $$v)},expression:"airports.arrival.frequencies"}})],1)])]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }