<template>
	<div v-if="weather && weather.METAR && weather.TAF">
		<div class="mt-3 mb-1 font-weight-bold">METAR</div>
		<div class="py-1">{{ weather.METAR }}</div>
		<div class="mt-2 mb-1 font-weight-bold">TAF</div>
		<div>
			<template v-for="(line, index) in weather.TAF.split(' BECMG ')">
				<div class="py-1" :key="index">{{ index === 0 ? line : 'BECMG ' + line }}</div>
			</template>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		weather: Object,
	},
};
</script>
