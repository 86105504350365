import { get } from 'vuex-pathify';
import { aggregate } from '@/store/helpers/operationStatus';

export const generateMixin = (...models) => {
	if (Array.isArray(models[0])) models = models[0];
	return {
		computed: {
			operationStatus: {
				get: () => aggregate(models.map((model) => get(`crud/${model.name}/operationStatus`))),
			},
		},
	};
};
