<template>
	<div div class="divSize" @dblclick.stop="onDblClick" @click="onClick">
		<v-expansion-panels dark class="ExPanel">
			<v-expansion-panel class="ClosedExPanel">
				<v-expansion-panel-header>
					<div>
						<v-icon> mdi-earth-arrow-right </v-icon>
					</div>
					<template v-slot:actions>
						<v-icon> mdi-chevron-down</v-icon>
					</template>
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<h2>Region</h2>
					<v-radio-group
						v-model="selectedRegion"
						@change="
							$emit('region', {
								center: availableRegions[selectedRegion].position,
								zoom: availableRegions[selectedRegion].zoom,
							})
						"
					>
						<v-radio
							color="golden"
							v-for="item in Object.entries(availableRegions).map((entry) => ({ key: entry[0], ...entry[1] }))"
							:key="item.key"
							:label="item.name"
							:value="item.key"
						></v-radio>
					</v-radio-group>
					<v-row>
						<v-col cols="12" sm="6" md="6" lg="6" xl="6">
							<v-autocomplete
								:items="$services.airports.icaoCodes"
								outlined
								dense
								@update:search-input="handleInput"
								v-model="airportIcao"
								label="Vertiport"
								@keydown.enter="onEnterPressed"
							></v-autocomplete>
						</v-col>
						<v-col cols="12" sm="6" md="6" lg="6" xl="6">
							<v-btn light @click="onEnterPressed"> <v-icon>mdi-near-me</v-icon></v-btn>
						</v-col>
					</v-row>
					<!-- <v-autocomplete :items="this.$services.airports.icaoCodes" outlined dense label="Country"></v-autocomplete> -->
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</div>
</template>

<script>
import { get, sync } from 'vuex-pathify';
export default {
	name: 'fwRegionControl',
	data: () => ({
		airportIcao: '',
		tempInput: null,
	}),
	props: {
		centerControl: Object,
		zoomControl: Number,
	},

	computed: {
		...sync(`flightWatch/regions`, ['selectedRegion']),
		...get(`flightWatch/regions`, ['availableRegions']),
	},
	watch: {
		centerControl() {
			setTimeout(() => {
				if (this.airportIcao === this.tempInput && this.tempInput !== null) {
					if (
						!this.$_.isEqual(
							{
								lat: this.$services.airports.getAirportByIcaoCode(this.airportIcao).latitude.toFixed(1),
								lng: this.$services.airports.getAirportByIcaoCode(this.airportIcao).longitude.toFixed(1),
							},
							{
								lat: this.centerControl.lat.toFixed(1),
								lng: this.centerControl.lng.toFixed(1),
							}
						)
					) {
						this.airportIcao = null;
						this.tempInput = null;
					}
				}
			}, 200);
		},
		zoomControl() {
			if (this.zoomControl !== 12) {
				this.airportIcao = null;
			}
		},
	},

	methods: {
		handleInput(value) {
			//console.log('handleInput value: ', value);
			this.tempInput = value;
		},
		onEnterPressed() {
			setTimeout(() => {
				if (this.airportIcao === this.tempInput && this.tempInput !== null) {
					console.log('Enterpressed-airportIcao:', this.airportIcao);
					console.log('Enterpressed-tempInput:', this.tempInput);

					this.$emit('vertiport', {
						center: {
							latitude: this.$services.airports.getAirportByIcaoCode(this.airportIcao).latitude,
							longitude: this.$services.airports.getAirportByIcaoCode(this.airportIcao).longitude,
						},
						zoom: 12,
					});
				}
			}, 100);
		},
		//ToDo: can be deleted or for other usage
		onClick() {
			//console.log('simple Click');
		},
		onDblClick() {
			//console.log('double Click');
		},
	},
};
</script>

<style scoped>
.ExPanel {
	background-color: #363636;
	opacity: 0.8;
}

.ClosedExPanel > * {
	padding-top: 0px;
	padding-right: 10px;
	padding-bottom: 0px;
	padding-left: 10px;
}

.divSize {
	z-index: 500;
	display: flex;
	align-items: center;
	position: absolute;
	top: 10px;
	left: 50%;
}
</style>
