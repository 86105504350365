import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const options = {
	theme: {
		dark: false,
		options: {
			customProperties: true,
		},
		themes: {
			dark: {
				golden: '#FFAA00',
				//primary: '#007cd6',
				mapButton: '#363636',
				listbutton: '#363636',
				// secondary: '#d6d7d9',
				// accent: '#82B1FF',
				// error: '#FF5252',
				// info: '#2196F3',
				// success: '#4CAF50',
				// warning: '#FFC107',
			},
			light: {
				golden: '#FFAA00',
				primary: '#272727',
				listbutton: '#272727',
				mapButton: '#363636',
				// secondary: '#d6d7d9',
				// accent: '#82B1FF',
				// error: '#FF5252',
				// info: '#2196F3',
				// success: '#4CAF50',
				// warning: '#FFC107',

				//colors:
				//menu:  #363636
				//navbar: #272727
				//mainBg: #DCDCDC
				//card: #F4F4F4
				//buttonSubmit: #FFAA00
				//buttonSubmit: #FFAB00
				//textfield: #FFFFFF
				//sidenav: #434343 old
				//golden: '#FFC107',
			},
		},
	},
};

export default new Vuetify(options);
