<template>
	<v-card>
		<!-- This is a Sideboard to display the most relevant information of a calculated flgiht paln. -->
		<v-card-title class="golden--text">Flight Plan Overview</v-card-title>
		<v-card-text class="pa-4">
			<air-flight-plan-load :flightPlan="flightPlan" class="mb-4" />
			<air-flight-plan-energy-time :flightPlan="flightPlan" class="mb-4" />
			<air-flight-plan-route :flightPlan="flightPlan" class="mb-4" />
		</v-card-text>
	</v-card>
</template>
<script>
import { FlightPlan } from '@/models';
export default {
	name: 'fpResultOverview',
	props: {
		flightPlan: FlightPlan,
	},
	data: () => ({}),
	computed: {},
};
</script>
