<template>
	<v-card class="mt-4" :width="width">
		<v-form ref="loginForm" @submit.prevent="validateLogin">
			<v-card-text>
				<v-text-field
					v-model="loginModel.username"
					label="username"
					prepend-icon="mdi-account"
					:rules="this.$validationRules.required"
					hide-details="auto"
				>
				</v-text-field>
				<v-text-field
					v-model="loginModel.password"
					label="password"
					prepend-icon="mdi-key"
					:rules="this.$validationRules.required"
					hide-details="auto"
				>
				</v-text-field>
				<v-card-actions class="my-2">
					<v-btn medium :loading="loading" @click="register()">Register</v-btn>
					<v-btn medium :loading="loading" type="submit">Login</v-btn>
				</v-card-actions>
			</v-card-text>
		</v-form>
		<air-error-dialog v-model="errorDiaglogOpen" :title="loginError.title" :message="loginError.message" />
	</v-card>
</template>

<script>
export default {
	props: {
		width: Number,
	},
	data: () => ({
		loginModel: {
			username: null,
			password: null,
		},
		loading: false,
		loginError: { title: 'Login failed!', message: null },
		errorDiaglogOpen: false,
	}),
	// computed: {
	// 	overlayActive: () => !!this.error,
	// },
	methods: {
		register() {
			const login = { ...this.loginModel };
			login.passwordCheck = login.password;
			this.$backend
				.post('/user/register', login)
				.then(() => {
					this.login(login);
				})
				.catch((error) => this.showLoginError(error));
		},
		login(loginData) {
			this.$backend
				.post('/user/login', loginData || { ...this.loginModel })
				.then((result) => {
					const token = 'Bearer ' + result.data.token;
					this.$backend.defaults.headers.post['Authorization'] = token;
					localStorage.setItem('token', token);
				})
				.catch((error) => this.showLoginError(error))
				.finally(() => {
					this.loading = false;
				});
		},
		validateLogin() {
			if (this.$refs.loginForm.validate()) {
				this.loading = true;
				setTimeout(() => {
					this.login(this.form);
				}, 500);
			}
		},
		showLoginError(error) {
			this.loginError.message = error.message || error.response.data.message;
			this.errorDiaglogOpen = true;
			console.log(error);
		},
	},
};
</script>
