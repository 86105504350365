var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4","xl":"3"}},[_c('air-item-search-list',{staticClass:"my-3",attrs:{"title":"Aircraft Type Management","items":Object.values(_vm.items),"disabled":_vm.operationStatus.busy || _vm.isListDisabled,"loading":_vm.operationStatus.loading,"maxHeight":500,"searchFields":[
					'general.typeDesignator',
					'general.manufacturerCode',
					'general.modelName',
					'engine.description' ],"uploadModelName":_vm.modelName},on:{"delete":_vm.openArchiveDialog,"select":_vm.setActiveItem,"new":_vm.newItem,"uploaded":_vm.loadItems},scopedSlots:_vm._u([{key:"item-title",fn:function(ref){
				var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.general.typeDesignator))])]}},{key:"item-subtitle",fn:function(ref){
				var item = ref.item;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(item.general.manufacturerCode)+" ")]),_c('v-col',{attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(item.general.modelName)+" ")]),_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(item.general.category.charAt(0) + item.engine.engineCount + item.engine.engineType.charAt(0))+" ")])],1)]}}],null,true),model:{value:(_vm.selectedIndex),callback:function ($$v) {_vm.selectedIndex=$$v},expression:"selectedIndex"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"8","xl":"9"}},[_c('air-aircraft-type-editor',{ref:"editor",attrs:{"disabled":_vm.operationStatus.busy || _vm.isEditorDisabled},on:{"cancel":_vm.cancelEdit,"save":_vm.saveActiveItem},model:{value:(_vm.temporaryItem),callback:function ($$v) {_vm.temporaryItem=$$v},expression:"temporaryItem"}})],1)],1),_c('yes-cancel-dialog',{attrs:{"title":"Archive Aircraft Type","message":"Are your sure you want to archive the Aircraft Type?"},on:{"yes":_vm.archiveItem},model:{value:(_vm.dialogOpen),callback:function ($$v) {_vm.dialogOpen=$$v},expression:"dialogOpen"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }