<template>
	<!-- ToDo: The object passed to "selectMapObject" is only a quick & dirty solution -->
	<l-marker
		@click="selectMapObject({ type: 'AircraftState', key: aircraftState.registration })"
		:lat-lng="{ lat: aircraftState.position.latitude, lng: aircraftState.position.longitude }"
	>
		<l-icon>
			<svg-aircraft :track="aircraftState.trueTrack" :alert="Math.round(Math.random() * 10) === 3 ? true : false" />
		</l-icon>
	</l-marker>
</template>
<script>
import svgAircraft from './svgAircraft.vue';
import { mixin as selectableMapObject } from '@/mixins/selectableMapObject';
export default {
	name: 'aircraftMarker',
	components: {
		svgAircraft,
	},
	mixins: [selectableMapObject],
	props: {
		aircraftState: Object,
	},
};
</script>
