import { Position2D } from './Position2D';

export class Position3D extends Position2D {
	static get name() {
		return 'Position3D';
	}

	constructor({ alt = null, ...rest } = {}) {
		super(rest);
		this.alt = alt;
	}

	static create(data) {
		return Object.seal(new Position3D(data));
	}
}
