const data = [
	{
		code: 'RNAV',
		description: 'SPECIFICATIONS',
	},
	{
		code: 'B4',
		description: 'RNAV 5 VOR/DME',
	},
	{
		code: 'B5',
		description: 'RNAV 5 INS or IRS ',
	},
	{
		code: 'A1',
		descritption: 'RNAV 10 (RNP 10)',
	},
];

function getPBN() {
	// return Object.keys(data).map(
	// 	(key) => data[key].code + '=' + data[key].description
	// );
	return data.map((element) => element.code);
}

export default {
	getPBN,
};
