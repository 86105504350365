import { Position2D } from './Position2D';

export class Waypoint {
	static get name() {
		return 'Waypoint';
	}

	constructor({
		ident = null,
		name = null,
		type = 'fix',
		overfly = false,
		reporting = false,
		position = null,
		altitude = null,
		via = null,
		distance = null,
		accumulatedDistance = null,
		magneticTrack = null,
		ias = null,
		tas = null,
		estimatedSegmentTime = null,
		estimatedAccumulatedTime = null,
		estimatedSegmentEnergy = null,
		estimatedAccumulatedEnergy = null,
	} = {}) {
		this.ident = ident;
		this.name = name;
		this.type = type;
		this.overfly = overfly;
		this.reporting = reporting;
		this.position = Position2D.create(position);
		this.altitude = altitude;
		this.via = via;
		this.distance = distance;
		this.accumulatedDistance = accumulatedDistance;
		this.magneticTrack = magneticTrack;
		this.ias = ias;
		this.tas = tas;
		this.estimatedSegmentTime = estimatedSegmentTime;
		this.estimatedAccumulatedTime = estimatedAccumulatedTime;
		this.estimatedSegmentEnergy = estimatedSegmentEnergy;
		this.estimatedAccumulatedEnergy = estimatedAccumulatedEnergy;
	}

	static create(data) {
		return Object.seal(new Waypoint(data));
	}
}
