import { make } from 'vuex-pathify';

const state = {
	selectedTileKey: 'esriWorldImagery',
	overlays: {
		selectedKeys: [],
		options: {
			clouds: {
				opacity: 0.35,
			},
			pressure: {
				opacity: 0.35,
			},
			wind: {
				opacity: 0.35,
			},
			aip: {
				opacity: 0.75,
			},
		},
	},
	general: {
		selectedColor: '#FF0000',
	},
	flightPlans: {
		visible: true,
		route: {
			polyline: {
				color: '#6000C8',
			},
			pseudoWaypoints: {
				visible: false,
			},
			waypoints: {
				visible: true,
				color: '#222555',
				backgroundColor: '#ffffff',
				opacity: 0.8,
			},
			vertiports: {
				visible: true,
				color: '#FFFFFF',
				backgroundColor: '#ffaa00',
			},
			areaOfOperations: {
				visible: true,
				fillColor: '#3388ff',
				fillOpacity: 0.2,
				strokeWeight: 1,
				strokeColor: '#3388ff',
				radius: 30,
			},
		},
	},
	vertiports: {},
};

export const store = {
	namespaced: true,
	modules: {},
	state: () => state,
	getters: make.getters(state),
	mutations: make.mutations(state),
};
