<template>
	<v-card>
		<v-card-title>General</v-card-title>
		<v-card-text>
			<v-data-table
				:items="general"
				:headers="generalHeaders"
				dense
				disable-sort
				disable-filtering
				disable-pagination
				hide-default-footer
			>
				<template v-slot:[`item`]="{ item }">
					<tr :class="item.style">
						<td>{{ item.description }}</td>
						<td class="pr-1 text-right">{{ item.value }}</td>
						<td class="pl-0">{{ item.unit }}</td>
					</tr>
				</template>
			</v-data-table>
		</v-card-text>
	</v-card>
</template>

<script>
import { FlightPlan } from '@/models';
export default {
	name: 'AirFlightPlanGeneral',
	props: {
		flightPlan: FlightPlan,
	},
	data: () => ({
		generalHeaders: [
			{ value: 'description', text: 'General Information', align: 'start' },
			{ value: 'value', text: '', align: 'end' },
			{ value: 'unit', text: '', align: 'start' },
		],
	}),
	computed: {
		general() {
			return [
				{
					description: 'FLT',
					value: this.flightPlan.general.flightDesignator,
				},
				{
					description: 'Callsign',
					value: this.flightPlan.general.callsign,
				},
				{ description: 'Date', value: this.$services.utils.dateFormatter.formatDateUtc(this.flightPlan.general.std) },
				{
					description: 'Route',
					value: this.flightPlan.general.departure + ' - ' + this.flightPlan.general.arrival,
				},
				{
					description: 'STD',
					value: this.flightPlan.general.std,
				},
				{
					description: 'ETD',
					value: this.flightPlan.general.etd,
				},
				{
					description: 'STA',
					value: this.flightPlan.general.sta,
				},
				{
					description: 'ETA',
					value: this.flightPlan.general.eta,
				},
				{
					description: 'Aircraft',
					value: this.flightPlan.aircraft.general.registration,
				},
				{
					description: 'PAX',
					value: this.flightPlan.load.pax,
				},
				{
					description: 'Payload',
					value: this.flightPlan.load.payload,
					unit: this.$services.utils.units.mass,
				},
				{
					description: 'TOM',
					value: this.flightPlan.load.actualMass,
					unit: this.$services.utils.units.mass,
				},
			];
		},
	},
};
</script>
