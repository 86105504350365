import units from './units';
import colors from './colors';
import dateFormatter from './dateFormatter';
import snackbars from './snackbars';
import filter from './filter';

export default {
	units,
	colors,
	dateFormatter,
	snackbars,
	filter,
};
