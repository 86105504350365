import { Meta } from './Meta';

export class AircraftType {
	static get name() {
		return 'AircraftType';
	}

	constructor({ _id = undefined, meta = {}, general = {}, engine = {}, performance = {} } = {}) {
		this._id = _id;
		this.meta = Meta.create(meta);
		this.general = General.create(general);
		this.engine = Engine.create(engine);
		this.performance = Performance.create(performance);
	}

	static create(data = {}) {
		return Object.seal(new AircraftType(data));
	}

	static getStructure() {
		return {
			root: AircraftType,
			children: [General, Engine, Performance],
		};
	}
}

class General {
	constructor({
		manufacturerCode = '',
		modelName = '',
		modelVersion = '',
		typeDesignator = '',
		specialTypeDesignator = '',
		category = '',
	} = {}) {
		this.manufacturerCode = manufacturerCode;
		this.modelName = modelName;
		this.modelVersion = modelVersion;
		this.typeDesignator = typeDesignator;
		this.specialTypeDesignator = specialTypeDesignator;
		this.category = category;
	}

	static create(data) {
		return new General(data);
	}
}
class Engine {
	constructor({ manufacturerCode = '', modelNumber = '', engineType = '', engineCount = '' } = {}) {
		this.manufacturerCode = manufacturerCode;
		this.modelNumber = modelNumber;
		this.engineType = engineType;
		this.engineCount = engineCount;
	}

	static create(data) {
		return new Engine(data);
	}
}
class Performance {
	constructor({
		serviceCeiling = '',
		verticalRates = {
			climb: '',
			descent: '',
		},
		energyPerMinute = {
			taxi: '',
			takeoff: '',
			climb: '',
			cruise: '',
			descent: '',
			landing: '',
		},
		speedProfiles = {},
	} = {}) {
		this.serviceCeiling = serviceCeiling;
		this.verticalRates = verticalRates;
		this.energyPerMinute = energyPerMinute;
		this.speedProfiles = speedProfiles;
	}

	static create(data) {
		return new Performance(data);
	}
}
