<template>
	<table class="air-space">
		<tr>
			<td class="font-weight-bold text-right">STD</td>
			<td>
				{{ $services.utils.dateFormatter.formatTimeUtc(times.std) }}
			</td>
			<td v-if="times.etd" class="font-weight-bold text-right">ETD</td>
			<td v-if="times.etd">
				<div>
					{{ $services.utils.dateFormatter.formatTimeUtc(times.etd) }}
					<span class="red--text" v-if="$services.utils.dateFormatter.dayDifference(times.std, times.etd) > 0">
						{{ ' (+' + $services.utils.dateFormatter.dayDifference(times.std, times.etd) + ')' }}
					</span>
				</div>
			</td>
		</tr>
		<tr>
			<td class="font-weight-bold text-right">STA</td>
			<td>
				{{ $services.utils.dateFormatter.formatTimeUtc(times.sta) }}
			</td>
			<td v-if="times.eta" class="font-weight-bold text-right">ETA</td>
			<td v-if="times.eta">
				<div>
					{{ $services.utils.dateFormatter.formatTimeUtc(times.eta) }}
					<span class="red--text" v-if="$services.utils.dateFormatter.dayDifference(times.sta, times.eta) > 0">
						{{ ' (+' + $services.utils.dateFormatter.dayDifference(times.sta, times.eta) + ')' }}
					</span>
				</div>
			</td>
		</tr>
		<tr>
			<td colspan="100">
				<v-divider class="d-block" />
			</td>
		</tr>
		<tr>
			<td class="font-weight-bold text-right">EET</td>
			<td>
				{{
					$services.utils.dateFormatter.formatTimeUtc(
						$services.utils.dateFormatter.dateTimeDifference(times.std, times.sta)
					)
				}}
			</td>
			<td v-if="times.etd && times.eta" class="font-weight-bold text-right">EET</td>
			<td v-if="times.etd && times.eta">
				{{
					$services.utils.dateFormatter.formatTimeUtc(
						$services.utils.dateFormatter.dateTimeDifference(times.etd, times.eta)
					)
				}}
			</td>
		</tr>
	</table>
</template>
<script>
export default {
	name: 'AirFlightPlanTimes',
	props: {
		times: Object,
	},
};
</script>
<style lang="scss" scoped>
.air-space {
	border-collapse: separate;
	border-spacing: 0.5em;
}
</style>
