export const state = {
	clouds: {
		name: 'Clouds',
		url: `http://{s}.tile.openweathermap.org/map/clouds/{z}/{x}/{y}.png?appid=${process.env.VUE_APP_OPEN_WEATHER_API_KEY}`,
		attribution: 'Map data &copy; <a href="http://openweathermap.org">OpenWeatherMap</a>',
		// maxZoom: 19,
	},
	pressure: {
		name: 'Pressure',
		url: `http://{s}.tile.openweathermap.org/map/pressure/{z}/{x}/{y}.png?appid=${process.env.VUE_APP_OPEN_WEATHER_API_KEY}`,
		attribution: 'Map data &copy; <a href="http://openweathermap.org">OpenWeatherMap</a>',
		// maxZoom: 19,
	},
	wind: {
		name: 'Wind',
		url: `http://{s}.tile.openweathermap.org/map/wind/{z}/{x}/{y}.png?appid=${process.env.VUE_APP_OPEN_WEATHER_API_KEY}`,
		attribution: 'Map data &copy; <a href="http://openweathermap.org">OpenWeatherMap</a>',
		// maxZoom: 19,
	},
};
