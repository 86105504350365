import { SET_LOADING, SET_SAVING, SET_ARCHIVING, SET_DELETING, SET_ERROR } from '@/store/mutationTypes';

const generateState = () => ({
	busy: false,
	loading: false,
	loaded: false,
	saving: false,
	saved: false,
	archiving: false,
	archived: false,
	deleting: false,
	deleted: false,
});

export function aggregate(states) {
	var aggregate = generateState();
	for (const state of states) {
		for (const [key, value] of Object.entries(state)) {
			aggregate[key] = aggregate[key] || value;
		}
	}
	return aggregate;
}

export function generateModule() {
	return {
		namespaced: true,
		state: generateState,
		mutations: {
			[SET_LOADING](state, payload) {
				state.loading = payload;
				state.busy = state.loading || state.saving || state.archiving || state.deleting;
			},
			[SET_SAVING](state, payload) {
				state.saving = payload;
				state.busy = state.loading || state.saving || state.archiving || state.deleting;
			},
			[SET_ARCHIVING](state, payload) {
				state.archiving = payload;
				state.busy = state.loading || state.saving || state.archiving || state.deleting;
			},
			[SET_DELETING](state, payload) {
				state.deleting = payload;
				state.busy = state.loading || state.saving || state.archiving || state.deleting;
			},
			[SET_ERROR](state, payload) {
				state.error = payload;
			},
		},
	};
}
