<template>
	<v-col cols="12" sm="12" md="12" lg="12" xl="12">
		<v-row>
			<v-col
				v-for="(route, index) of routes.filter((item) => (!!item.icon ? true : false))"
				:key="index"
				cols="12"
				sm="6"
				md="4"
				lg="4"
				xl="4"
			>
				<router-link :to="route.path" class="text-decoration-none">
					<route-card :route="route"></route-card>
				</router-link>
			</v-col>
		</v-row>
	</v-col>
</template>
<script>
import routes from '@/router/routes';
export default {
	name: 'ServiceOverview',
	props: {},
	data: () => ({
		routes,
	}),
};
</script>
