<template>
	<g class="terminalGroup">
		<g class="terminalBackgroundLines">
			<title>terminalBackgroundLines</title>
			<g fill="none" stroke="#646567" stroke-width="1.5">
				<g stroke-linecap="round" stroke-linejoin="round">
					<line x1="51.1" x2="98.1" y1="174.25" y2="174.25" />
					<line x1="98.1" x2="51.1" y1="237.45" y2="237.45" />
					<line x1="98.1" x2="51.1" y1="300.65" y2="300.65" />
					<line x1="234.2" x2="281.2" y1="174.25" y2="174.25" />
					<line x1="281.2" x2="234.2" y1="237.45" y2="237.45" />
					<line x1="281.2" x2="234.2" y1="300.65" y2="300.65" />
					<line x1="166" x2="166" y1="55.251" y2="102.25" />
					<line x1="82" x2="115.2" y1="91.651" y2="124.95" />
					<line x1="250" x2="216.8" y1="91.651" y2="124.95" />
					<line x1="166" x2="166" y1="419.95" y2="373.05" />
					<line x1="249.8" x2="216.7" y1="383.35" y2="350.35" />
					<line x1="82.2" x2="115.4" y1="383.15" y2="350.05" />
				</g>
				<path d="m233.4 170.15a67.4 67.4 0 0 0-134.8 0v134.6a67.4 67.4 0 0 0 134.8 0z" stroke-miterlimit="10" />
			</g>
		</g>
		<g class="terminalControl" font-family="Roboto-Medium, Roboto" font-size="11px" font-weight="500">
			<title>terminalControl</title>
			<line
				x1="57.179"
				x2="275.51"
				y1="464.75"
				y2="464.75"
				fill="none"
				stroke="#646567"
				stroke-miterlimit="10"
				stroke-width="1.0103"
			/>
			<g class="reg">
				<text x="74.900475" y="443.151" fill="#b0b0b0"> Reg </text>
				<circle
					class="clickable"
					@click="setAllDisplays('registration')"
					cx="85.6"
					cy="464.45"
					r="10.6"
					fill="#bd8bb9"
				/>
			</g>
			<g class="turn">
				<text x="127.20047" y="443.151" fill="#b0b0b0"> Turn </text>
				<circle
					class="clickable"
					@click="setAllDisplays('turnaround')"
					cx="139.6"
					cy="464.45"
					r="10.6"
					fill="#97bf0d"
				/>
			</g>
			<g class="power">
				<text x="175.80048" y="443.151" fill="#b0b0b0"> Power </text>
				<circle class="clickable" @click="setAllDisplays('battery')" cx="193.6" cy="464.45" r="10.6" fill="#ff5f32" />
			</g>
			<g class="pax">
				<text x="237.20047" y="443.151" fill="#b0b0b0"> Pax </text>
				<circle class="clickable" @click="setAllDisplays('pax')" cx="247.6" cy="464.45" r="10.6" fill="#ffd664" />
			</g>
		</g>
		<g class="gates" font-family="Roboto-Medium, Roboto" font-size="12px" font-weight="500">
			<circle
				v-for="(circle, index) of progressCircles"
				:key="`backgroundCircle${index}`"
				:cx="circle.cx"
				:cy="circle.cy"
				:r="progressCircleCommons.radius"
				:fill="backgroundCircleCommons.fill"
				:stroke="backgroundCircleCommons.stroke"
				:stroke-linecap="progressCircleCommons.strokeLinecap"
				:stroke-width="backgroundCircleCommons.strokeWidth"
			/>
			<g v-for="(circle, index) of progressCircles" :key="`progressCircle${index}`">
				<circle
					v-if="gates[index]"
					:cx="circle.cx"
					:cy="circle.cy"
					:r="progressCircleCommons.radius"
					:transform="`rotate(-90 ${circle.cx} ${circle.cy})`"
					:fill="progressCircleCommons.fill"
					:stroke="displayOptions[selectedDisplayOptions[index]].color"
					:stroke-linecap="progressCircleCommons.strokeLinecap"
					:stroke-width="progressCircleCommons.strokeWidth"
					:stroke-dasharray="circumference"
					:stroke-dashoffset="progress(index)"
				/>
			</g>
			<g class="g1">
				<text x="36.000473" y="209.25101" fill="#737373">G1</text>
				<text x="72.92009" y="209.951" :fill="displayOptions[selectedDisplayOptions[0]].color">{{
					gateDisplays[0]
				}}</text>
			</g>
			<g class="g2">
				<text x="40.200474" y="132.25101" fill="#737373">G2</text>
				<text x="77.220085" y="146.351" :fill="displayOptions[selectedDisplayOptions[1]].color">{{
					gateDisplays[1]
				}}</text>
			</g>
			<g class="g3">
				<text x="108.60047" y="64.750999" fill="#737373">G3</text>
				<text x="122.72009" y="97.350998" :fill="displayOptions[selectedDisplayOptions[2]].color">{{
					gateDisplays[2]
				}}</text>
			</g>
			<g class="g4">
				<text x="209.80048" y="64.850998" fill="#737373">G4</text>
				<text x="191.72008" y="97.350998" :fill="displayOptions[selectedDisplayOptions[3]].color">{{
					gateDisplays[3]
				}}</text>
			</g>
			<g class="g5">
				<text x="275.50046" y="132.25101" fill="#737373">G5</text>
				<text x="237.40047" y="146.151" :fill="displayOptions[selectedDisplayOptions[4]].color">{{
					gateDisplays[4]
				}}</text>
			</g>
			<g class="g6">
				<text x="281.30048" y="209.451" fill="#737373">G6</text>
				<text x="241.22008" y="210.05101" :fill="displayOptions[selectedDisplayOptions[5]].color">{{
					gateDisplays[5]
				}}</text>
			</g>
			<g class="g7">
				<text x="281.30048" y="272.25101" fill="#737373">G7</text>
				<text x="241.22008" y="272.85098" :fill="displayOptions[selectedDisplayOptions[6]].color">{{
					gateDisplays[6]
				}}</text>
			</g>
			<g class="g8">
				<text x="275.70047" y="355.25101" fill="#737373">G8</text>
				<text x="236.32007" y="336.95099" :fill="displayOptions[selectedDisplayOptions[7]].color">{{
					gateDisplays[7]
				}}</text>
			</g>
			<g class="g9">
				<text x="210.30048" y="419.75098" fill="#737373">G9</text>
				<text x="191.72008" y="384.85098" :fill="displayOptions[selectedDisplayOptions[8]].color">{{
					gateDisplays[8]
				}}</text>
			</g>
			<g class="g10">
				<text x="105.70047" y="419.75101" fill="#737373">G10</text>
				<text x="122.72009" y="384.95099" :fill="displayOptions[selectedDisplayOptions[9]].color">{{
					gateDisplays[9]
				}}</text>
			</g>
			<g class="g11">
				<text x="34.900475" y="355.25101" fill="#737373">G11</text>
				<text x="77.520088" y="337.05099" :fill="displayOptions[selectedDisplayOptions[10]].color">{{
					gateDisplays[10]
				}}</text>
			</g>
			<g class="g12">
				<text x="28.300472" y="272.25101" fill="#737373">G12</text>
				<text x="72.92009" y="272.95099" :fill="displayOptions[selectedDisplayOptions[11]].color">{{
					gateDisplays[11]
				}}</text>
			</g>
		</g>
		<slot></slot>
	</g>
</template>
<script>
export default {
	name: 'svgTerminal',
	props: {
		gates: Array,
	},
	data() {
		return {
			displayOptions: {
				registration: {
					property: 'registration',
					color: '#bd8bb9',
					format: (aircraftState) => {
						const splittedRegistration = aircraftState.registration.split('-');
						if (splittedRegistration.length === 2) return splittedRegistration[1];
						else return aircraftState.registration;
					},
					progress: () => [100, 100],
				},
				battery: {
					color: '#ff5f32',
					format: (aircraftState) => (aircraftState.battery.remainingCapacity * 100).toFixed(0) + '%',
					progress: (aircraftState) => [aircraftState.battery.remainingCapacity * 100, 100],
				},
				turnaround: {
					color: '#97bf0d',
					format: (aircraftState) => {
						const minutesUntilNextStd = (new Date() - new Date(aircraftState.nextStd)) / (60 * 1000);
						return minutesUntilNextStd.toFixed(0);
					},
					progress: (aircraftState) => {
						const minutesToStd = (new Date() - new Date(aircraftState.nextStd)) / (60 * 1000);
						if (minutesToStd >= -30 && minutesToStd < 0) {
							return [Math.round(minutesToStd), 30];
						} else {
							return [30, 30];
						}
					},
				},
				pax: {
					color: '#ffd664',
					format: (aircraftState) => aircraftState.pax,
					progress: () => [100, 100],
				},
			},
			selectedDisplayOptions: new Array(this.gates.length).fill('registration'),
			progressCircleCommons: {
				strokeWidth: 2.2,
				radius: 22.1,
				strokeLinecap: 'round',
				fill: 'none',
				stroke: '#bd8bb9',
			},
			backgroundCircleCommons: {
				strokeWidth: 1.4,
				fill: '#434345',
				stroke: '#737375',
			},
			progressCircles: [
				{
					name: 'G1',
					cx: 81.8,
					cy: 205.75,
				},
				{
					name: 'G2',
					cx: 86.4,
					cy: 142.05,
				},
				{
					name: 'G3',
					cx: 131.6,
					cy: 93.351,
				},
				{
					name: 'G4',
					cx: 200.6,
					cy: 93.351,
				},
				{
					name: 'G5',
					cx: 245.5,
					cy: 142.05,
				},
				{
					name: 'G6',
					cx: 250.1,
					cy: 205.95,
				},
				{
					name: 'G7',
					cx: 250.1,
					cy: 268.75,
				},
				{
					name: 'G8',
					cx: 245.5,
					cy: 332.85,
				},
				{
					name: 'G9',
					cx: 200.6,
					cy: 380.75,
				},
				{
					name: 'G10',
					cx: 131.6,
					cy: 380.75,
				},
				{
					name: 'G11',
					cx: 86.4,
					cy: 332.85,
				},
				{
					name: 'G12',
					cx: 81.8,
					cy: 268.75,
				},
			],
		};
	},
	computed: {
		progressCircleCircumference() {
			return this.progressCircleCommons.radius * 2 * Math.PI;
		},
		circumference() {
			return `${this.progressCircleCircumference} ${this.progressCircleCircumference}`;
		},
		gateDisplays() {
			const displays = [];
			for (var i = 0; i < this.gates.length; i++) {
				const aircraftState = this.gates[i];
				if (aircraftState) {
					const selectedDisplayOption = this.selectedDisplayOptions[i];
					// console.log('selectedDisplayOption', selectedDisplayOption);
					displays[i] = this.displayOptions[selectedDisplayOption].format(aircraftState);
				} else {
					displays[i] = '';
				}
			}
			return displays;
		},
	},
	methods: {
		calculateStrokeDashOffset(value, maxValue) {
			return this.progressCircleCircumference - (value / maxValue) * this.progressCircleCircumference;
		},
		setAllDisplays(property) {
			// ToDo: does not work well with reactivity
			for (let i = 0; i < this.selectedDisplayOptions.length; i++) this.selectedDisplayOptions.splice(i, 1, property);
		},
		// eslint-disable-next-line no-unused-vars
		progress(i) {
			const aircraftState = this.gates[i];
			const selectedDisplayOption = this.selectedDisplayOptions[i];
			const progress = this.displayOptions[selectedDisplayOption].progress(aircraftState);
			return this.calculateStrokeDashOffset(progress[0], progress[1]);
		},
	},
};
</script>
<style lang="sass" scoped>
.clickable
  cursor: pointer
</style>
