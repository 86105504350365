<template>
	<v-data-table
		dense
		:items="runways"
		:headers="headers"
		:items-per-page="20"
		disable-sort
		disable-filtering
		disable-pagination
		hide-default-footer
	>
		<template
			v-for="(value, index) in ['width', 'length', 'thresholdOffset', 'overrunLength', 'markings', 'lighting']"
			v-slot:[`item.${value}`]="{ item }"
		>
			<span v-if="value === 'markings' || value === 'lighting'" :key="index">{{ item[value].join(', ') }}</span>
			<span
				v-else-if="value === 'length'"
				:key="index"
				:class="
					item[value] < 1800
						? 'red--text'
						: item[value] < 2400
						? 'orange--text'
						: item[value] > 3000
						? 'green--text'
						: ''
				"
				>{{ item[value].toFixed(0) }}m</span
			>
			<span
				v-else-if="value === 'width'"
				:key="index"
				:class="item[value] < 43 ? 'red--text' : item[value] > 47 ? 'green--text' : ''"
				>{{ item[value].toFixed(0) }}m</span
			>
			<span v-else :key="index">{{ item[value].toFixed(0) }}m</span>
		</template>
		<template v-slot:[`item.bearing`]="{ item }">
			<span>{{ item.bearing.toFixed(0) }}&deg;</span>
		</template>
		<!-- highlight non-std RW width and shorther than 2400m; add group by RW pair -->
	</v-data-table>
</template>
<script>
export default {
	props: {
		runways: Array,
	},
	data: () => ({
		headers: [
			{ value: 'ident', text: 'RW', alian: 'start' },
			{ value: 'width', text: 'Width', alian: 'start' },
			{ value: 'length', text: 'Length', alian: 'start' },
			{ value: 'thresholdOffset', text: 'THR', alian: 'start' },
			{ value: 'overrunLength', text: 'Overrun', alian: 'start' },
			{ value: 'bearing', text: 'Bearing', alian: 'start' },
			{ value: 'surface', text: 'Surface', alian: 'start' },
			{ value: 'markings', text: 'Markings', alian: 'start' },
			{ value: 'lighting', text: 'Lighting', alian: 'start' },
		],
	}),
};
</script>
