<template>
	<!-- width="475.4" height="606.51" viewbox="0 0 475.4 606.51" -->
	<svg viewBox="0 0 475.4 606.51" version="1.1" xmlns="http://www.w3.org/2000/svg">
		<svg-quickview />
		<svg-windrose
			:windDirection="getWindDirection()"
			arrowColor="#e53e16"
			textColor="#9c9e9f"
			lineColor="#9c9e9f"
			:xOffset="334.9"
			:yOffset="360.05"
		/>
		<svg-terminals :gates="vertiportState.gates">
			<svg-pads :pad1active="true" :pad2active="true" />
		</svg-terminals>
		<svg-parkings :parkings="vertiportState.parkings" />
		<svg-title fontColor="white" fontSize="16px" :title="vertiportState.icao" />
	</svg>
</template>
<script>
import svgQuickview from './svgQuickview.vue';
import svgWindrose from './svgWindrose.vue';
import svgPads from './svgPads.vue';
import svgParkings from './svgParkings.vue';
import svgTerminals from './svgTerminal.vue';
import svgTitle from './svgTitle.vue';

export default {
	name: 'vcVertiportControl',
	components: {
		svgQuickview,
		svgWindrose,
		svgTerminals,
		svgPads,
		svgParkings,
		svgTitle,
	},
	props: {
		vertiportState: Object,
	},
	methods: {
		getWindDirection() {
			const metar = this.$_.get(this.vertiport, 'weather.metar');
			if (metar) {
				const direction = this.$_.first(metar.match(/[0-9]+(KT|MPS)/i));
				if (direction) return +direction.substr(0, 3);
			}

			return Math.random() * 360;
		},
	},
};
</script>
