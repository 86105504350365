<template>
	<v-tooltip right nudge-right="-100" open-delay="1000" :disabled="!route.hotkey">
		<template v-slot:activator="{ on, attrs }">
			<v-card height="100%" class="text-center" v-bind="attrs" v-on="on">
				<v-card-subtitle class="pt-6">
					<v-icon x-large>{{ route.icon }}</v-icon>
				</v-card-subtitle>
				<v-card-title class="d-block pt-0">{{ route.name }}</v-card-title>
				<v-card-text>{{ route.description }}</v-card-text>
			</v-card>
		</template>
		<div>
			<div class="font-weight-bold">Hotkey:</div>
			<div>STRG+{{ route.hotkey }}</div>
		</div>
	</v-tooltip>
</template>
<script>
export default {
	name: 'RouterCard',
	props: { route: Object },
};
</script>
