export default {
	visibility: 'm',
	altitude: 'ft',
	verticalSpeed: 'ft/min',
	distance: 'NM',
	mass: 'kg',
	velocity: 'kt',
	passenger: 'pcs',
	energy: 'kWh',
	fuel: 'liter',
	minutes: 'min',
	hours: 'hrs',
	temperature: '°C',
	percentage: '%',
};
