<template>
	<v-dialog v-model="show" persistent :max-width="maxWidth">
		<v-card>
			<v-card-title class="headline"> {{ title }} </v-card-title>
			<v-card-text> {{ message }}</v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="green darken-1" text @click="closeAndEmit('yes')"> YES </v-btn>
				<v-btn color="green darken-1" text @click="closeAndEmit('cancel')"> Cancel </v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: 'YesCancelDialog',
	props: {
		value: Boolean,
		maxWidth: {
			type: Number,
			default: 350,
		},
		title: {
			type: String,
			default: 'Sorry, something went wrong!',
		},
		message: {
			type: String,
			default: 'There is no error, you should not see this message.',
		},
	},
	data: () => ({}),
	computed: {
		show: {
			get() {
				return this.value;
			},
			set(value) {
				// this.value = value; - funktioniert nicht, da value ein prop ist.
				// stattdessen wird der neue wert für das prop "value" durch das "input"
				// event and den parent geschickt, welcher wiederum das prop updated - dies nennt man 2-way binding
				this.$emit('input', value);
			},
		},
	},
	methods: {
		closeAndEmit(event) {
			this.$emit(event);
			this.show = false;
		},
	},
};
</script>
