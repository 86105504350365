<template>
	<v-container>
		<v-row>
			<v-col cols="12" md="7" lg="8" xl="9">
				<v-expansion-panels>
					<v-expansion-panel>
						<v-expansion-panel-header> Date Picker </v-expansion-panel-header>
						<v-expansion-panel-content>
							<v-form ref="testCard">
								<v-row class="mx-0">
									<v-col cols="12" sm="12" md="6" lg="10" xl="10">
										<air-date-time-picker v-model="dateTime"></air-date-time-picker>
										{{ this.dateTime }}
									</v-col>
								</v-row>
							</v-form>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	data: function () {
		return {
			dateTime: new Date(),
		};
	},
};
</script>
