<template>
	<v-card class="cardSearchList" :disabled="disabled || loading">
		<v-card-title>
			<span>{{ title }}</span>
			<v-spacer />
			<v-tooltip bottom>
				<template v-slot:activator="{ on, attrs }">
					<div v-bind="attrs" v-on="on">
						<air-file-upload :modelName="uploadModelName" @saved="$emit('uploaded')" accept=".csv" />
					</div>
				</template>
				<span>
					<slot name="newTooltip">Upload CSV</slot>
				</span>
			</v-tooltip>
			<v-tooltip bottom>
				<template v-slot:activator="{ on, attrs }">
					<!-- This was included in the dive below: style="width: min-content" -->
					<div v-bind="attrs" v-on="on">
						<v-btn @click="$emit('new')" fab>
							<v-icon> mdi-plus </v-icon>
						</v-btn>
					</div>
				</template>
				<span>
					<slot name="newTooltip">New</slot>
				</span>
			</v-tooltip>
		</v-card-title>

		<v-card-text>
			<v-row>
				<v-col cols="12" xs="8" sm="6" md="8">
					<v-text-field
						v-model="searchQuery"
						outlined
						dense
						label="Search"
						prepend-inner-icon="mdi-magnify"
					></v-text-field>
				</v-col>
				<v-col cols="12" xs="4" sm="6" md="4">
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<div v-bind="attrs" v-on="on" style="width: min-content">
								<v-switch
									class="my-1"
									v-model="searchType"
									true-value="AND"
									false-value="OR"
									inset
									:label="searchType"
									dense
								></v-switch>
							</div>
						</template>
						<span>AND: Single Search<br />OR: Multiple Search</span>
					</v-tooltip>
				</v-col>
			</v-row>

			<v-row v-if="loading">
				<v-col cols="12">
					<v-skeleton-loader
						type="list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
					/>
				</v-col>
			</v-row>
			<v-row v-else>
				<v-col cols="12">
					<v-list dense rounded :max-height="maxHeight" class="overflow-y-auto">
						<v-list-item-group v-model="selectedIndex">
							<v-list-item v-for="(item, index) in filteredItems" :key="index">
								<v-list-item-content @click="$emit('select', item)">
									<v-list-item-title>
										<slot name="item-title" v-bind:item="item"></slot>
									</v-list-item-title>
									<v-list-item-subtitle>
										<slot name="item-subtitle" v-bind:item="item"></slot>
									</v-list-item-subtitle>
								</v-list-item-content>
								<v-list-item-action>
									<v-btn icon @click="$emit('delete', item)">
										<v-icon>mdi-delete</v-icon>
									</v-btn>
								</v-list-item-action>
							</v-list-item>
							<v-divider></v-divider>
						</v-list-item-group>
					</v-list>
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>
<script>
import _ from 'lodash';
export default {
	name: 'airItemSearchList',
	props: {
		value: Number,
		items: Array,
		searchFields: Array,
		title: String,
		disabled: Boolean,
		loading: Boolean,
		maxHeight: Number,
		uploadModelName: String,
	},
	data: () => ({
		searchQuery: null,
		searchType: 'AND',
	}),
	computed: {
		selectedIndex: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
		filteredItems() {
			if (this.searchQuery) {
				var filtered = [];

				for (let item of this.items) {
					let matches;
					if (this.searchType === 'OR') matches = false;
					else if (this.searchType === 'AND') matches = true;
					else throw new Error('not implemented');

					for (let keyword of this.searchQuery.toLowerCase().trim().split(' ')) {
						if (this.searchType === 'OR') {
							matches = matches || this.searchInObject(item, this.searchFields, keyword);
						}
						if (this.searchType === 'AND') {
							matches = matches && this.searchInObject(item, this.searchFields, keyword);
						}
					}

					if (matches) filtered.push(item);
				}
				return filtered;
			} else {
				return this.items;
			}
		},
	},
	methods: {
		/**
		 * This function provides a deep search for a keyword within certain properties of an object.
		 * @param item An object which should be searched.
		 * @param searchProperties An array(type string) of properties which are check if they contain the keyword.
		 * @param keyword The string which is searched for.
		 */
		searchInObject(item, searchProperties, keyword) {
			var aggregate = false;

			for (const field of searchProperties) {
				const currentProperty = _.get(item, field);
				aggregate = aggregate || this.$_.lowerCase(currentProperty).includes(keyword);
			}
			return aggregate;
		},
	},
};
</script>
<style scoped>
.cardSearchList {
	/* height: 80%; */
	position: fixed;
}
</style>
