const data = {
	error: {
		color: '',
		icon: 'mdi-close-circle',
		mode: 'multi-line',
		position: 'bottom',
		title: 'ERROR',
		text: '',
		visible: true,
		timeout: 4000,
	},
	info: {
		color: 'info',
		icon: 'mdi-information-outline',
		mode: 'multi-line',
		position: 'center',
		title: 'Information',
		text: '',
		visible: true,
		timeout: 4000,
	},
	saved: {
		color: '',
		icon: 'mdi-database-check',
		mode: 'multi-line',
		position: 'bottom',
		title: 'SAVED',
		text: 'Saved',
		visible: true,
		timeout: 4000,
	},
	success: {
		color: '',
		icon: 'mdi-check-circle',
		mode: 'multi-line',
		position: 'bottom',
		title: 'SUCCESS',
		text: '',
		visible: true,
		timeout: 4000,
	},
	archived: {
		color: '',
		icon: 'mdi-check-circle',
		mode: 'multi-line',
		position: 'bottom',
		title: 'ARCHIVED',
		text: '',
		visible: true,
		timeout: 4000,
	},
};

function getSnackbar(type) {
	return data[type];
}

export default {
	getSnackbar,
};
