export const SET_ITEMS = `SET_ITEMS`;
export const ADD_ITEMS = `ADD_ITEMS`;
export const REMOVE_ITEMS = `REMOVE_ITEMS`;
export const REMOVE_INDEXES = `REMOVE_INDEXES`;
export const ERROR = `ERROR`;
export const SUCCESS = `SUCCESS`;
export const SET_LOADING = `SET_LOADING`;
export const SET_SAVING = `SET_SAVING`;
export const SET_ARCHIVING = `SET_ARCHIVING`;
export const SET_DELETING = `SET_DELETING`;
export const SET_ERROR = `SET_ERROR`;
export const REMOVE_KEYS = `REMOVE_KEYS`;
export const SAVE_ITEMS = `SAVE_ITEMS`;
export const READ = `READ`;
