import backend from './backend';
import airports from './airports';
import utils from './utils';
import flights from './flights';
import flightPlans from './flightPlans';
import aircraftTypes from './aircraftTypes';
import aircrafts from './aircrafts';

export default {
	backend,
	airports,
	utils,
	flights,
	flightPlans,
	aircraftTypes,
	aircrafts,
};
