import { call, get } from 'vuex-pathify';
import { LOAD, SAVE, ARCHIVE } from '@/store/actionTypes';
import { camelCase, cloneDeep } from 'lodash';

export const generateMixin = (mainModel, ...otherModels) => {
	var itemsGetters = {
		items: get(`crud/${mainModel.name}/items`),
	};
	for (const model of otherModels) {
		itemsGetters = {
			...itemsGetters,
			[`${camelCase(model.name)}Items`]: get(`crud/${model.name}/items`),
		};
	}
	return {
		data: () => ({
			dialogOpen: false,
			isEditorDisabled: true,
			isListDisabled: false,
			selectedIndex: -1,
			temporaryItem: mainModel.create(),
			itemMarkedToDelete: null,
			modelName: mainModel.name,
		}),
		computed: {
			...itemsGetters,
		},
		created() {
			this.selectedIndex = -1;
			this.loadItems([mainModel.name, ...otherModels.map((m) => m.name)]);
		},
		methods: {
			...call(`crud`, {
				loadItems: LOAD,
			}),
			...call(`crud/${mainModel.name}`, {
				saveItem: SAVE,
				archiveInDatabase: ARCHIVE,
			}),
			setActiveItem(item) {
				this.temporaryItem = cloneDeep(item);
				this.isEditorDisabled = false;
			},
			newItem() {
				this.emptyTemporaryItem();
				this.isEditorDisabled = false;
				this.isListDisabled = true;
				this.$refs.editor.resetValidation();
				this.selectedIndex = -1;
			},
			saveActiveItem() {
				this.saveItem(this.temporaryItem).then(() => {
					this.cancelEdit();
					this.$refs.editor.resetValidation();
				});
			},
			emptyTemporaryItem() {
				this.temporaryItem = cloneDeep(mainModel.create());
			},
			cancelEdit() {
				this.emptyTemporaryItem();
				this.isEditorDisabled = true;
				this.isListDisabled = false;
				this.selectedIndex = -1;
			},
			openArchiveDialog(item) {
				this.itemMarkedToDelete = item;
				this.dialogOpen = true;
			},
			archiveItem() {
				this.archiveInDatabase(this.itemMarkedToDelete).finally(() => {
					this.itemMarkedToDelete = null;
					this.selectedIndex = -1;
				});
			},
		},
	};
};
