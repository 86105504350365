<template>
	<l-feature-group ref="aircraftLayer" v-if="visible">
		<fw-aircraft-state
			v-for="(aircraftState, iaco24) of Object.values(items).filter((item) => item && !item.isOnGround)"
			:key="iaco24"
			:aircraftState="aircraftState"
		/>
	</l-feature-group>
</template>
<script>
import { get } from 'vuex-pathify';
import fwAircraftState from '@/componentsViews/flightWatch/components/aircraftState/aircraftState.vue';

export default {
	name: 'fwAircraftLayer',
	components: {
		fwAircraftState,
	},
	props: {
		visible: Boolean,
	},
	computed: {
		...get(`flightWatch/aircraftStates`, ['items', 'lastUpdated']),
	},
};
</script>
