import { make } from 'vuex-pathify';

const state = {
	selectedMapObject: null,
	unselectMapObjectHandle: () => {
		return;
	},
};

export const store = {
	namespaced: true,
	state: () => state,
	modules: {},
	getters: make.getters(state),
	mutations: make.mutations(state),
	actions: {},
};
