<template>
	<v-card class="mx-auto">
		<v-card-title class="subheading font-weight-bold">
			<span>Flight Plan</span>
			<v-spacer></v-spacer>
			<span class="golden--text">{{ value.general.flightDesignator }}</span>
		</v-card-title>
		<v-card-subtitle>
			<v-divider></v-divider>
		</v-card-subtitle>
		<v-card-text>
			<air-flight-plan-general class="mb-2" :flightPlan="value" />
			<air-flight-plan-load class="mb-2" :flightPlan="value" />
			<air-flight-plan-energy-time class="mb-2" :flightPlan="value" />
			<air-flight-plan-route class="mb-2" :flightPlan="value" />
		</v-card-text>
	</v-card>
</template>
<script>
import AirFlightPlanGeneral from '@/componentsViews/operationalFlightPlan/AirFlightPlanGeneral.vue';
// import { Waypoint } from '@/models';
export default {
	name: 'FlightWatchMapObjectDetails',
	components: { AirFlightPlanGeneral },
	props: {
		value: Object,
	},
	methods: {
		formatPosition(type, value) {
			var out;
			if (type === 'latitude') {
				out = value >= 0 ? 'N' : 'S';
			} else if (type === 'longitude') {
				out = value >= 0 ? 'E' : 'W';
			}
			out += Math.abs(value).toFixed(4) + '°';
			return out;
		},
	},
};
</script>
<style lang="scss" scoped>
.noListStyle {
	list-style-type: none;
}
</style>
