import { Meta } from './Meta';

export class Aircraft {
	static get name() {
		return 'Aircraft';
	}

	constructor({
		_id = undefined,
		meta = {},
		references = {},
		general = {},
		battery = {},
		passengers = '',
		masses = {},
		massAndBalance = {},
		equipment = {},
	} = {}) {
		this._id = _id;
		this.meta = Meta.create(meta);
		this.references = References.create(references);
		this.general = General.create(general);
		this.battery = Battery.create(battery);
		this.passengers = passengers;
		this.masses = Masses.create(masses);
		this.massAndBalance = MassAndBalance.create(massAndBalance);
		this.equipment = Equipment.create(equipment);
	}

	static create(data) {
		return Object.freeze(new Aircraft(data));
	}
}

class References {
	constructor({ aircraftType = null } = {}) {
		this.aircraftType = aircraftType;
	}

	static create(data) {
		return Object.freeze(new References(data));
	}
}

class General {
	constructor({
		registration = '',
		icao24 = '',
		msn = '',
		homebase = '',
		primaryColor = '',
		secondaryColor = '',
	} = {}) {
		this.registration = registration;
		this.icao24 = icao24;
		this.msn = msn;
		this.homebase = homebase;
		this.primaryColor = primaryColor;
		this.secondaryColor = secondaryColor;
	}

	static create(data) {
		return Object.freeze(new General(data));
	}
}

class Battery {
	constructor({
		manufacturer = '',
		modelName = '',
		capacity = '',
		bias = '',
		minTemperature = '',
		maxTemperature = '',
	} = {}) {
		this.manufacturer = manufacturer;
		this.modelName = modelName;
		this.capacity = capacity;
		this.bias = bias;
		this.minTemperature = minTemperature;
		this.maxTemperature = maxTemperature;
	}

	static create(data) {
		return Object.freeze(new Battery(data));
	}
}

class Masses {
	constructor({ emptyMass = null, maxMass = null } = {}) {
		this.emptyMass = emptyMass;
		this.maxMass = maxMass;
	}

	static create(data) {
		return Object.freeze(new Masses(data));
	}
}

class MassAndBalance {
	constructor() {}

	static create(data) {
		return Object.freeze(new Masses(data));
	}
}

class Equipment {
	constructor({ equipment = [], surveillance = [], pbn = [] } = {}) {
		this.equipment = equipment;
		this.surveillance = surveillance;
		this.pbn = pbn;
	}

	static create(data) {
		return Object.freeze(new Masses(data));
	}
}
