<template>
	<div>
		<l-control v-bind="$attrs">
			<v-row no-gutters>
				<v-col cols="12">
					<v-btn dark color="mapButton" @click="zoom++">
						<v-icon> mdi-plus </v-icon>
					</v-btn>
				</v-col>
				<v-col cols="12">
					<v-btn dark color="mapButton" @click="zoom--"> <v-icon> mdi-minus </v-icon></v-btn>
				</v-col>
			</v-row>
		</l-control>
	</div>
</template>

<script>
// import { get, sync } from 'vuex-pathify';
export default {
	name: 'fwZoomControl',
	props: {
		value: Number,
	},
	computed: {
		// ...sync(`flightWatch/regions`, ['selectedRegion']),
		// ...get(`flightWatch/regions`, ['availableRegions']),

		zoom: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
};
</script>

<style scoped>
.v-btn:not(.v-btn--round).v-size--default {
	opacity: 0.8;
	margin-bottom: 1%;
	margin-left: 30%;
	min-width: 36px;
	width: 36px;
	height: 36px;
	border-radius: 4px;
}
</style>
