<template>
	<div>
		<v-menu :close-on-content-click="false" offset-x>
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					v-bind="attrs"
					v-on="on"
					dark
					color="mapButton"
					large
					class="regionButton"
					@dblclick.stop="onDblClick"
					@click="onClick"
				>
					<v-icon> mdi-earth-arrow-right </v-icon>
				</v-btn>
			</template>
			<v-card dark class="regionCard" light color="mapButton">
				<v-card-text>
					<v-col cols="12" sm="12" md="12" lg="12" xl="12">
						<v-card-subtitle><h2>Regions</h2></v-card-subtitle>

						<v-radio-group v-model="selectedRegion">
							<v-radio
								color="golden"
								v-for="item in Object.entries(availableRegions).map((entry) => ({ key: entry[0], ...entry[1] }))"
								:key="item.key"
								:label="item.name"
								:value="item.key"
							></v-radio>
						</v-radio-group>
					</v-col>
				</v-card-text>
			</v-card>
		</v-menu>
	</div>
</template>

<script>
import { get, sync } from 'vuex-pathify';
export default {
	name: 'fwRegionControl',
	data: () => ({}),
	props: {
		value: Object,
	},
	computed: {
		...sync(`flightWatch/regions`, ['selectedRegion']),
		...get(`flightWatch/regions`, ['availableRegions']),
	},
	methods: {
		//ToDo: can be deleted or for other usage
		onClick() {
			//console.log('simple Click');
		},
		onDblClick() {
			//console.log('double Click');
		},
	},
};
</script>

<style scoped>
.regionButton {
	display: flex;
	align-items: center;
	position: absolute;
	bottom: 1%;
	right: 48%;
	border-radius: 4px;
	opacity: 0.8;
	z-index: 500;
}
.regionCard {
	border-radius: 4px;
	opacity: 0.8;
	margin-left: 2%;
	bottom: 0%;
	padding: 5px;
}
</style>
