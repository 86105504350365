<template>
	<div id="content">
		<fw-map style="height: 100%" />

		<fw-sideboard />
	</div>
</template>

<script>
import fwMap from '../components/fwMap.vue';
import fwSideboard from './fwSideboard.vue';
export default {
	name: `FlightWatch`,
	components: { fwMap, fwSideboard },
	data() {
		return {};
	},
};
</script>

<style scoped>
#content {
	width: 100%;
	height: 100%;
}
</style>
