import { ADD, READ } from '@/store/mutationTypes';

const state = {
	items: [],
};

const mutations = {
	[READ]: (state) => state.items.shift(),
	[ADD]: (state, payload) => {
		state.items.push(payload);
		// ToDo: Also write into a logger here.
	},
};

export const store = {
	namespaced: true,
	state: () => state,
	mutations,
};
