<template>
	<v-form :disabled="isFormDisabled" ref="form">
		<v-card class="my-6">
			<v-card-subtitle>General Data</v-card-subtitle>
			<v-card-text>
				<v-row>
					<v-col cols="12" sm="6" md="6" lg="4" xl="4">
						<v-text-field
							v-model="currentFlightPlan.general.flightDesignator"
							outlined
							dense
							label="Flight Designator"
							disabled
							:rules="[$validationRules.required]"
						></v-text-field>
					</v-col>
					<v-col cols="12" sm="6" md="6" lg="4" xl="4">
						<v-text-field
							v-model="currentFlightPlan.general.callsign"
							outlined
							dense
							label="Call Sign"
							disabled
							:rules="[$validationRules.required]"
						></v-text-field>
					</v-col>
					<v-col cols="12" sm="6" md="6" lg="4" xl="4">
						<v-autocomplete
							:items="aircrafts"
							v-model="currentFlightPlan.aircraft"
							outlined
							dense
							label="Aircraft"
							:rules="[$validationRules.required]"
							item-text="general.registration"
							return-object
						>
						</v-autocomplete>
					</v-col>
					<v-col cols="12" sm="6" md="6" lg="4" xl="4">
						<v-autocomplete
							:items="this.$services.airports.icaoCodes"
							v-model="currentFlightPlan.general.departure"
							outlined
							dense
							label="Departure"
							disabled
							:rules="[$validationRules.required]"
						></v-autocomplete>
					</v-col>
					<v-col cols="12" sm="6" md="6" lg="4" xl="4">
						<v-text-field v-model="currentFlightPlan.general.std" outlined dense label="STD" disabled></v-text-field>
					</v-col>
					<v-col cols="12" sm="6" md="6" lg="4" xl="4">
						<v-text-field
							v-model="currentFlightPlan.general.etd"
							outlined
							dense
							label="ETD (STD)"
							placeholder="default STD"
							:rules="[$validationRules.required]"
						></v-text-field>
					</v-col>
					<v-col cols="12" sm="6" md="6" lg="4" xl="4">
						<v-autocomplete
							:items="this.$services.airports.icaoCodes"
							v-model="currentFlightPlan.general.arrival"
							outlined
							dense
							label="Arrival"
							disabled
							:rules="[$validationRules.required]"
						></v-autocomplete>
					</v-col>
					<v-col cols="12" sm="6" md="6" lg="4" xl="4">
						<v-text-field v-model="currentFlightPlan.general.sta" outlined dense label="STA" disabled></v-text-field>
					</v-col>
					<v-col cols="12" sm="6" md="6" lg="4" xl="4">
						<v-text-field
							v-model="currentFlightPlan.general.eta"
							outlined
							dense
							label="ETA (STA)"
							placeholder="default STA"
							:rules="[$validationRules.required]"
						></v-text-field>
					</v-col>
					<v-col cols="12" sm="6" md="6" lg="4" xl="4">
						<v-autocomplete
							:items="this.$services.flightPlans.options.flightRules.getRules()"
							v-model="currentFlightPlan.general.flightRule"
							outlined
							dense
							label="Flight Rules"
							:rules="[$validationRules.required]"
						></v-autocomplete>
					</v-col>
					<v-col cols="12" sm="6" md="6" lg="4" xl="4">
						<v-autocomplete
							:items="this.$services.flightPlans.options.typeOfFlight.getTypes()"
							v-model="currentFlightPlan.general.typeOfFlight"
							outlined
							dense
							label="Type of Flight"
							:rules="[$validationRules.required]"
						></v-autocomplete>
					</v-col>
					<v-col cols="12" sm="6" md="6" lg="4" xl="4">
						<v-combobox
							v-model="currentFlightPlan.general.sts"
							:items="this.$services.flightPlans.options.sts.getSTS()"
							label="STS"
							multiple
							outlined
							dense
							small-chips
						></v-combobox>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" sm="12" md="12" lg="12" xl="12">
						<v-textarea
							v-model="currentFlightPlan.general.atcRemarks"
							label="ATC Remarks"
							name="textarea-atcRemarks"
							outlined
							dense
							auto-grow
							rows="2"
						></v-textarea>
					</v-col>
					<v-col cols="12" sm="12" md="12" lg="12" xl="12">
						<v-textarea
							v-model="currentFlightPlan.general.dispatchNotes"
							label="Dispatch Notes"
							name="textarea-dispatchNotes"
							outlined
							dense
							auto-grow
							rows="2"
						></v-textarea>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

		<v-card class="my-6">
			<v-card-subtitle>Equipment</v-card-subtitle>
			<v-card-text>
				<v-row>
					<v-col cols="12" sm="6" md="6" lg="4" xl="4">
						<v-combobox
							v-model="currentFlightPlan.other.equipment"
							:items="this.$services.aircrafts.options.equipment.getEquipment()"
							label="Equipment"
							multiple
							outlined
							dense
							small-chips
						></v-combobox>
					</v-col>
					<v-col cols="12" sm="6" md="6" lg="4" xl="4">
						<v-combobox
							v-model="currentFlightPlan.other.ssr"
							:items="this.$services.aircrafts.options.surveillance.getSurveillance()"
							label="SSR"
							multiple
							outlined
							dense
							small-chips
						></v-combobox>
					</v-col>
					<v-col cols="12" sm="6" md="6" lg="4" xl="4">
						<v-combobox
							v-model="currentFlightPlan.other.pbn"
							:items="this.$services.aircrafts.options.pbn.getPBN()"
							label="PBN"
							multiple
							outlined
							dense
							small-chips
						></v-combobox>
					</v-col>
					<!-- <v-col cols="12" sm="6" md="3" lg="2" xl="2">
								<air-date-time-picker v-model="testDate"></air-date-time-picker>
								<v-btn @click="showValidEquipment">validate form</v-btn>
							</v-col> -->
				</v-row>
			</v-card-text>
		</v-card>

		<v-card class="my-6">
			<v-card-subtitle>Flight Route</v-card-subtitle>
			<v-card-text>
				<v-container>
					<v-row>
						<v-col cols="12" sm="6" md="6" lg="3" xl="2">
							<v-autocomplete
								:items="['Auto', 'Manual']"
								v-model="currentAutoRoute"
								outlined
								dense
								label="Auto/Manual"
								:rules="[$validationRules.required]"
							></v-autocomplete>
						</v-col>
						<v-col cols="12" sm="6" md="6" lg="3" xl="2">
							<v-text-field
								v-model="currentFlightPlan.routingOptions.maxAltitude"
								outlined
								dense
								label="MAX Flight Level"
								:rules="[$validationRules.required]"
							></v-text-field>
						</v-col>
						<v-col cols="12" sm="6" md="6" lg="3" xl="2">
							<!-- <v-btn-toggle v-model="toggleGroup" group>
									<v-btn value="yes" class="my-0"> YES </v-btn>
									<v-btn value="no" class="my-0"> NO </v-btn>
								</v-btn-toggle> -->
							<v-autocomplete
								:items="['yes', 'no']"
								v-model="currentFlightPlan.routingOptions.sharpTurnAvoidance"
								outlined
								dense
								label="Sharp Turn Avoidance"
								:rules="[$validationRules.required]"
							></v-autocomplete>
						</v-col>
						<v-col cols="12" sm="6" md="6" lg="3" xl="2">
							<v-checkbox
								v-model="currentFlightPlan.routingOptions.lowerAirways"
								class="my-0"
								hide-details
								dense
								label="use AWYLO"
							></v-checkbox>
							<v-checkbox
								v-model="currentFlightPlan.routingOptions.upperAirways"
								class="my-0"
								dense
								hide-details
								label="use AWYHI"
							></v-checkbox>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<v-textarea
								v-model="currentFlightPlan.route.routeString"
								label="Route"
								name="textarea-remarks"
								outlined
								dense
								auto-grow
								rows="2"
								:disabled="currentFlightPlan.routingOptions.autoRoute"
							></v-textarea>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" md="6">
							<v-textarea
								v-model="currentFlightPlan.routingOptions.routeVia"
								label="Route via"
								outlined
								dense
								auto-grow
								rows="1"
								disabled
							/>
						</v-col>
						<v-col cols="12" md="6">
							<v-textarea
								v-model="currentFlightPlan.routingOptions.avoid"
								label="Avoid"
								outlined
								dense
								auto-grow
								rows="1"
								disabled
							/>
						</v-col>
					</v-row>
				</v-container>
			</v-card-text>
		</v-card>

		<v-card class="my-6">
			<v-card-subtitle>Speed</v-card-subtitle>
			<v-card-text>
				<v-row>
					<v-col cols="12" sm="6" md="6" lg="3" xl="2">
						<v-autocomplete :items="['Profile 1']" outlined dense disabled label="Profile"></v-autocomplete>
					</v-col>
					<v-col cols="12" sm="6" md="6" lg="3" xl="2">
						<v-text-field
							v-model="currentFlightPlan.speeds.climb.ias"
							outlined
							dense
							label="Climb"
							placeholder=""
							:suffix="this.$services.utils.units.velocity"
							:rules="[$validationRules.required]"
						></v-text-field>
					</v-col>
					<v-col cols="12" sm="6" md="6" lg="3" xl="2">
						<v-text-field
							v-model="currentFlightPlan.speeds.cruise.ias"
							outlined
							dense
							label="Cruise"
							placeholder=""
							:suffix="this.$services.utils.units.velocity"
							:rules="[$validationRules.required]"
						></v-text-field>
					</v-col>
					<v-col cols="12" sm="6" md="6" lg="3" xl="2">
						<v-text-field
							v-model="currentFlightPlan.speeds.descent.ias"
							outlined
							dense
							label="Descent"
							placeholder=""
							:suffix="this.$services.utils.units.velocity"
							:rules="[$validationRules.required]"
						></v-text-field>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

		<v-card class="my-6">
			<v-card-subtitle>Energy Planning</v-card-subtitle>
			<v-card-text>
				<v-row>
					<v-col cols="12" sm="6" md="6" lg="3" xl="2">
						<v-text-field
							v-model="currentFlightPlan.endurance.time.taxi"
							outlined
							dense
							label="Taxi Time"
							placeholder=""
							:suffix="this.$services.utils.units.minutes"
							:rules="[$validationRules.required]"
						></v-text-field>
					</v-col>
					<v-col cols="12" sm="6" md="6" lg="3" xl="2">
						<v-text-field
							v-model="currentFlightPlan.endurance.time.contingency"
							outlined
							dense
							label="Contingency Time"
							placeholder=""
							:suffix="this.$services.utils.units.minutes"
							:rules="[$validationRules.required]"
						></v-text-field>
					</v-col>
					<v-col cols="12" sm="6" md="6" lg="3" xl="2">
						<v-text-field
							v-model="currentFlightPlan.endurance.time.reserve"
							outlined
							dense
							label="Reserve Time"
							placeholder=""
							:suffix="this.$services.utils.units.minutes"
							:rules="[$validationRules.required]"
						></v-text-field>
					</v-col>
					<v-col cols="12" sm="6" md="6" lg="3" xl="2">
						<v-text-field
							v-model="currentFlightPlan.endurance.time.additional"
							outlined
							dense
							label="Additional Time"
							placeholder=""
							:suffix="this.$services.utils.units.minutes"
							:rules="[$validationRules.required]"
						></v-text-field>
					</v-col>
					<v-col cols="12" sm="6" md="6" lg="3" xl="2">
						<v-text-field
							v-model="currentFlightPlan.endurance.time.extra"
							outlined
							dense
							label="Extra Time"
							placeholder=""
							:suffix="this.$services.utils.units.minutes"
							:rules="[$validationRules.required]"
						></v-text-field>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

		<v-card class="my-6">
			<v-card-subtitle>Load</v-card-subtitle>
			<v-card-text>
				<v-row>
					<v-col cols="12" sm="6" md="6" lg="3" xl="2">
						<v-text-field v-model="currentFlightPlan.load.pax" label="PAX" outlined dense></v-text-field>
					</v-col>
					<v-col cols="12" sm="6" md="6" lg="3" xl="2">
						<v-text-field
							v-model="currentFlightPlan.load.paxMass"
							label="PAX Mass"
							outlined
							dense
							:suffix="this.$services.utils.units.mass"
							:rules="[$validationRules.required]"
						></v-text-field>
					</v-col>
					<v-col cols="12" sm="6" md="6" lg="3" xl="2">
						<v-text-field
							v-model="currentFlightPlan.load.baggage"
							label="Baggage"
							outlined
							dense
							:suffix="this.$services.utils.units.mass"
							:rules="[$validationRules.required]"
						></v-text-field>
					</v-col>
					<v-col cols="12" sm="6" md="6" lg="3" xl="2">
						<v-text-field
							v-model="currentFlightPlan.load.cargo"
							outlined
							dense
							label="Cargo"
							placeholder=""
							:suffix="this.$services.utils.units.mass"
							:rules="[$validationRules.required]"
						></v-text-field>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</v-form>
</template>

<script>
export default {
	name: 'AirFlightPlanEditor',
	props: {
		value: Object,
		aircrafts: Array,
	},
	data: () => ({}),
	computed: {
		isFormDisabled() {
			return this.currentFlightPlan && !!this.currentFlightPlan._id;
		},
		currentFlightPlan: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
		currentAutoRoute: {
			get() {
				return this.currentFlightPlan.routingOptions.autoRoute ? 'Auto' : 'Manual';
			},
			set(selection) {
				this.currentFlightPlan.routingOptions.autoRoute = selection === 'Auto' ? true : false;
			},
		},
	},
};
</script>
