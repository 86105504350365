import { camelCase } from 'lodash';
import { get } from 'vuex-pathify';

export const generateMixin = (...models) => {
	if (Array.isArray(models[0])) models = models[0];

	const computed = {};
	for (var i = 0; i < models.length; i++) {
		if (typeof models[i] === 'function') {
			if (models[i].name) {
				models[i] = models[i].name;
			} else {
				throw new Error(`Unsupported model: ${models[i]}`);
			}
		}
		computed[`${camelCase(models[i])}Store`] = get(`crud/${models[i]}/items`);
	}

	return {
		computed,
	};
};
