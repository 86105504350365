<template>
	<v-card>
		<v-card-title>
			<span>Dispatch List</span>
			<v-spacer />
			<air-file-upload :modelName="uploadModelName" @saved="load()" accept=".csv" />
		</v-card-title>
		<v-card-text>
			<dispatch-list></dispatch-list>
		</v-card-text>
	</v-card>
</template>

<script>
import dispatchList from '../components/airDispatchList.vue';
import airFileUpload from '@/componentsViews/base/components/AirFileUpload.vue';
import { Flight, FlightPlan } from '@/models';
import { LOAD } from '@/store/actionTypes';

export default {
	name: 'DispatchView',
	components: { dispatchList, airFileUpload },
	data: function () {
		return {
			uploadModelName: Flight.name,
		};
	},
	methods: {
		load() {
			this.$store.dispatch(`crud/${LOAD}`, [Flight.name, FlightPlan.name]);
		},
	},
};
</script>
