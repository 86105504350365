import { Meta } from '.';
import { FlightPlan } from '.';
import _ from 'lodash';

export class Flight {
	static get name() {
		return 'Flight';
	}

	constructor({
		_id = undefined,
		meta = {},
		mock = {},
		references = {},
		flightDesignator = null,
		callsign = null,
		departure = null,
		arrival = null,
		std = null,
		sta = null,
		plannedAircraftRegistration = null,
	} = {}) {
		this._id = _id;
		this.meta = Meta.create(meta);
		this.mock = mock;
		this.references = References.create(references);
		this.flightDesignator = flightDesignator;
		this.callsign = callsign;
		this.departure = departure;
		this.arrival = arrival;
		this.std = std;
		this.sta = sta;
		this.plannedAircraftRegistration = plannedAircraftRegistration;
	}

	static create(data = {}) {
		// ToDo: flightPlans subobject initializing
		return Object.seal(new Flight(data));
	}

	getFlightPlanDraft(aircrafts) {
		const foundAircraft = aircrafts.filter((item) => item.general.registration === this.plannedAircraftRegistration)[0];
		if (!foundAircraft)
			throw new Error(`No aircraft assigned for Flight ${this.flightDesignator} with STD ${this.std}`);

		const rawFlightPlan = _.merge({}, this.mock, {
			references: {
				flight: this._id,
			},
			aircraft: foundAircraft,
			general: {
				flightDesignator: this.flightDesignator,
				callsign: this.callsign,
				departure: this.departure,
				arrival: this.arrival,
				std: this.std,
				sta: this.sta,
				etd: this.std,
				eta: this.sta,
			},
		});
		return FlightPlan.create(rawFlightPlan);
	}
}

class References {
	constructor({ activeFlightPlan = null, flightPlans = [] } = {}) {
		this.activeFlightPlan = activeFlightPlan;
		this.flightPlans = flightPlans;
	}

	static create(data) {
		return Object.freeze(new References(data));
	}
}
