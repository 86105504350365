import { Waypoint } from './Waypoint';

export class WaypointConstrainted extends Waypoint {
	static get name() {
		return 'WaypointConstrainted';
	}

	constructor({ maxSpeed = null, minSpeed = null, maxAltitude = null, minAltitude = null, ...rest } = {}) {
		super(rest);
		this.maxSpeed = maxSpeed;
		this.minSpeed = minSpeed;
		this.maxAltitude = maxAltitude;
		this.minAltitude = minAltitude;
	}

	static create(data) {
		return Object.seal(new WaypointConstrainted(data));
	}
}
