const data = [
	{
		code: 'N',
		description: 'NIL',
	},
	{
		code: 'S',
		description: '(VOR, VHF RTF, ILS)',
	},
	{
		code: 'A',
		description: 'GBAS Landing System',
	},
	{
		code: 'B',
		description: 'LPV (APV with SBAS)',
	},
	{
		code: 'C',
		description: 'LORAN C',
	},
	{
		code: 'D',
		description: 'DME',
	},
	{
		code: 'E1',
		description: 'FMC WPR ACARS',
	},
];

function getEquipment() {
	// return Object.keys(data).map(
	// 	(key) => data[key].code + '=' + data[key].description
	// );
	return data.map((element) => element.code);
}

export default {
	getEquipment,
};
