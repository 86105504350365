<template>
	<v-snackbar
		v-model="snackbar.visible"
		:timeout="snackbar.timeout"
		:color="snackbar.color"
		:position="snackbar.position"
	>
		<v-layout>
			<v-icon large>{{ snackbar.icon }}</v-icon>
			<v-layout class="pl-4" column>
				<div>
					<strong>{{ snackbar.title }}</strong>
				</div>
				<div>{{ snackbar.text }}</div>
			</v-layout>
		</v-layout>
	</v-snackbar>
</template>
<script>
export default {
	name: 'airSnackbar',
	computed: {},
};
</script>
