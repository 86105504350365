import { make } from 'vuex-pathify';
import { LOAD } from '@/store/actionTypes';
import { SET_ITEMS } from '@/store/mutationTypes';
import backend from '@/services/backend';

const state = {
	items: {},
	lastUpdated: null,
};

const mutations = {
	[SET_ITEMS]: function (state, payload) {
		state.items = payload;
	},
	SET_LAST_UPDATED: function (state, payload) {
		state.lastUpdated = new Date(payload);
	},
};

export const store = {
	namespaced: true,
	state: () => state,
	modules: {},
	getters: make.getters(state),
	mutations: { ...mutations, ...make.mutations(state) },
	actions: {
		[LOAD]: async function ({ commit }, payload) {
			const rawBounds = payload;
			const bounds = {
				minLongitude: Math.min(rawBounds._northEast.lng, rawBounds._southWest.lng),
				maxLongitude: Math.max(rawBounds._northEast.lng, rawBounds._southWest.lng),
				minLatitude: Math.min(rawBounds._northEast.lat, rawBounds._southWest.lat),
				maxLatitude: Math.max(rawBounds._northEast.lat, rawBounds._southWest.lat),
			};
			const filter = {
				bounds,
			};
			backend.flightWatch
				.getAircraftStates(filter)
				.then((res) => {
					commit(SET_ITEMS, res.data.states);
					commit(`SET_LAST_UPDATED`, res.data.lastUpdated);
				})
				.catch((error) => {
					console.log(error);
				});
		},
	},
};
