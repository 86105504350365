<template>
	<div>
		<v-text-field
			v-model="searchQuery"
			v-bind="$attrs"
			outlined
			dense
			label="Search"
			prepend-inner-icon="mdi-magnify"
		/>
		<v-switch class="my-1" v-model="searchTypeSync" true-value="AND" false-value="OR" inset :label="searchType" dense />
	</div>
</template>
<script>
export default {
	name: 'BaseSearchFilter',
	props: {
		value: Array,
		items: Array,
		searchType: {
			type: String,
			default: 'AND',
		},
		searchFields: Array,
	},
	data() {
		return {
			searchQuery: '',
		};
	},
	computed: {
		searchTypeSync: {
			get() {
				return this.searchType;
			},
			set(value) {
				this.$emit('update:searchType', value);
			},
		},
	},
	watch: {
		searchQuery() {
			const filteredItems = this.$utils.filter.items(
				this.items,
				this.searchFields,
				this.searchQuery,
				this.searchTypeSync
			);
			this.$emit('input', filteredItems);
		},
	},
};
</script>
