<template>
	<div>
		<l-control v-bind="$attrs">
			<v-expansion-panels dark class="ExPanel">
				<v-expansion-panel class="ClosedExPanel">
					<v-expansion-panel-header>
						<div>
							<v-icon> mdi-cog-outline </v-icon>
						</div>
						<template v-slot:actions>
							<v-icon> mdi-chevron-down</v-icon>
						</template>
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<!-- {{ Object.entries(tiles).map((entry) => ({ key: entry[0], ...entry[1] })) }} -->
						<v-row>
							<v-col cols="6" sm="6" md="6" lg="6" xl="6">
								<h2 class="text--white">Map</h2>
								<v-radio-group v-model="selectedTileKey">
									<v-radio
										color="golden"
										v-for="item in Object.entries(tiles).map((entry) => ({ key: entry[0], ...entry[1] }))"
										:key="item.key"
										:label="item.name"
										:value="item.key"
									></v-radio>
								</v-radio-group>
							</v-col>
							<v-col cols="6" sm="6" md="6" lg="6" xl="6">
								<h2>Layers</h2>
								<v-checkbox
									color="golden"
									v-for="item in Object.entries(overlays).map((entry) => ({ key: entry[0], ...entry[1] }))"
									v-model="selectedOverlayKeys"
									:label="item.name"
									:key="item.key"
									:value="item.key"
									hide-details
								></v-checkbox>
							</v-col>
						</v-row>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
		</l-control>

		<l-tile-layer :url="tiles[selectedTileKey].url" />
		<l-tile-layer
			v-for="(key, index) of selectedOverlayKeys"
			:key="index"
			:url="overlays[key].url"
			:opacity="overlayOptions[key].opacity"
			:name="overlays[key].name"
			:tms="typeof overlays[key].tms !== 'undefined' ? overlays[key].tms : undefined"
			:detectRetina="typeof overlays[key].detectRetina !== 'undefined' ? overlays[key].detectRetina : undefined"
			:subdomains="typeof overlays[key].subdomains !== 'undefined' ? overlays[key].subdomains : undefined"
		/>
	</div>
</template>
<script>
import { get, sync } from 'vuex-pathify';
export default {
	name: 'fwLayerOverlayControl',
	data() {
		return {};
	},
	computed: {
		...sync(`flightWatch/settings/layers`, ['selectedTileKey']),
		...sync(`flightWatch/settings/layers`, {
			selectedOverlayKeys: 'overlays@selectedKeys',
		}),

		...get(`flightWatch/layers`, { tiles: 'availableTiles', overlays: 'availableOverlays' }),
		...get(`flightWatch/settings/layers`, {
			overlayOptions: 'overlays@options',
		}),
	},
};
</script>

<style scoped>
.ExPanel > * {
	background-color: #363636;
	opacity: 0.8;
}

.ClosedExPanel > * {
	padding-top: 0px;
	padding-right: 10px;
	padding-bottom: 0px;
	padding-left: 10px;
}
</style>
