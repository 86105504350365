export const generateMixin = (model) => ({
	props: {
		value: model,
		disabled: Boolean,
	},
	data: () => ({
		dialogOpen: false,
	}),
	computed: {
		editorItem: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
	methods: {
		resetValidation() {
			this.$refs.form.resetValidation();
		},
		onCancel() {
			this.resetValidation();
			this.$emit('cancel');
		},
		showDialog() {
			if (this.$refs.form.validate()) {
				this.dialogOpen = true;
			}
		},
	},
});
