import { make } from 'vuex-pathify';
import { LOAD } from '@/store/actionTypes';
import { SET_ITEMS } from '@/store/mutationTypes';
import backend from '@/services/backend';

const state = {
	items: {},
};

const mutations = {
	[SET_ITEMS]: function (state, payload) {
		state.items = payload;
	},
};

export const store = {
	namespaced: true,
	state: () => state,
	modules: {},
	getters: make.getters(state),
	mutations: { ...mutations, ...make.mutations(state) },
	actions: {
		[LOAD]: async function ({ commit }, payload) {
			backend.flightWatch
				.getVertiportStates(payload)
				.then((res) => {
					commit(SET_ITEMS, res.data);
				})
				.catch((error) => {
					console.log(error);
				});
		},
	},
};
