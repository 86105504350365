<template>
	<div v-if="currentAlert">
		<v-snackbar
			v-model="currentAlert.visible"
			:timeout="currentAlert.timeout"
			:color="currentAlert.color"
			:position="currentAlert.position"
		>
			<v-layout>
				<v-icon large>{{ currentAlert.icon }}</v-icon>
				<v-layout class="pl-4" column>
					<div>
						<strong>{{ currentAlert.title }}</strong>
					</div>
					<div>{{ currentAlert.text }}</div>
				</v-layout>
			</v-layout>
		</v-snackbar>
	</div>
</template>
<script>
export default {
	name: 'BaseAlerts',
	props: {
		storeLocation: String,
		duration: {
			type: Number,
			default: 4,
		},
	},
	data() {
		return {
			currentAlert: null,
		};
	},
	methods: {
		getNextAlert() {
			const slashIfNeeded = this.storeLocation.endsWith('/') ? '' : '/';
			this.currentAlert = this.$store.commit(`${this.storeLocation}${slashIfNeeded}alerts/READ`);
		},
	},
};
</script>
