<template>
	<v-card class="d-block">
		<v-card-title>Map Settings</v-card-title>
		<v-card-text>
			<v-expansion-panels>
				<v-expansion-panel>
					<v-expansion-panel-header>General</v-expansion-panel-header>
					<v-expansion-panel-content>
						<div>
							<div class="subtitle-1 font-weight-bold">Selected Color:</div>
							<v-color-picker v-model="selectedColor"></v-color-picker>
						</div>
					</v-expansion-panel-content>
				</v-expansion-panel>
				<v-expansion-panel>
					<v-expansion-panel-header>Area Of Operations</v-expansion-panel-header>
					<v-expansion-panel-content>
						<div class="subtitle-1 font-weight-bold">Area of Operations Radius:</div>
						<div>
							<v-slider
								v-model="areaOfOperationsRadius"
								class="align-center"
								max="200"
								min="1"
								step="1"
								track-color="golden"
								hide-details
							>
								<template v-slot:append>
									<v-text-field
										v-model="areaOfOperationsRadius"
										class="mt-0 pt-0"
										hide-details
										single-line
										type="number"
										min="1"
										max="200"
										step="1"
										style="width: 60px"
									></v-text-field>
								</template>
							</v-slider>
						</div>
					</v-expansion-panel-content>
				</v-expansion-panel>
				<v-expansion-panel>
					<v-expansion-panel-header>Flights</v-expansion-panel-header>
					<v-expansion-panel-content>
						<div>
							<v-checkbox v-model="vertiportVisible" label="Vertiports displayed"></v-checkbox>
							<v-checkbox v-model="waypointsVisible" label="Waypoints displayed"></v-checkbox>
							<v-checkbox v-model="pseudoWaypointsVisible" label="Pseudo-Waypoints displayed"></v-checkbox>
							<div class="subtitle-1 font-weight-bold">Route Line Color:</div>
							<v-color-picker v-model="polylineColor"></v-color-picker>

							<div class="subtitle-1 font-weight-bold">Vertiport Color:</div>
							<v-color-picker v-model="vertiportColor"></v-color-picker>
							<div class="subtitle-1 font-weight-bold">Vertiport Background Color:</div>
							<v-color-picker v-model="vertiportBackgroundColor"></v-color-picker>
							<div class="subtitle-1 font-weight-bold">Waypoint Opacity:</div>
							<v-slider
								v-model="waypointOpacity"
								class="align-center"
								max="1.0"
								min="0.0"
								step="0.1"
								track-color="golden"
								hide-details
							>
								<template v-slot:append>
									<v-text-field
										v-model="waypointOpacity"
										class="mt-0 pt-0"
										hide-details
										single-line
										type="number"
										min="0.0"
										max="1.0"
										step="0.1"
										style="width: 60px"
									></v-text-field>
								</template>
							</v-slider>
						</div>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
		</v-card-text>
	</v-card>
</template>
<script>
import { sync } from 'vuex-pathify';

export default {
	name: `FlightWatchMapAdmin`,
	data: () => ({}),
	computed: {
		...sync(`flightWatch/settings/layers`, {
			selectedColor: 'general@selectedColor',
			vertiportVisible: 'flightPlans@route.vertiports.visible',
			waypointsVisible: 'flightPlans@route.waypoints.visible',
			pseudoWaypointsVisible: 'flightPlans@route.pseudoWaypoints.visible',
			polylineColor: 'flightPlans@route.polyline.color',
			vertiportColor: 'flightPlans@route.vertiports.color',
			vertiportBackgroundColor: 'flightPlans@route.vertiports.backgroundColor',
			waypointOpacity: 'flightPlans@route.waypoints.opacity',
			areaOfOperationsRadius: 'flightPlans@route.areaOfOperations.radius',
		}),
	},
};
</script>
