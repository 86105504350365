import flightRules from './flightRules';
import typeOfFlight from './typeOfFlight';
import routingModes from './routingModes';
import sts from './sts';

export default {
	flightRules,
	typeOfFlight,
	routingModes,
	sts,
};
