<template>
	<v-card>
		<v-card-title>Route</v-card-title>
		<v-card-text>
			<v-text-field v-model="flightPlan.route.totalDistance" label="Total Distance" outlined dense disabled />
			<v-text-field v-model="flightPlan.route.cruiseAltitude" label="Cruise Altitude" outlined dense disabled />
			<v-textarea v-model="flightPlan.route.routeString" label="Route" outlined dense auto-grow disabled></v-textarea>
		</v-card-text>
	</v-card>
</template>
<script>
import { FlightPlan } from '@/models';
export default {
	name: 'AirFlightPlanRoute',
	props: {
		flightPlan: FlightPlan,
	},
};
</script>
