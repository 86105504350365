<template>
	<v-data-table
		class="my-1"
		:items="flightPlans"
		:headers="headers"
		hide-default-footer
		disable-filtering
		disable-pagination
		disable-sort
	>
		<!-- ToDo: Impelement this correctly -->
		<!-- <template v-for="(slotName, index) in ['item.meta.updatedAt']" :slot="slotName" slot-scope="{ item }">
			<span :key="index"
				>{{ $services.utils.dateFormatter.formatDateTimeUtc($_.get(item, slotName.slice(5))) }}aa{{
					slotName.slice(5)
				}}</span
			>
		</template> -->
		<template v-slot:[`item.meta.updatedAt`]="{ item }">
			<span>{{ $services.utils.dateFormatter.formatDateTimeUtc(item.meta.updatedAt) }}</span>
		</template>
		<template v-slot:[`item.endurance.time.trip`]="{ item }">
			<span>{{ $services.utils.dateFormatter.minutesToTimeString(item.endurance.time.trip) }}</span>
		</template>
		<template v-for="(slotName, index) in unitHeaders" :slot="slotName" slot-scope="{ item, header }">
			<span :key="index">{{ $_.get(item, slotName.slice(5)) }} {{ header.unit }}</span>
		</template>
	</v-data-table>
</template>
<script>
export default {
	name: 'AirFlightPlanOverviewHorizontal',
	props: {
		flightPlans: Array,
	},
	data: () => ({
		headers: null,
		unitHeaders: null,
	}),
	created() {
		this.headers = [
			{ value: 'meta.updatedAt', text: 'Calculated', align: 'start' },
			{ value: 'endurance.energy.total', text: 'Energy', align: 'start', unit: this.$services.utils.units.energy },
			{ value: 'endurance.time.trip', text: 'Trip', align: 'start' },
			{ value: 'route.totalDistance', text: 'Distance', align: 'start', unit: this.$services.utils.units.distance },
			{ value: 'route.cruiseAltitude', text: 'Cruise', align: 'start', unit: this.$services.utils.units.altitude },
			{ value: 'load.actualMass', text: 'Mass', align: 'start', unit: this.$services.utils.units.mass },
			{ value: 'route.routeString', text: 'Route', align: 'start' },
		];
		this.unitHeaders = this.headers.filter((item) => !!item.unit).map((item) => 'item.' + item.value);
	},
	computed: {},
};
</script>
