import Vue from 'vue';

const required = (value) => (value !== null ? value.toString().length > 0 : !!value || 'Input required.');

const integerString = (value) => /^[0-9]+$/.test(value) || 'Only numbers.';
const decimalString = (value) => /^[0-9]+(\.[0-9]+){0,1}$/.test(value) || 'Only numbers.';
const negativeDecimalString = (value) => /^-?[0-9]+(\.[0-9]+){0,1}$/.test(value) || 'Only negativ/positiv numbers.';

const fourChar = (value) => /^.{1,4}$/.test(value) || 'Must be exact 4 characters.';

const range0to1000 = (value) => {
	if (value >= 0 && value <= 1000) return true;
	else return 'Allowed range 0 to 1000.';
};
const year = (value) => {
	if (value > 1945 && value < 2022) return true;
	else return 'Must be a valid year.';
};
const month = (value) => {
	if (value >= 1 && value <= 12) return true;
	else return 'Must be a valid month.';
};
const day = (value) => {
	if (value >= 1 && value <= 31) return true;
	else return 'Must be a valid day.';
};
const hours = (value) => {
	if (value >= 0 && value <= 23) return true;
	else return 'Must be a valid month.';
};
const minutes = (value) => {
	if (value >= 0 && value <= 59) return true;
	else return 'Must be a valid month.';
};

Vue.prototype.$validationRules = {
	required,
	integerString,
	decimalString,
	negativeDecimalString,
	fourChar,
	range0to1000,
	year,
	month,
	day,
	hours,
	minutes,
};
