import { store as layout } from './layout';
import { store as alerts } from './alerts';

export const store = {
	namespaced: true,
	modules: {
		alerts,
		layout,
	},
};
