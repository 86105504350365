import Vue from 'vue';
import { store } from './store';
import App from './App.vue';
import router from './router';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';

import services from './services'; // wenn keine datei angegeben, importiert er immer index.js
Vue.prototype.$services = services;

import { vuetify } from './plugins';

Vue.config.productionTip = false;

new Vue({
	router,
	vuetify,
	store,
	render: (h) => h(App),
}).$mount('#app');
