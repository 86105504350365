import Home from '@/componentsViews/home/views/Home';
import DispatchView from '@/componentsViews/flights/views/DispatchView';
import AircraftManagement from '@/componentsViews/aircraft/views/AircraftManagement';
import AircraftTypeManagement from '@/componentsViews/aircraftType/views/AircraftTypeManagement';
import FlightPlanManagement from '@/componentsViews/flightPlan/views/FlightPlanManagement';
import FlightPlanViewer from '@/componentsViews/flightPlan/views/FlightPlanViewer';
import FlightWatch from '@/componentsViews/flightWatch/views/FlightWatchView';
// import MovementControl from '@/componentsViews/movementControl/views/MovementControl';
import VertiportMultiControl from '@/componentsViews/vertiportControl/views/VertiportMultiControl';

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
		icon: 'mdi-home',
		description: 'The home section offers an overview of the activated applications.',
	},
	{
		path: '/dispatch-list',
		name: 'Dispatch List',
		hotkey: 'F1',
		component: DispatchView,
		icon: 'mdi-file-clock-outline',
		description: 'Provides an overview of all scheduled flights and allows uploads of such.',
	},
	{
		path: '/ofp/file',
		name: 'OFP Management',
		hotkey: 'F2',
		component: FlightPlanManagement,
		icon: 'mdi-file-document-multiple-outline',
		description: 'Should normaly be accessed via the Dispatch List "New OFP" button.',
	},
	{
		path: '/ofp/view',
		name: 'OFP Viewer',
		component: FlightPlanViewer,
	},
	{
		path: '/aircraft-mgmt',
		name: 'Aircraft',
		hotkey: 'F3',
		component: AircraftManagement,
		icon: 'mdi-airplane',
		description: 'Manage your fleet of aircraft in this section.',
	},
	{
		path: '/aircraft-type-mgmt',
		name: 'Aircraft Type',
		hotkey: 'F4',
		component: AircraftTypeManagement,
		icon: 'mdi-shield-airplane-outline',
		description: 'Manage your aircraft types in this section.',
	},
	{
		path: '/flightwatch',
		name: 'Flight Watch',
		description: 'Watch the current status of your fleet on an interactive map.',
		icon: 'mdi-earth',
		hotkey: 'F5',
		component: FlightWatch,
	},
	{
		path: '/vertiport-control',
		name: 'Vertiport Control',
		description: 'Check the vertiports and their aircraft.',
		icon: 'mdi-eye',
		hotkey: 'F6',
		component: VertiportMultiControl,
	},
	// {
	// 	path: '/movement-control',
	// 	name: 'Movement-Control',
	// 	description: 'Gantt view to control operations.',
	// 	hotkey: 'F7',
	// 	component: MovementControl,
	// 	icon: 'mdi-arrow-decision',
	// },
	// {
	// 	path: '/test-view',
	// 	name: 'Test-View',
	// 	hotkey: 'F5',
	// 	component: TestView,
	// 	icon: 'mdi-format-list-text',
	// },
	// {
	// 	path: '/flightwatch',
	// 	name: 'Flightwatch',
	// 	icon: 'mdi-magnify',
	// 	sub: [
	// 		{
	// 			path: '/flightwatch-aproncontrol',
	// 			name: 'Apron Control',
	// 			icon: 'mdi-parking',
	// 		},
	// 		{
	// 			path: '/flightwatch-map',
	// 			name: 'Map',
	// 			icon: 'mdi-map-marker',
	// 		},
	// 	],
	// },
	// {
	// 	path: '/help',
	// 	name: 'Help',
	// 	icon: 'mdi-help',
	// },
];

export default routes;
