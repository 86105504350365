<template>
	<g class="title">
		<text x="124" y="25" :fill="fontColor" font-family="Roboto-Medium, Roboto" :font-size="fontSize" font-weight="500">
			Vertiport {{ title }}
		</text>
	</g>
</template>
<script>
export default {
	name: 'vertiportTitle',
	props: {
		title: String,
		fontColor: {
			type: String,
			default: '#000000',
		},
		fontSize: {
			type: String,
			default: '16px',
		},
	},
};
</script>
