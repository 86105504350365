<template>
	<div>
		<v-data-table
			:loading="operationStatus.loading"
			:headers="headers"
			:items="Object.values(flightStore)"
			:items-per-page.sync="itemsPerPage"
			:sort-desc="true"
			multi-sort
			item-key="_id"
			show-expand
			:expanded.sync="expandedFlights"
			sort-by="std"
			class="elevation-10"
		>
			<template v-slot:[`expanded-item`]="{ headers, item }">
				<td></td>
				<td :colspan="headers.length - 1">
					<fp-dispatch-quickview
						v-if="item.references.flightPlans.length"
						:flightPlans="item.references.flightPlans.map((id) => flightPlanStore[id])"
					/>
				</td>
			</template>
			<template
				v-for="(slotName, index) in ['std', 'sta', 'etd', 'eta']"
				:slot="'item.' + slotName"
				slot-scope="{ item }"
			>
				<span :key="index" v-if="item[slotName]">{{
					$services.utils.dateFormatter.formatDateTimeUtc(item[slotName])
				}}</span>
			</template>
			<template v-slot:[`item.aircraft`]="{ item }">
				<span
					class="green--text"
					v-if="
						item.references.activeFlightPlan && flightPlanStore && flightPlanStore[item.references.activeFlightPlan]
					"
					>{{ flightPlanStore[item.references.activeFlightPlan].aircraft.general.registration }}</span
				>
				<span v-else-if="item.plannedAircraftRegistration">{{ item.plannedAircraftRegistration }}</span>
			</template>
			<template v-for="(slotName, index) in ['item.departure', 'item.arrival']" :slot="slotName" slot-scope="{ item }">
				<v-btn :key="index" outlined small @click="showAirportInfo(item[slotName.split('.')[1]])">{{
					item[slotName.split('.')[1]]
				}}</v-btn>
			</template>
			<template v-slot:[`item.calculated`]="{ item }">
				<v-btn
					outlined
					class="noBorder"
					v-if="item.references.activeFlightPlan"
					:to="{ path: '/ofp/view', query: { id: item.references.activeFlightPlan } }"
					small
				>
					<v-icon color="green">mdi-check-bold</v-icon>
				</v-btn>
				<!-- <v-btn v-else small color="listbutton">
						<v-icon color="red">mdi-close-thick</v-icon>
					</v-btn> -->
			</template>
			<!-- <template v-slot:[`item.released`]="{ item }">
					<v-btn dark :to="item.released" small>
						<v-icon>
							{{ item.released ? 'mdi-check-bold' : 'mdi-close-thick' }}
						</v-icon>
					</v-btn>
				</template> -->
			<template v-slot:[`item.ofp`]="{ item }">
				<v-btn
					outlined
					class="noBorder"
					:to="{
						path: '/ofp/file',
						query: {
							flightId: item._id,
						},
					}"
				>
					<v-icon color="listbutton">mdi-clipboard-edit-outline</v-icon>
				</v-btn>
			</template>
		</v-data-table>
		<air-dialog
			v-model="showAirportInfoDialog"
			width="unset"
			:title="
				'Airport Information: ' +
				(currentAirport ? currentAirport.ICAO : '') +
				' / ' +
				(currentAirport ? currentAirport.IATA : '')
			"
		>
			<air-metar-taf v-if="currentAirport" :weather="currentAirport.weather" />
		</air-dialog>
	</div>
</template>
<script>
import { Flight, FlightPlan, Aircraft } from '@/models';

import { generateMixin as loadCrudModels } from '@/mixins/crud/loadModels';
import { generateMixin as getCrudModels } from '@/mixins/crud/getModels';
import { generateMixin as aggregateOperationStatus } from '@/mixins/aggregatedOperationStatus';

export default {
	name: 'AirDispatchList',
	mixins: [
		loadCrudModels(Flight, FlightPlan, Aircraft),
		getCrudModels(Flight, FlightPlan, Aircraft),
		aggregateOperationStatus(Flight, FlightPlan),
	],
	data: () => ({
		itemsPerPage: 10,
		showAirportInfoDialog: false,
		currentAirport: null,
		expandedFlights: [],
		headers: [
			{ text: '', value: 'data-table-expand' },
			{ text: 'STD', value: 'std', align: 'start' },
			{ text: 'ETD', value: 'etd', align: 'start' },
			{ text: 'STA', value: 'sta', align: 'start' },
			{ text: 'ETA', value: 'eta', align: 'start' },
			{ text: 'Flight Designator', value: 'flightDesignator', align: 'start' },
			{ text: 'Callsign', value: 'callsign', align: 'start' },
			{ text: 'Aircraft', value: 'aircraft', align: 'start' },
			{ text: 'DEP', value: 'departure', align: 'start' },
			{ text: 'ARR', value: 'arrival', align: 'start' },
			// { text: 'Dispatcher', value: 'dispatcher', align: 'start' },
			// { text: 'Pilot', value: 'pilot', align: 'start' },
			{ text: 'Calculated', value: 'calculated', align: 'center' },
			// { text: 'Released', value: 'released', align: 'center' },
			{ text: 'New OFP', value: 'ofp', align: 'center' },
		],
	}),
	methods: {
		showAirportInfo(icaoCode) {
			this.$services.backend.flightPlanning
				.getAirportInfos([icaoCode])
				.then((result) => {
					if (result.data.length) {
						this.currentAirport = result.data[0];
						this.showAirportInfoDialog = true;
					}
				})
				.catch((err) => {
					console.log('error saving flight plan', err);
					//show snackbar with error
				})
				.finally(() => {
					// stop skeleton-loader now
				});
		},
	},
};
</script>

<style scoped>
.noBorder {
	border: 0;
}
</style>
