import Vue from 'vue';
import Vuex from 'vuex';
import pathify from '../plugins/pathify';
import { modules } from './modules';

Vue.use(Vuex);

export const store = new Vuex.Store({
	plugins: [pathify.plugin],
	strict: process.env.NODE_ENV.toLowerCase().startsWith('prod'),
	namespaced: true,
	modules,
});

export function registerModule(moduleName, module) {
	if (!store.state[moduleName]) {
		console.log(`Registering store '${moduleName}'.`);
		store.registerModule(moduleName, module);
	}
}
export function unregisterModule(moduleName) {
	if (store.state[moduleName]) {
		console.log(`Unregistering store '${moduleName}'.`);
		store.unregisterModule(moduleName);
	}
}
