<template>
	<span>
		<v-file-input prepend-icon="mdi-upload" :accept="accept" v-model="chosenFile" hide-input />
	</span>
</template>
<script>
import { Flight, Aircraft } from '@/models';
import { generateMixin as getModels } from '@/mixins/crud/getModels';
import { generateMixin as loadModels } from '@/mixins/crud/loadModels';
import { SAVE } from '@/store/actionTypes';

export default {
	name: 'AirFileUpload',
	mixins: [getModels(Aircraft), loadModels(Aircraft)],
	props: {
		modelName: String,
		accept: String,
	},
	data: () => ({
		chosenFile: null,
	}),
	computed: {},
	watch: {
		chosenFile: function () {
			this.upload();
		},
	},
	methods: {
		upload() {
			if (!this.chosenFile) {
				return;
			}
			// ToDo: improve this....
			this.$services.backend
				.upload(this.modelName, this.chosenFile)
				.then(async (res) => {
					console.log(`UPLOAD SUCCESS!!!`, res.data);
					const { objectsImported } = res.data;
					if (objectsImported) {
						for (const item of objectsImported) {
							const flight = Flight.create(item);
							try {
								console.log('flight', flight);
								const flightPlanDraft = flight.getFlightPlanDraft(Object.values(this.aircraftStore));
								console.log('flightPlanDraft', flightPlanDraft);
								await this.$store.dispatch(`crud/FlightPlan/${SAVE}`, flightPlanDraft);
							} catch (filingError) {
								console.log(
									`Could not automatically file flight ${flight.flightDesignator} with STD ${flight.std}.`,
									filingError
								);
							}
						}
					}
					this.$emit('saved');
				})
				.catch((err) => {
					console.log('UPLOAD FAILURE!!', err);
				});
			console.log(this.chosenFile);
		},
	},
};
</script>
